import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { ID_FREE_ARTIST, IMAGE_SOURCE } from '../../../constants/rails';
import { getHasIndexesAccess, isLogged } from '../../../redux/slices/userContext/selectors';
import { getArtistName, getIdartist } from '../../../redux/selectors/indexes/artist';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const DemoOrLockMode = () => {
  const logged = useSelector(isLogged);
  const artistName = useSelector(getArtistName);
  const hasIndexesSubscription = useSelector(getHasIndexesAccess);
  const idartist = useSelector(getIdartist);
  const [display, setDisplay] = useState(0);

  useEffect(() => {
    if (hasIndexesSubscription) {
      if (logged) {
        setDisplay(0);
      } else {
        setDisplay(4);
      }
    } else if (idartist === ID_FREE_ARTIST) {
      setDisplay(2);
    } else if (logged) {
      setDisplay(3);
    } else {
      setDisplay(1);
    }
  }, [hasIndexesSubscription, logged]);

  if (display === 0) return null;

  return (
    <div className="indexes-demo-mode">
      <div className="icon">
        <img alt="lock" src={`${IMAGE_SOURCE}no-access/cadenas-no-padding.svg`} />
      </div>
      <div className="texts">
        <div className="text">
          {display === 4 && (
            <p>
              <A18n rsx="indexes.demo.or.lock.mode.m5" replace={[['%artist%', artistName]]} />
            </p>
          )}
          {display === 3 && (
            <p>
              <A18n rsx="indexes.demo.or.lock.mode.m4" replace={[['%artist%', artistName]]} />{' '}
              <a href="/demo/indexes">
                <A18n rsx="indexes.demo.or.lock.mode.m2" />
              </a>
            </p>
          )}
          {display === 1 && (
            <p>
              <A18n rsx="indexes.demo.or.lock.mode.m1" replace={[['%artist%', artistName]]} />{' '}
              <a href="/demo/indexes">
                <A18n rsx="indexes.demo.or.lock.mode.m2" />
              </a>
            </p>
          )}
          {display === 2 && (
            <p>
              <A18n rsx="indexes.header.demo.m1" /> <A18n rsx="indexes.header.demo.m2" />
            </p>
          )}
          <p>
            {!logged && (
              <a href="/identity" className="btn" style={{ marginRight: 30 }}>
                <A18n rsx="indexes.demo.or.lock.mode.m3" />
              </a>
            )}
            {!hasIndexesSubscription && (
              <a href="/subscription" className="btn">
                <A18n rsx="indexes.header.demo.m3" />
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemoOrLockMode;
