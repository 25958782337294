import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import i18next from 'i18next';
import RandExp from 'randexp';
import { isEmpty } from 'lodash';
import ErrorBlock from '../../../../../../common/ErrorBlock';
import { getCountry } from '../../../../../../../services/wallet/country';

const Tvaintra = ({ register, initialValues, watch, setValue, errors }) => {
  const [tvaFormat, setTvaFormat] = useState({});
  const [disabled, setDisabled] = useState();
  const [required, setRequired] = useState(false);

  const getCountryTvaInfo = (id, processNoTvaintra) => {
    getCountry({ id }).then(({ tvaformatregex, tvaformatinitialstring }) => {
      let countryhasTvaintra = false;

      if (tvaformatregex) {
        setTvaFormat({ pattern: tvaformatregex, sampleTva: new RandExp(tvaformatregex).gen(), initialString: tvaformatinitialstring });
        countryhasTvaintra = true;
      } else {
        setTvaFormat(null);
      }

      // The country has tvaintra but the customer has a firm and no tvaintra, the checkbox noTvaintra is checked
      if (countryhasTvaintra) {
        if (processNoTvaintra && !isEmpty(initialValues.firm) && isEmpty(initialValues.tvaintra)) {
          setValue('noTvaintra', true);
          setDisabled(true);
          setRequired(false);
        } else {
          setRequired(!isEmpty(initialValues.firm));
          setDisabled(false);
        }
      }
    });
  };

  // UseEffect for initialize state for tvaintra
  useEffect(() => {
    if (initialValues?.idcountry) {
      getCountryTvaInfo(initialValues.idcountry);
    }
  }, [initialValues?.idcountry]);

  // UseEffect to change state depending on form values
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'idcountry') getCountryTvaInfo(value.idcountry);

      if (name === 'noTvaintra') {
        setDisabled(true);
        setRequired(false);
        setValue('tvaintra', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  if (!tvaFormat) return null;

  return (
    <FormGroup row>
      <Label for="tvaintra" md={4}>
        {i18next.t('components.account.verify.identity.personal.m15')}
      </Label>
      <Col sm={8}>
        <input
          className="form-control"
          type="text"
          {...register('tvaintra', {
            required: { value: required, message: 'Information Obligatoire' },
            pattern: {
              value: new RegExp(tvaFormat.pattern),
              message: `Le numéro doit etre du type ${tvaFormat.sampleTva} `,
            },
          })}
          disabled={disabled}
          placeholder={tvaFormat.sampleTva}
        />
        <ErrorBlock error={errors.tvaintra} />
        <input type="checkbox" {...register('noTvaintra')} />
        <Label for="noTvaintra"> Pas de tva</Label>
      </Col>
    </FormGroup>
  );
};

export default Tvaintra;
