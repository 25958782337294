import React from 'react';
import { Image } from '@react-pdf/renderer';
import SvgNoReproduction from '../../common/SvgNoReproduction';

const Reproduction = ({ lot, imageMaxDimension }) => {
  if (!lot.imageUrl) return <SvgNoReproduction imageMaxDimension={imageMaxDimension} />;

  return <Image src={lot.imageUrl} />;
};

export default Reproduction;
