export const parsePrice = price => {
  if (!price) return '';

  const matches = price.match(/<img.*alt="(\w+)".*\/>/);

  if (matches) {
    return price.replace(matches[0], matches[1]);
  }

  return price;
};
