import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getParams, navigationStep } from '../../../../redux/slices/payments';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Navigation = () => {
  const step = useSelector(navigationStep);
  const { idestimates = [], from } = useSelector(getParams);

  return (
    <div className="payments-navigation container">
      <div className="nav-cell">
        {from === 'estimate' && (
          <>
            {idestimates.length === 0 && (
              <>
                <h3>
                  <span
                    className="label label-success"
                    onClick={() => {
                      window.location = '/estimate';
                    }}
                  >
                    <i className="fa fa-check-circle" />
                  </span>
                </h3>
                <A18n rsx="subscription.breadcrumb.m8" />
              </>
            )}
            {idestimates.length > 0 && (
              <>
                <h3>
                  <span
                    className="label label-success"
                    onClick={() => {
                      window.location = `/estimate/preview`;
                    }}
                  >
                    <i className="fa fa-check-circle" />
                  </span>
                </h3>
                <A18n rsx="subscription.breadcrumb.m6" />
              </>
            )}
          </>
        )}

        {from === 'shop' && (
          <>
            <h3>
              <span
                className="label label-success"
                onClick={() => {
                  window.location = `/estimate/preview`;
                }}
              >
                <i className="fa fa-check-circle" />
              </span>
            </h3>
            <A18n rsx="subscription.breadcrumb.m5" />
          </>
        )}

        {from !== 'estimate' && idestimates.length === 0 && from !== 'shop' && (
          <>
            <h3>
              <span
                className="label label-success"
                onClick={() => {
                  window.location = '/subscription';
                }}
              >
                <i className="fa fa-check-circle" />
              </span>
            </h3>
            <A18n rsx="subscription.breadcrumb.m1" />
          </>
        )}
      </div>

      <div className="split done" />

      <div className="nav-cell">
        {step === 2 && (
          <>
            <h3>
              <span className="label label-primary">2</span>
            </h3>
            <strong>
              <A18n rsx="subscription.breadcrumb.m2" />
            </strong>
          </>
        )}
        {step > 2 && (
          <>
            <h3>
              <span
                className="label label-success pointer"
                onClick={() => {
                  const url = new URL(window.location.href);
                  url.pathname = '/subscription/cart';
                  window.location = url.href;
                }}
              >
                <i className="fa fa-check-circle" />
              </span>
            </h3>
            <A18n rsx="subscription.breadcrumb.m2" />
          </>
        )}
      </div>

      <div className={classNames({ split: 1, done: step === 2 })} />

      <div className="nav-cell">
        {step < 3 && (
          <>
            <h3>
              <span className="label label-default">3</span>
            </h3>
            <strong className="disabled">
              <A18n rsx="subscription.breadcrumb.m3" />
            </strong>
          </>
        )}
        {step === 3 && (
          <>
            <h3>
              <span className="label label-primary">3</span>
            </h3>
            <strong>
              <A18n rsx="subscription.breadcrumb.m3" />
            </strong>
          </>
        )}
        {step > 3 && (
          <>
            <h3>
              <span className="label label-success pointer">
                <i className="fa fa-check-circle" />
              </span>
            </h3>
            <A18n rsx="subscription.breadcrumb.m3" />
          </>
        )}
      </div>

      <div className={classNames({ split: 1, done: step === 4 })} />

      <div className="nav-cell">
        {step < 4 && (
          <>
            <h3>
              <span className="label label-default">4</span>
            </h3>
            <strong className="disabled">
              <A18n rsx="subscription.breadcrumb.m4" />
            </strong>
          </>
        )}
        {step === 4 && (
          <>
            <h3>
              <span className="label label-primary">4</span>
            </h3>
            <strong>
              <A18n rsx="subscription.breadcrumb.m4" />
            </strong>
          </>
        )}
      </div>
    </div>
  );
};

export default Navigation;
