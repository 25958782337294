import i18Next from 'i18next';
import { useIntl } from 'react-intl';
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import LogoArtprice from './LogoArtprice';
import styles from './styles';

const CAPITAL = 6_651_515;

const Footer = () => {
  const intl = useIntl();

  return (
    <View style={styles.footer} fixed>
      <Text style={styles.footerLegals}>{i18Next.t('components.lotslists.pdf.m3')}</Text>
      <View fixed style={{ backgroundColor: '#999999', flexDirection: 'row' }}>
        <View fixed style={{ flexDirection: 'column', width: 500, paddingLeft: 10, paddingTop: 10, marginRight: 20 }}>
          <View style={{ marginBottom: 1.3 }}>
            <Text style={{ lineHeight: 1.6, fontSize: 9, textAlign: 'center' }}>
              {i18Next.t('estimate.footer.m1').replace('%capital%', intl.formatNumber(CAPITAL, { style: 'currency', currency: 'eur', minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
            </Text>
            <Text style={{ lineHeight: 1.6, fontSize: 9, textAlign: 'center' }}>{i18Next.t('estimate.footer.m2')}</Text>
          </View>
          <View style={{ paddingLeft: 90, display: 'flex', flexDirection: 'row' }}>
            <Text style={{ marginLeft: 2, fontSize: 9 }}>{i18Next.t('estimate.footer.m3')}</Text>
          </View>
        </View>
        <View style={{ width: 60 }}>
          <LogoArtprice />
        </View>
      </View>
    </View>
  );
};

export default Footer;
