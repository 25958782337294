import i18Next from 'i18next';
import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Reproduction from './Reproduction';
import { imageMaxDimension, styles } from './styles';
import Footer from '../common/Footer';
import { parsePrice } from '../common/utils';

const List = ({ data }) => {
  const { sortedIdlots, lots, list } = data;

  return (
    <Document language="fr">
      <Page size="A4" style={styles.page}>
        <View fixed style={{ flexDirection: 'row-reverse', width: '100%', marginTop: 20, marginBottom: 30, textTransform: 'uppercase' }}>
          <Text style={{ backgroundColor: '#000', color: '#fff', paddingRight: 5, paddingLeft: 5, paddingTop: 10 }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
          <Text style={{ backgroundColor: '#000', color: '#fff', padding: 10, paddingRight: 30 }}>
            {i18Next.t('components.lotslists.pdf.m4')} - {list.label}
          </Text>
        </View>

        {sortedIdlots
          .map(idlot => lots[idlot])
          .map(lot => (
            <View key={lot.id} style={styles.section} wrap={false}>
              <View style={{ flexDirection: 'row' }}>
                <View style={styles.reproduction}>
                  <Reproduction lot={lot} imageMaxDimension={imageMaxDimension} original={false} />
                </View>
                <View style={styles.cell}>
                  <Text style={[styles.text, { fontWeight: 700 }]}>
                    {lot.title}
                    {lot.creationDate && ` (${lot.creationDate})`}
                  </Text>
                  <Text style={styles.text}>{lot.artistOrAssociation}</Text>
                  <Text style={styles.text}>
                    {lot.category} - {lot.technique}
                  </Text>
                  <Text style={styles.text}>{lot.dimension}</Text>
                  {(lot.estimation?.low || lot.estimation?.high) && (
                    <Text style={styles.text}>
                      {i18Next.t('components.lotslists.pdf.m1')} {parsePrice(lot.estimation?.low)} - {parsePrice(lot.estimation?.high)}
                    </Text>
                  )}
                  {lot.lotstatus === 1 && (
                    <Text style={styles.text}>
                      {i18Next.t('components.lotslists.pdf.m2')}
                      <Text style={{ fontWeight: 'bold' }}> {parsePrice(lot.price)}</Text>
                    </Text>
                  )}
                  {lot.lotstatus > 1 && <Text style={styles.text}>{parsePrice(lot.price)}</Text>}
                </View>
                <View style={styles.cell}>
                  <Text style={styles.text}>{lot.auctioneerName}</Text>
                  <Text style={styles.text}>
                    {lot.saleDtStart}
                    {lot.saleDtStart !== lot.saleDtEnd && `- ${lot.saleDtEnd}`}
                  </Text>
                  <Text style={styles.text}>
                    {lot.saleCity}, {lot.saleCountry}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        <Footer />
      </Page>
    </Document>
  );
};
export default List;
