import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, getCartLoading, getParams, updateParams } from '../../../../redux/slices/payments';
import A18n from '../../../common/A18n';

const Promocode = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const loading = useSelector(getCartLoading);
  const { promocode } = useSelector(getParams);
  const { promotion_from_promocode: promotionFromPromocode } = useSelector(getCart);

  const { register, handleSubmit } = useForm({
    defaultValues: { promocode },
  });

  const onSubmit = values => {
    // update the search query param to trigger the update of the promocode param in the outlet context
    setSearchParams(s => {
      s.set('promocode', values.promocode);
      return s;
    });

    dispatch(updateParams({ promocode: values.promocode }));
  };

  return (
    <div>
      <h3>Code promotionnel</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group">
          <input type="text" id="sln_promocode" className="form-control" disabled={loading} {...register('promocode')} />
          <div className="input-group-btn">
            <input value="Ok" type="submit" id="sln_promocode_btn" className="btn btn-primary" />
          </div>
        </div>
        {promotionFromPromocode?.status && promotionFromPromocode.status > 1 && (
          <div>
            <i className="fa fa-exclamation-triangle" /> {promotionFromPromocode.status === 4 && <A18n rsx="subscription.user_promocode.m6" />}
            {promotionFromPromocode.status === 5 && <A18n rsx="subscription.user_promocode.m10" />}
            {promotionFromPromocode.status === 2 && <A18n rsx="subscription.user_promocode.m11" />}
            {promotionFromPromocode.status === 3 && <A18n rsx="subscription.user_promocode.m9" />}
            {promotionFromPromocode.status === 6 && <A18n rsx="subscription.user_promocode.m12" />}
          </div>
        )}
      </form>
    </div>
  );
};

export default Promocode;
