/* eslint-disable max-len */

import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const SvgNoReproduction = ({ imageMaxDimension }) => (
  <Svg height={imageMaxDimension / 2} width={imageMaxDimension / 2} viewBox="0 0 807.69 810.26">
    <Path
      fill="#C9C9C9"
      d="M791.96,18.29c-9.12-9.12-23.9-9.12-33.02,0L13.16,764.07c-9.12,9.12-9.12,23.9,0,33.02 c4.56,4.56,10.53,6.84,16.51,6.84c5.98,0,11.95-2.28,16.51-6.84L791.96,51.31C801.08,42.2,801.08,27.41,791.96,18.29z"
    />
    <Path
      fill="#C9C9C9"
      d="M319.81,268.59c-6.32-7.5-17.69-8.04-24.7-1.18L85.56,472.81V307.07c12.8,14.87,31.74,24.3,52.9,24.3 c38.54,0,69.78-31.24,69.78-69.78c0-38.54-31.24-69.78-69.78-69.78c-21.16,0-40.1,9.43-52.9,24.3v-77.55h513.87l46.7-46.7H62.21 c-12.9,0-23.35,10.45-23.35,23.35v583.91l349.36-349.36L319.81,268.59z"
    />
    <Path
      fill="#C9C9C9"
      d="M719.57,466.81l-63.1-78.04c-6.34-7.84-18.1-8.4-25.16-1.2L528.99,491.97c-6.03,6.16-16.06,5.78-21.61-0.81 l-53.06-62.96L159,723.51h583.92c12.89,0,23.35-10.45,23.35-23.35V116.25l-46.7,46.7V466.81z"
    />
  </Svg>
);

export default SvgNoReproduction;
