import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { string, number, oneOfType, arrayOf, shape, bool } from 'prop-types';
import ReduxProvider from '../../providers/ReduxProvider';
import { cleanPathname, objectToQueryString, queryStringToObject, updateUrlQuery } from '../../../utils/http';
import Search from '../../search/v2/Search';
import Item from '../../search/v2/Search/Item';
import Select from '../../search/v2/inputs/Select';
import DebouncedInput from '../../search/v2/inputs/DebouncedInput';
import ArtistSearch from '../../search/v2/inputs/ArtistSearch';
import Checkbox from '../../search/v2/inputs/Checkbox';
import Params from '../../search/v2/Params';
import SearchBar from '../../search/v2/inputs/SearchBar';
import CategorySelect from '../../search/v2/helpers/items/CategorySelect';
import TechniqueSelect from '../../search/v2/helpers/items/TechniqueSelect';
import ShapeSelect from '../../search/v2/helpers/items/ShapeSelect';
import SizeSelect from '../../search/v2/helpers/items/SizeSelect';
import CountrySelect from '../../search/v2/helpers/items/CountrySelect';
import Prices from '../../search/v2/inputs/Prices';
import Preferences from '../../search/v2/helpers/Preferences';
import Sort from '../../search/v2/helpers/Sort';
import AdvancedButton from '../../search/v2/helpers/AdvancedButton';
import { MARKETPLACE_AUCTIONS, MARKETPLACE_DEFAULT, MARKETPLACE_WITH_KEYWORD } from '../../../constants/search/sorts';
import Count from '../../search/v2/helpers/Count';
import { forceIdToArray } from '../../../utils/search/format';
import './stylesheet.scss';

function formatBaseParams(idartist, sitenameformated) {
  if (!idartist && !sitenameformated) {
    return undefined;
  }

  const baseParams = {};
  if (idartist) {
    baseParams.idartist = forceIdToArray(idartist);
  }
  if (sitenameformated) {
    baseParams.sitenameformated = sitenameformated;
  }
  return baseParams;
}

const Bar = ({ searchScope, idartist, sitenameformated, hiddenFields, sortInRailsParam }) => {
  const urlParams = queryStringToObject(window.location.search);
  const [sortOptions, setSortOptions] = useState(MARKETPLACE_DEFAULT);

  useEffect(() => {
    if (sortInRailsParam) {
      updateUrlQuery({ ...urlParams, sort: sortInRailsParam });
    }
  }, [sortInRailsParam]);

  useEffect(() => {
    setSortOptions({ ...sortOptions, ...(urlParams?.idclassifiedauctionmode === '1' ? MARKETPLACE_AUCTIONS : {}), ...(urlParams?.terms ? MARKETPLACE_WITH_KEYWORD : {}) });
  }, [urlParams?.idclassifiedauctionmode, urlParams?.terms]);

  const handleChange = params => {
    const { sort } = urlParams;
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ sort, ...params, p: 1 })}`;
  };

  return (
    <Search
      searchScope={searchScope}
      baseParams={formatBaseParams(idartist, sitenameformated)}
      advancedParams={urlParams}
      onChange={({ searchParams }) => {
        handleChange(searchParams);
        return false; // stop the internal Search processing (we are going to reload the page)
      }}
      horizontalBar={({ drawerId, searchParams }) => (
        <>
          <div className="marketplace-horizontal-bar">
            <div className="searchbar-container marg marg-b-15">
              <Item label={false} name="terms">
                <SearchBar placeholder={i18next.t('components.classifieds.searchbar.m1')} />
              </Item>
              <AdvancedButton drawerId={drawerId} />
            </div>
            <div className="filters-container">
              <Count
                searchScope={searchScope}
                rsx={{
                  initial: 'marketplace.classifieds.searchbar.count.m4',
                  noResult: 'marketplace.classifieds.searchbar.count.m2',
                  oneResult: 'marketplace.classifieds.searchbar.count.m3',
                  multipleResults: 'marketplace.classifieds.searchbar.count.m1',
                }}
              />
              <div className="flex-filler" />
              <Sort options={sortOptions} onChange={sortOption => handleChange({ ...searchParams, sort: sortOption })} value={urlParams?.sort} />
              <Preferences rsx={{ title: false }} onChange={() => handleChange(searchParams)} hiddenFields={{ artistsViewFormat: true }} />
            </div>
          </div>
          <Params searchScope={searchScope} onChange={handleChange} />
        </>
      )}
      drawer={() => (
        <div>
          <Item label={i18next.t('marketplace.searchbars.stores.bar.m5')} name="terms">
            <DebouncedInput debounce={250} placeholder={i18next.t('layouts.menu.m2')} />
          </Item>
          <hr />
          <Item label={i18next.t('marketplace.searchbars.common.artist.m1')} name="idartist">
            <ArtistSearch disabled={!!idartist} />
          </Item>
          <Item label={i18next.t('marketplace.searchbars.classifieds.bar.m13')} name="in_fav">
            <Checkbox />
          </Item>
          <hr />
          <CategorySelect />
          <TechniqueSelect />
          <ShapeSelect />
          <SizeSelect />
          <Item label={i18next.t('marketplace.searchbars.classifieds.bar.m9')} name={{ price_min: 'from', price_max: 'to', price_idcurrency: 'currency' }}>
            <Prices />
          </Item>
          <hr />
          {!hiddenFields?.idcountry && <CountrySelect />}
          {!hiddenFields?.idclassifiedauctionmode && (
            <Item label={i18next.t('marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m1')} name="idclassifiedauctionmode">
              <Select facet="idclassifiedauctionmode" placeholder={i18next.t('marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m2')} hideCount />
            </Item>
          )}
          {!hiddenFields?.idprofessionaltype && (
            <Item label={i18next.t('marketplace.searchbars.common.professionaltypes.m2')} name="idprofessionaltype">
              <Select facet="idprofessionaltype" placeholder={i18next.t('marketplace.searchbars.common.professionaltypes.m1')} />
            </Item>
          )}
          {!hiddenFields?.idstoretype && (
            <Item label={i18next.t('marketplace.searchbars.common.storetypes.m1')} name="idstoretype">
              <Select facet="idstoretype" placeholder={i18next.t('marketplace.searchbars.common.storetypes.m2')} />
            </Item>
          )}
        </div>
      )}
    />
  );
};

const ProvidedBar = ({ searchScope, idartist, sitenameformated, hiddenFields, sortInRailsParam }) => (
  <ReduxProvider>
    <Bar searchScope={searchScope} idartist={idartist} sitenameformated={sitenameformated} hiddenFields={hiddenFields} sortInRailsParam={sortInRailsParam} />
  </ReduxProvider>
);

ProvidedBar.defaultProps = {
  idartist: undefined,
  sitenameformated: undefined,
  hiddenFields: undefined,
  sortInRailsParam: undefined,
};

ProvidedBar.propTypes = {
  idartist: oneOfType([number, arrayOf(number)]),
  sitenameformated: string,
  sortInRailsParam: string,
  hiddenFields: shape({
    idcountry: bool,
    idclassifiedauctionmode: bool,
    idprofessionaltype: bool,
    idstoretype: bool,
  }),
  searchScope: string.isRequired,
};

export default ProvidedBar;
