import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getMainReport, getReports } from '../../../redux/slices/homepage';
import { ANALYTICS_FROM_HOMEPAGE, ANALYTICS_CLICK_OPERATION_TO_REPORT, IMAGE_SOURCE, ANALYTICS_CLICK_OPERATION_TO_REPORTS_HOME } from '../../../constants/rails';
import Arrow from '../../common/Arrow';
import Thumbnails from './Thumbnails';
import A18n from '../../common/A18n';
import './stylesheet.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';

const Reports = () => {
  const reports = useSelector(getReports);
  const mainReport = useSelector(getMainReport);

  if (isEmpty(reports) && mainReport === null) return null;

  return (
    <div className="container homepage-reports block">
      <h2 className="title">
        <A18n rsx="homepage.reports.m1" />
        <div className="all">
          <a href="/artmarketinsight/reports" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_REPORTS_HOME })}>
            <A18n rsx="homepage.reports.m2" />
            <Arrow style={{ marginLeft: 5 }} />
          </a>
        </div>
      </h2>

      {mainReport && (
        <div className="main radius" style={{ backgroundImage: `url("${mainReport.image}")` }}>
          <div className={classNames(['bg', mainReport.report])} />
          <div className="content">
            <div className="legend">
              <A18n rsx="homepage.reports.m3" />
            </div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="title" dangerouslySetInnerHTML={{ __html: mainReport.title }} />
            <div className="desc">
              <A18n rsx="homepage.reports.m4" />
            </div>
            <div className="lnk">
              <a
                href={`/artprice-reports/${mainReport.url}`}
                className="btn btn-default btn-animated"
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_REPORT, slug: mainReport.url })}
              >
                <A18n rsx="homepage.reports.m5" replace={[['%year%', mainReport.year]]} />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="thumbnails">
        {reports?.map(report => (
          <Thumbnails key={`reports${report.title}`} src={report.image}>
            <div className="desc">
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: report.title }} />
              <div className="lnk">
                <a
                  href={`/artprice-reports/${report.url}`}
                  className="btn btn-default btn-animated"
                  onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_REPORT, slug: report.url })}
                >
                  <A18n rsx="homepage.reports.m6" />
                </a>
              </div>
            </div>
          </Thumbnails>
        ))}
        <Thumbnails src={`${IMAGE_SOURCE}hp/guide.jpg`}>
          <div className="desc">
            <p>
              <A18n rsx="homepage.reports.m7" />
            </p>
            <div className="lnk">
              <a
                href="/artmarketinsight/reports#guides"
                className="btn btn-default btn-animated"
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_REPORT, slug: 'guides' })}
              >
                <A18n rsx="homepage.reports.m6" />
              </a>
            </div>
          </div>
        </Thumbnails>
      </div>
    </div>
  );
};

export default Reports;
