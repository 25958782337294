import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getCart } from '../../../../redux/slices/payments';

const Summary = () => {
  const [title, setTitle] = useState();
  const cart = useSelector(getCart);

  useEffect(() => {
    if (!cart.cart_unavailable_for_product && !isEmpty(cart) && !cart.loading) {
      setTitle(cart.main_products[0].product.title);
    }
  }, [cart]);

  return (
    <div className="payments-cart-summary">
      <div className="summary-container">
        <div className="container">{title && <h2>{title} : Détail de votre commande</h2>}</div>
      </div>
    </div>
  );
};
export default Summary;
