import React from 'react';
import { ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME, ANALYTICS_FROM_HOMEPAGE } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import A18n from '../../../common/A18n';

const Estimate = () => (
  <div className="homepage-estimate homepage-auto-promo">
    <div className="l0">
      <A18n rsx="homepage.focusonorautopromo.estimate.m1" />
    </div>
    <div className="l1">
      <A18n rsx="homepage.focusonorautopromo.estimate.m2" />
    </div>
    <div className="l2">
      <a className="btn btn-default btn-animated" href="/estimate" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME, section: 'auto-promo-estimate' })}>
        <A18n rsx="homepage.focusonorautopromo.estimate.m3" />
      </a>
    </div>
  </div>
);

export default Estimate;
