import i18next from 'i18next';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { getIdprofessionaltype, getVerifiedAt } from '../../../../../../redux/slices/userContext/selectors';
import CountryDropdown from '../../../../../common/inputs/CountryDropdown';
import TitleDropdown from '../../../../../common/inputs/TitleDropdown';
import PhoneInput from '../../../../../common/ui/PhoneInput';
import ErrorBlock from '../../../../../common/ErrorBlock';
import Tvaintra from './Tvaintra';
import './stylesheet.scss';

const PROFESSIONAL = 1;

const Form = ({ initialValues, onSubmit, onCancel }) => {
  const verifiedAt = useSelector(getVerifiedAt);
  const idprofessionaltype = useSelector(getIdprofessionaltype);

  // Used for phone prefix
  const [countryIso2, setCountryIso2] = useState(i18next.language);

  // Used for disable fields
  const [readOnly, setReadOnly] = useState(false);
  const [readOnlyForProfessional, setReadOnlyForProfessional] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: initialValues,
  });

  const handleCountryChange = country => {
    setCountryIso2(country.iso2.toLowerCase());
  };

  useEffect(() => {
    setReadOnly(!isNil(verifiedAt));
    setReadOnlyForProfessional(!isNil(verifiedAt) && idprofessionaltype === PROFESSIONAL);
  }, [verifiedAt, idprofessionaltype]);

  return (
    <div className="payments-cart-summary-addresses-address-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <FormGroup row>
          <Label for="title" md={4}>
            Titre
          </Label>
          <Col sm={8}>
            <TitleDropdown control={control} className="form-control" name="idtitle" readOnly={readOnly} rules={{ required: 'Title obligatoire' }} />
            <ErrorBlock error={errors.idtitle} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="firstname" md={4}>
            FirstName
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('firstname', { required: 'Information Obligatoire' })} disabled={readOnly} />
            <ErrorBlock error={errors.firstname} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="lastname" md={4}>
            LastName
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('lastname', { required: 'Information Obligatoire' })} disabled={readOnly} />
            <ErrorBlock error={errors.lastname} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="firm" md={4}>
            Firm
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('firm')} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.firm} />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="line1" md={4}>
            {i18next.t('components.account.verify.identity.personal.m6')}
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('line1', { required: 'Information Obligatoire' })} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.line1} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={8} className="col-lg-offset-4 col-md-offset-4">
            <input className="form-control" type="text" {...register('line2')} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.line2} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={8} className="col-lg-offset-4 col-md-offset-4">
            <input className="form-control" type="text" {...register('line3')} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.line3} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="postcode" md={4}>
            {i18next.t('components.account.verify.identity.personal.m7')}
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('postcode', { required: 'Information Obligatoire' })} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.postcode} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="city" md={4}>
            {i18next.t('components.account.verify.identity.personal.m8')}
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('city', { required: 'Information Obligatoire' })} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.city} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="optional" for="state" md={4}>
            {i18next.t('components.account.verify.identity.personal.m9')}
          </Label>
          <Col sm={8}>
            <input className="form-control" type="text" {...register('state')} disabled={readOnlyForProfessional} />
            <ErrorBlock error={errors.state} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="idcountry" md={4}>
            {i18next.t('components.account.verify.identity.personal.m10')}
          </Label>
          <Col sm={8}>
            <CountryDropdown control={control} className="form-control" name="idcountry" onChange={handleCountryChange} rules={{ required: 'Pays obligatoire' }} />
            <ErrorBlock error={errors.idcountry} />
          </Col>
        </FormGroup>
        <Tvaintra initialValues={initialValues} watch={watch} register={register} setValue={setValue} errors={errors} />
        <FormGroup row>
          <Label for="phone" md={4}>
            {i18next.t('components.account.verify.identity.personal.m11')}
          </Label>
          <Col sm={8}>
            <PhoneInput control={control} country={countryIso2} name="phone" rules={{ required: 'Info du tel obligatoire' }} />
            <ErrorBlock error={errors && errors.phone} />
          </Col>
        </FormGroup>

        <button type="submit" id="sln_promocode_btn" className="btn btn-primary">
          OK
        </button>
        <button type="button" id="sln_promocode_btn" className="btn btn-default" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default Form;
