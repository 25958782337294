import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getFiltersResultCount } from '../../../../../../redux/slices/artist';
import { getCurrentBillCurrency, isLogged } from '../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';
import { getTheme } from '../../../../../../redux/selectors/subscriptions/physicproducts';
import { THEME_BASICS } from '../../../../../../constants/rails';

const NoAccess = ({ display, setDisplay, searchParams }) => {
  const intl = useIntl();
  const themeBasic = useSelector(state => getTheme(state, { idtheme: THEME_BASICS }));
  const currency = useSelector(getCurrentBillCurrency);
  const [priceOneDay, setPriceOneDay] = useState();
  const filtersResultCount = useSelector(getFiltersResultCount);
  const logged = useSelector(isLogged);

  useEffect(() => {
    if (themeBasic) {
      const physicproduct = themeBasic.physicproducts.filter(p => p.original_nbdays === 1)[0];

      if (physicproduct?.price[currency]) {
        setPriceOneDay(
          intl.formatNumber(physicproduct?.price[currency], {
            style: 'currency',
            currency,
          }),
        );
      }
    }
  }, [themeBasic]);

  if (!display) return null;

  return (
    <div className="artists-artist-common-drawer-no-access">
      <div className="bg" onClick={() => setDisplay(false)} />
      <div className="box">
        <div className="close" onClick={() => setDisplay(false)}>
          <img src="https://images.gratt.org/img/artist/close.png" alt="close" />
        </div>
        <div className="title">
          <A18n rsx="artists.artist.filters.drawer.noaccess.m5" />
        </div>
        <div className="args">
          <p>
            {searchParams.only_in_future_sales === 1 && filtersResultCount > 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m1" replace={[['%nb%', intl.formatNumber(filtersResultCount)]]} />}
            {searchParams.only_in_future_sales !== 1 && filtersResultCount > 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m2" replace={[['%nb%', intl.formatNumber(filtersResultCount)]]} />}
            {searchParams.only_in_future_sales === 1 && filtersResultCount === 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m3" />}
            {searchParams.only_in_future_sales !== 1 && filtersResultCount === 1 && <A18n rsx="artists.artist.filters.drawer.noaccess.m4" />}
          </p>
          <p>
            {priceOneDay && <A18n rsx="artists.artist.filters.drawer.noaccess.m6" replace={[['%price%', priceOneDay]]} trustHtml />}
            {!priceOneDay && <A18n rsx="artists.artist.filters.drawer.noaccess.m7" trustHtml />}
          </p>
          <div className="actions">
            <button
              type="button"
              onClick={e => {
                e.stopPropagation();
                window.location.href = '/subscription';
              }}
            >
              <A18n rsx="artists.artist.filters.drawer.noaccess.m8" />
            </button>
          </div>
          {!logged && (
            <div className="login">
              <A18n rsx="artists.artist.lots.noaccessdrawer.m4" />{' '}
              <a href="/identity/sales" id="sln_login">
                <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
