import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import i18next from 'i18next';

const Placeholder = React.forwardRef(({ idx, imageUrl, onLoad, onLoadStart, updatingImage, actions }, ref) => {
  useEffect(() => {
    if (imageUrl) {
      onLoadStart();
    }
  }, [imageUrl]);

  if (imageUrl) {
    return (
      <div className={`marketplace-store-edit-form-images-image-placeholder ${idx === 0 && 'primary'}`}>
        <img crossOrigin="use-credentials" alt="store" src={imageUrl} ref={ref} onLoad={onLoad} />
        {actions()}
        {idx === 0 && <i className="fa fa-star-o" title={i18next.t('marketplace.stores.edit.form.images.m1')} />}
        {idx === 0 && <div className="legend"> {i18next.t('marketplace.stores.edit.form.images.m1')}</div>}
      </div>
    );
  }

  if (updatingImage) {
    return null;
  }

  return <i className="fa fa-plus-circle fa-4x" />;
});

Placeholder.defaultProps = {
  imageUrl: null,
  onLoadStart: undefined,
};

Placeholder.propTypes = {
  imageUrl: string,
  onLoad: func.isRequired,
  onLoadStart: func,
};

export default Placeholder;
