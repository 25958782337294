import React from 'react';
import { IntlProvider } from 'react-intl';
import Table from '../index';

const TableProvided = ({ locale = 'en', data = [] }) => (
  <IntlProvider locale={locale}>
    <Table data={data} />
  </IntlProvider>
);

export default TableProvided;
