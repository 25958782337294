import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUserContext } from '../../../../../../redux/selectors/userContext';

const Show = ({ address, withTva }) => {
  const intl = useIntl();
  const { tvaintra, idcustomer } = useSelector(getUserContext);

  return (
    <div>
      <p>{idcustomer}</p>
      <p>
        {address.title && address.title.dictionary[intl.locale]} {address.firstname} {address.lastname}
      </p>
      <p>{address.firm}</p>
      {address.line1 && <p>{address.line1}</p>}
      {address.line2 && <p>{address.line2}</p>}
      {address.line3 && <p>{address.line3}</p>}
      <p>
        {address.postcode} {address.city}
      </p>
      {address.country && <p>{address.country.dictionary[intl.locale]}</p>}
      <p>{address.phone}</p>
      {withTva && <p>{tvaintra}</p>}
    </div>
  );
};

export default Show;
