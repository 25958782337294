import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';

import './stylesheet.scss';

const HorizontalBar = React.forwardRef(({ children, className, ...otherProps }, ref) => (
  <div className={`horizontal-bar ${className || ''}`.trim()} ref={ref} {...otherProps}>
    {children}
  </div>
));

HorizontalBar.defaultProps = {
  className: '',
};

HorizontalBar.propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default HorizontalBar;
