import React from 'react';
import i18next from 'i18next';
import { string, func, bool } from 'prop-types';

import './stylesheet.scss';

const SearchBar = ({ formClassName, inputClassName, placeholder, className, onSubmit, onChange, defaultValue, withoutButton, label, ...otherProps }) => {
  const handleChange = v => {
    onChange(v);
  };

  return (
    <form className={formClassName} onSubmit={onSubmit} {...otherProps}>
      {label && <div className="artp-input-label">{label}</div>}
      <div className={`artp-search-bar ${!withoutButton ? 'artp-input-group' : ''} ${className || ''}`.trim()}>
        <input className={inputClassName} type="text" defaultValue={defaultValue} placeholder={placeholder} onChange={e => handleChange(e.target.value)} />
        {!withoutButton && (
          <button type="submit" className="btn">
            <i className="fa fa-search" />
          </button>
        )}
      </div>
    </form>
  );
};

SearchBar.defaultProps = {
  placeholder: i18next.t('layouts.menu.m2'),
  defaultValue: '',
  className: '',
  formClassName: '',
  withoutButton: false,
  label: undefined,
  onChange: () => undefined,
  onSubmit: () => undefined,
  inputClassName: '',
};

SearchBar.propTypes = {
  placeholder: string,
  defaultValue: string,
  onChange: func,
  onSubmit: func,
  className: string,
  label: string,
  formClassName: string,
  withoutButton: bool,
  inputClassName: string,
};

export default SearchBar;
