import React from 'react';
import { parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getIdcustomer, getPushEmail, isLogged } from '../../../../redux/slices/userContext/selectors';
import { updateHideArtistFollowRecommendationsModaleUntil } from '../../../../redux/actions/preferences';
import { getHideArtistFollowRecommendationsModaleUntil } from '../../../../redux/selectors/preferences';
import A18n from '../../../common/A18n';
import './stylesheet.scss';
import { IMAGE_SOURCE } from '../../../../constants/rails';

const RecommendationsModale = ({ display, setDisplay }) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const hideArtistFollowRecommendationsModaleUntil = useSelector(getHideArtistFollowRecommendationsModaleUntil);

  if (!display || parseISO(hideArtistFollowRecommendationsModaleUntil) > new Date()) return null;

  const handleClose = () => {
    if (idcustomer && !isPushEmail) {
      dispatch(updateHideArtistFollowRecommendationsModaleUntil());
    }
    setDisplay(false);
  };

  return (
    <div className="artists-follow-recommandations">
      <div className="bg" onClick={handleClose} />
      <div className="box">
        <div className={`args ${logged ? 'log' : ''}`}>
          <div className="close" onClick={handleClose}>
            <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
          </div>
          {!idcustomer && (
            <>
              <p>
                <A18n rsx="artists.follow.recommendations-modale.m1" />
              </p>
              <a className="action" href="/newcomer">
                <A18n rsx="artists.follow.recommendations-modale.m2" />
              </a>
            </>
          )}
          {idcustomer && !isPushEmail && (
            <>
              <p>
                <A18n rsx="artists.follow.recommendations-modale.m3" />
              </p>
              <p>
                <A18n rsx="artists.follow.recommendations-modale.m4" />
              </p>
              <a className="action" href="/myartprice/settings/artists">
                <A18n rsx="artists.follow.recommendations-modale.m5" />
              </a>
            </>
          )}

          {!logged && (
            <div className="login">
              <A18n rsx="artists.follow.recommendations-modale.m6" />{' '}
              <a href="/identity/sales" id="sln_login">
                <A18n rsx="artists.follow.recommendations-modale.m7" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendationsModale;
