import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_ARTIST_CLASSIFIEDS, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_MARKETPLACE_CATEGORY } from '../../../../../constants/rails';
import { getArtist } from '../../../../../redux/slices/artist';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const maxCategoriesCount = 5;

const Categories = ({ categories = [] }) => {
  const [all, setAll] = useState(false);
  const [initialCategories, setInitialCategories] = useState();
  const [othersCategoriesCount, setOthersCategoriesCount] = useState(0);
  const artist = useSelector(getArtist);

  useEffect(() => {
    let categoriesCount = maxCategoriesCount;
    if (categories?.length > maxCategoriesCount) {
      categoriesCount = maxCategoriesCount - 1;
    }

    setInitialCategories(categories.slice(0, all ? 50 : categoriesCount));
    setOthersCategoriesCount(categories.slice(categoriesCount, 50).reduce((acc, c) => acc + c.count, 0));
  }, [categories?.length, maxCategoriesCount, all]);

  const handleClick = () => {
    aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_ARTIST_CLASSIFIEDS, section: ANALYTICS_SECTION_ARTIST_MARKETPLACE_CATEGORY, idartist: artist.id });
  };

  if (!initialCategories) return null;

  return (
    <div className="categories">
      {initialCategories.map(category => (
        <div className="category" key={`${category.count}-${category.label}`}>
          <a href={category.url} onClick={handleClick}>
            {category.label} (<FormattedNumber value={category.count} />)
          </a>
        </div>
      ))}

      {!all && othersCategoriesCount > 0 && (
        <div className="category category-all" key={`${othersCategoriesCount}-${'other'}`} onClick={() => setAll(true)}>
          <A18n rsx="artists.artist.lots.categories.m1" /> (<FormattedNumber value={othersCategoriesCount} />)
        </div>
      )}
    </div>
  );
};

export default Categories;
