import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import { CURRENCIES } from '../../../../constants/preferences';

export const CustomYAxisLabel = ({ currency, numberOfDigitsReduced }) => {
  let textualLargeNumber = '';
  switch (numberOfDigitsReduced) {
    case 3:
      textualLargeNumber = i18next.t('indexes.thousands');
      break;
    case 6:
      textualLargeNumber = i18next.t('indexes.millions');
      break;
    case 9:
      textualLargeNumber = i18next.t('indexes.billions');
      break;
    default:
      textualLargeNumber = '';
  }
  let label = '';
  if (currency) {
    label = i18next.t('indexes.barchart_yaxis.label_with_currency').replace('(', '').replace(')', '').replace('%currency%', CURRENCIES[currency].iso1);
  } else {
    label = i18next.t('indexes.barchart_yaxis.label_without_currency').replace('(', '').replace(')', '');
  }
  label = label.replace('%large_number%', textualLargeNumber).replace('(', '').replace(')', '');

  return (
    <text y={-10} x={-10} style={{ font: 'normal normal 600 14px/19px Open Sans' }}>
      {upperFirst(label)}
    </text>
  );
};

CustomYAxisLabel.defaultProps = {
  viewBox: { height: 0 },
};
CustomYAxisLabel.propTypes = {
  viewBox: PropTypes.shape({ height: PropTypes.number }),
  numberOfDigitsReduced: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
