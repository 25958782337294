/* eslint-disable global-require */
module.exports = [
  'subscription.breadcrumb.m8',
  'subscription.breadcrumb.m6',
  'subscription.breadcrumb.m7',
  'subscription.breadcrumb.m5',
  'subscription.breadcrumb.m1',
  'subscription.breadcrumb.m2',
  'subscription.breadcrumb.m3',
  'subscription.breadcrumb.m4',
];
