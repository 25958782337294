import React from 'react';
import { pickBy, identity, isPlainObject, isEmpty } from 'lodash';
import { string, oneOf, array, arrayOf, bool } from 'prop-types';
import { MODES } from '../../../constants/a18n';
import { a18n, getOptions, DEFAULT_OPTIONS } from '../../../utils/a18n';

/*
replace: array of tuple ([[regexp, str], [regexp2, str2], ...])
 */
const A18n = ({ rsx, mode, replace, link, trustHtml = false, ...otherProps }) => {
  const options = getOptions(pickBy({ mode, replace, link }, identity));

  // eslint-disable-next-line react/no-danger
  const text = trustHtml ? <span dangerouslySetInnerHTML={{ __html: a18n(rsx, options) }} /> : a18n(rsx, options);

  if (options.link) {
    return (
      <a className="gs" href={`https://site-bo.backoffice.artprice.io/translate/translate/search?query={"translate":{"rsxkey":{"eq":"${rsx}"}}}`} target="_blank" rel="noreferrer" {...otherProps}>
        {text}
      </a>
    );
  }

  if (isPlainObject(otherProps) && !isEmpty(otherProps)) {
    return <span {...otherProps}>{text}</span>;
  }

  return text;
};

A18n.propTypes = {
  trustHtml: bool,
  rsx: string.isRequired,
  mode: oneOf(MODES),
  replace: arrayOf(array),
  link: bool,
};

A18n.defaultProps = {
  trustHtml: false,
  mode: undefined,
  replace: DEFAULT_OPTIONS.replace,
  link: DEFAULT_OPTIONS.link,
};

export default A18n;
