import { useIntl } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { getAils, getArtist, getCategoriesFuture, getLotsFuture } from '../../../../../redux/slices/artist';
import { hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import {
  AILTYPE_LOT,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED,
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_FUTURE_LOT,
  ANALYTICS_SECTION_ARTIST_FUTURE_LOTS,
  ANALYTICS_SECTION_EMAIL_ALERT_CARD,
  ID_FREE_ARTIST,
  MODULE_FUTURESALE,
} from '../../../../../constants/rails';
import DOMView from '../../../../common/observers/DOMView';
import { UiContext } from '../../common/UiProvider';
import Reproduction from '../Reproduction';
import A18n from '../../../../common/A18n';
import Scroll from '../../common/Scroll';
import Categories from '../Categories';
import Lock from '../../common/Lock';
import Fake from './Fake';
import Ail from './Ail';

const Future = ({ onOpenDrawer, style = {} }) => {
  const intl = useIntl();
  const [lotsAil, setLotsAil] = useState([]);
  const [urlSeeAll, setUrlSeeAll] = useState();
  const [anchorSeeAll, setAnchorSeeAll] = useState();
  const ails = useSelector(getAils);
  const lots = useSelector(getLotsFuture);
  const categories = useSelector(getCategoriesFuture);
  const artist = useSelector(getArtist);
  const logged = useSelector(isLogged);
  const hasFutureSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_FUTURESALE }));
  const [lotCount, setLotCount] = useState(0);
  const [displayLock, setDisplayLock] = useState(false);
  const { lotsFutureRef } = useContext(UiContext);

  useEffect(() => {
    if (categories) {
      setLotCount(categories.reduce((nb, c) => nb + c.count, 0));
    }
  }, [categories]);

  useEffect(() => {
    if (artist.id === ID_FREE_ARTIST) {
      setDisplayLock(false);
    } else {
      setDisplayLock(!hasFutureSaleAccess || !logged);
    }
  }, [artist?.id, logged, hasFutureSaleAccess]);

  useEffect(() => {
    if (!isEmpty(get(ails, AILTYPE_LOT)) && get(ails, AILTYPE_LOT)) {
      setLotsAil(get(ails, AILTYPE_LOT));
    }
  }, [ails]);

  useEffect(() => {
    if (lotCount === 0 && lots.notSignedCount > 0) {
      setUrlSeeAll(lots.notSignedUrl);
      setAnchorSeeAll(i18next.t('artists.artist.lots.future.m22'));
    } else {
      if (isEmpty(artist.groupMembers)) {
        setAnchorSeeAll(i18next.t('artists.artist.lots.future.m7'));
      } else if (!isEmpty(artist.groupMembers)) {
        setAnchorSeeAll(i18next.t('artists.artist.lots.future.m14'));
      }
      setUrlSeeAll(lots.url);
    }
  }, [lotCount, lots.notSignedCount]);

  const handleOpenDrawer = ({ section, op, e, lot }) => {
    onOpenDrawer({ section, op, period: 'future', e, lot });
  };

  return (
    <div className="step-future" style={{ position: 'relative', ...style }} ref={lotsFutureRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.lots.future.m1" />
        </h2>
        {!isEmpty(categories) && (
          <>
            <p className="sub-h2">
              {isEmpty(artist.groupMembers) && (
                <>
                  {lots.notSignedCount === 0 && (
                    <>
                      {lotCount > 1 && (
                        <A18n
                          rsx="artists.artist.lots.future.m2"
                          replace={[
                            ['%artist%', artist.name],
                            ['%nb%', lotCount],
                          ]}
                        />
                      )}
                      {lotCount === 1 && <A18n rsx="artists.artist.lots.future.m3" replace={[['%artist%', artist.name]]} />}
                    </>
                  )}
                  {lots.notSignedCount > 0 && (
                    <A18n
                      rsx="artists.artist.lots.future.m21"
                      replace={[
                        ['%nb%', intl.formatNumber(lotCount + lots.notSignedCount)],
                        ['%artist%', artist.name],
                      ]}
                    />
                  )}
                </>
              )}
              {!isEmpty(artist.groupMembers) && (
                <>
                  {lotCount > 1 && (
                    <A18n
                      rsx="artists.artist.lots.future.m15"
                      replace={[
                        ['%artist%', artist.name],
                        ['%nb%', lotCount],
                      ]}
                    />
                  )}
                  {lotCount === 1 && <A18n rsx="artists.artist.lots.future.m16" replace={[['%artist%', artist.name]]} />}
                </>
              )}
            </p>
            <Categories
              period="future"
              categories={categories}
              clickOnCategories={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY, e })}
              clickOnNotSigned={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED, e })}
              lots={lots}
            />
          </>
        )}
        {isEmpty(categories) && (
          <>
            {lots.notSignedCount === 0 && (
              <p className="sub-h2">
                <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.lots.future.m4' : 'artists.artist.lots.future.m17'} replace={[['%artist%', artist.name]]} />
              </p>
            )}

            {lots.notSignedCount === 1 && (
              <p>
                <A18n rsx="artists.artist.lots.future.m5" replace={[['%artist%', artist.name]]} />
              </p>
            )}
            {lots.notSignedCount > 1 && (
              <p>
                <A18n
                  rsx="artists.artist.lots.future.m6"
                  replace={[
                    ['%artist%', artist.name],
                    ['%nb%', lots.notSignedCount],
                  ]}
                />
              </p>
            )}

            {lots.notSignedCount === 0 && <Fake direction="horizontal" />}
            {lots.notSignedCount > 0 && (
              <Categories
                period="future"
                categories={categories}
                clickOnCategories={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_BY_CATEGORY, e })}
                clickOnNotSigned={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST_NOT_SIGNED, e })}
                lots={lots}
              />
            )}
          </>
        )}
      </div>

      {!(isEmpty(lots.items) && isEmpty(lotsAil)) && (
        <Scroll
          aapiBeaconSection={ANALYTICS_SECTION_ARTIST_FUTURE_LOTS}
          className={`lots ${!isEmpty(lotsAil) ? `ails ails-${lotsAil.length}` : ''}`}
          renderComplement={() => {
            if (lotCount + lots.notSignedCount === 1) return null;

            return (
              <a
                id="sln_fs"
                href={urlSeeAll}
                className="btn btn-default link"
                onClick={e => handleOpenDrawer({ section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOTS_LIST, e })}
                style={{ paddingRight: 40 }}
              >
                {anchorSeeAll}
                {displayLock && <Lock stroke="#000" style={{ position: 'absolute' }} />}
              </a>
            );
          }}
        >
          {!isEmpty(lotsAil) && lotsAil.map(ail => <Ail key={ail.id} ail={ail} />)}
          {lots.items
            .filter(lot => !lotsAil.map(ail => ail.discriminant).includes(lot.id))
            .map(lot => (
              <a href={lot.url} onClick={e => handleOpenDrawer({ lot, section: ANALYTICS_SECTION_ARTIST_FUTURE_LOT, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_FUTURE_LOT, e })} key={`l-${lot.url}`}>
                <div className="lot">
                  <div className="tag">
                    <div className="dt">
                      {lot.saleDtStart}
                      {lot.saleDtEnd && ` - ${lot.saleDtEnd}`}
                    </div>
                    {lot.saleCountry && <div className="country">{lot.saleCountry}</div>}
                  </div>
                  <Reproduction lot={lot} />
                  <p>{lot.category}</p>
                  {lot.lotartistassociationtype && <p>{lot.lotartistassociationtype}</p>}
                  {displayLock && !lot.ail && (
                    <div className="lock">
                      <Lock stroke="#b4b2ab" style={{ marginRight: 5 }} />
                      <span>
                        <A18n rsx="artists.artist.lots.future.m13" />
                      </span>
                    </div>
                  )}
                </div>
              </a>
            ))}
          <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_EMAIL_ALERT_CARD} selector=".fake" idartist={artist.id} />
          <Fake />
        </Scroll>
      )}
    </div>
  );
};

export default Future;
