import React from 'react';
import './stylesheet.scss';
import A18n from '../../common/A18n';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { ANALYTICS_FROM_HOMEPAGE, ANALYTICS_CLICK_OPERATION_TO_ARTINVESTMENT } from '../../../constants/rails';

const Studies = () => (
  <div className="homepage-studies block">
    <div className="container">
      <div className="l0">
        <A18n rsx="homepage.studies.m1" />
      </div>
      <div className="l1">
        <A18n rsx="homepage.studies.m2" />
      </div>
      <div className="l2">
        <A18n rsx="homepage.studies.m3" />
      </div>
      <div className="l3">
        <a href="/indexes/artinvestment" className="btn btn-default  btn-animated" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_ARTINVESTMENT })}>
          <A18n rsx="homepage.studies.m4" />
        </a>
      </div>
    </div>
  </div>
);

export default Studies;
