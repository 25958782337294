import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_PROMOTION_NOTIFY, ANALYTICS_SECTION_PROMO_NOTIFIER_BANNER } from '../../../constants/rails';
import { getSessionStorage, setSessionStorage, getLocaleStorage, setLocaleStorage } from '../../../utils/storage';
import { usePromotionNotify } from '../../../hooks/usePromotionNotify';
import { aapiBeacon } from '../../../services/analytics/aapi';
import A18n from '../../common/A18n';

import './stylesheet.scss';

export const MAX_DISPLAY_COUNT_ALLOWED_IN_SESSION = 3;
export const MAX_DISPLAY_COUNT_ALLOWED = 15;

const PromotionNotify = () => {
  const [visible, setVisible] = useState(false);
  const { promotionNotify, displayCountLocaleStorageKey, displayCountSessionstorageKey } = usePromotionNotify();

  useEffect(() => {
    const displayCountInSession = getSessionStorage(displayCountSessionstorageKey) || 0;
    const displayCount = getLocaleStorage(displayCountLocaleStorageKey) || 0;

    if (displayCountInSession < MAX_DISPLAY_COUNT_ALLOWED_IN_SESSION && displayCount < MAX_DISPLAY_COUNT_ALLOWED) {
      if (!promotionNotify?.forceClose && promotionNotify?.status === 1) {
        setSessionStorage(displayCountSessionstorageKey, displayCountInSession + 1);
        setLocaleStorage(displayCountLocaleStorageKey, displayCount + 1);

        aapiBeacon('dom_view', { from: ANALYTICS_FROM_PROMOTION_NOTIFY, section: ANALYTICS_SECTION_PROMO_NOTIFIER_BANNER, slug: `display-${displayCount + 1}` });
        setTimeout(() => {
          setVisible(true);
        }, 2000);
      }
    }
  }, [displayCountLocaleStorageKey, displayCountSessionstorageKey, promotionNotify]);

  if (promotionNotify?.status !== 1) return null;

  const updateStorage = () => {
    const displayCountInSession = getSessionStorage(displayCountSessionstorageKey);
    const displayCount = getLocaleStorage(displayCountLocaleStorageKey);

    setLocaleStorage(displayCountLocaleStorageKey, displayCount + MAX_DISPLAY_COUNT_ALLOWED_IN_SESSION - displayCountInSession);
    setSessionStorage(displayCountSessionstorageKey, MAX_DISPLAY_COUNT_ALLOWED_IN_SESSION);
  };

  const onClose = () => {
    updateStorage();
    setVisible(false);
  };

  const onClick = () => {
    const displayCount = getLocaleStorage(displayCountLocaleStorageKey);
    aapiBeacon('click', { op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, from: ANALYTICS_FROM_PROMOTION_NOTIFY, section: ANALYTICS_SECTION_PROMO_NOTIFIER_BANNER, slug: `display-${displayCount}` });

    updateStorage();
    setVisible(false);
    window.location = `/subscription?promocode=${promotionNotify.promocode}&type=new`;
  };

  return (
    <div
      className={classNames({
        'layout-header-promotion-notify': true,
        visible,
      })}
    >
      <div className="container">
        {/* eslint-disable-next-line react/no-danger */}
        <span className="content" dangerouslySetInnerHTML={{ __html: promotionNotify.description }} />

        <button type="button" onClick={onClick}>
          <A18n rsx="layout.header.promotion.notify.m1" />
        </button>
      </div>
      <div className="close-notifier" onClick={onClose}>
        X
      </div>
    </div>
  );
};

export default PromotionNotify;
