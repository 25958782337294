import React from 'react';
import i18next from 'i18next';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { func, string, number, bool } from 'prop-types';
import DropDown from '../../../common/DropDown';
import { isCurrentDraftDirty } from '../../../../redux/selectors/search';
import { getCurrentDraftCount } from '../../../../redux/selectors/search/info';
import './stylesheet.scss';

const DropDownToValidate = ({ title, icon, displayIconDesktop, render, onCancel, onSubmit, draftCount, isDraftDirty, className, ...otherProps }) => (
  <DropDown
    className={`common-drop-down-to-validate ${className || ''}`}
    title={title}
    icon={icon}
    displayIconDesktop={displayIconDesktop}
    onClose={onCancel}
    render={({ close }) => (
      <>
        {render({ close })}
        {isDraftDirty && <div className="total-count">{i18next.t('common.dropdowntovalidate.m1').replace('%count%', draftCount)}</div>}
        <div className="submit-and-cancel">
          <button
            type="button"
            className="btn btn-default pull-left"
            onClick={() => {
              close();
              onCancel();
            }}
          >
            {i18next.t('sales.search.filters.m2')}
          </button>

          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={() => {
              close();
              onSubmit();
            }}
          >
            {i18next.t('sales.search.filters.m1')}
          </button>
        </div>
      </>
    )}
    {...otherProps}
  />
);

DropDownToValidate.defaultProps = {
  onCancel: noop,
  onSubmit: noop,
  icon: '',
  className: '',
  draftCount: 0,
  isDraftDirty: false,
};

DropDownToValidate.propTypes = {
  title: string.isRequired,
  icon: string,
  render: func.isRequired,
  className: string,
  onCancel: func,
  onSubmit: func,
  draftCount: number,
  isDraftDirty: bool,
};

function mapStateToProps(state) {
  return {
    draftCount: getCurrentDraftCount(state),
    isDraftDirty: isCurrentDraftDirty(state),
  };
}

export default connect(mapStateToProps, null)(DropDownToValidate);
