import React from 'react';
import { isEqual } from 'lodash';
import useGtm from '../../../hooks/useGtm';

const optionsEquality = (prevProps, nextProps) => isEqual(prevProps.options, nextProps.options);

const Gtm = React.memo(({ options }) => {
  useGtm(options);
  return null;
}, optionsEquality);

Gtm.defaultProps = {
  options: {},
};

export default Gtm;
