import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_ARTIST_SIGNATURES, ANALYTICS_FROM_ARTIST, IMAGE_SOURCE, MODULE_IMAGE, MODULE_PASTSALE, THEME_BASICS } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';
import { getArtist } from '../../../../../redux/slices/artist';
import './stylesheet.scss';
import Drawer from '../../common/Drawer';
import { on } from '../../../../../redux/actions/ui/switches';
import { getCurrentBillCurrency, hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import { getBannerUrlSml, getCode } from '../../../../../redux/slices/promotion';
import { getTheme } from '../../../../../redux/selectors/subscriptions/physicproducts';
import Lock from '../../common/Lock';

const ID_DRAWER = 'signature-drawer';

const Signature = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const artist = useSelector(getArtist);
  const promocodecode = useSelector(getCode);
  const [priceOneDay, setPriceOneDay] = useState();
  const artpriceBanners = useSelector(getBannerUrlSml);
  const currency = useSelector(getCurrentBillCurrency);
  const themeBasic = useSelector(state => getTheme(state, { idtheme: THEME_BASICS }));
  const hasImageAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_IMAGE }));
  const hasPastsaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_PASTSALE }));
  const logged = useSelector(isLogged);

  useEffect(() => {
    if (themeBasic) {
      const physicproduct = themeBasic.physicproducts.filter(p => p.original_nbdays === 1)[0];

      if (physicproduct?.price[currency]) {
        setPriceOneDay(
          intl.formatNumber(physicproduct?.price[currency], {
            style: 'currency',
            currency,
          }),
        );
      }
    }
  }, [themeBasic]);

  if (artist?.signatures?.count === 0) return <div className="artists-artist-header-signatures" />;

  const openDrawer = () => {
    aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_SIGNATURES, idartist: artist.id });
    dispatch(on(ID_DRAWER));
  };

  return (
    <div className="artists-artist-header-signatures">
      {artist.signatures.items[0] && (
        <div className="signature-container" style={{ cursor: 'pointer' }}>
          <a href="#" onClick={openDrawer}>
            <div className="signature" style={{ backgroundImage: `url(${artist.signatures.items[0]})` }} />
          </a>
        </div>
      )}
      <div className="links">
        <div className="sig">
          {(!logged || !hasImageAccess) && (
            <div className="lock">
              <Lock stroke="#000" />
            </div>
          )}
          <div className="od">
            <a href="#" onClick={openDrawer}>
              <A18n rsx="artists.artist.header.m9" />
            </a>
          </div>
        </div>
      </div>

      <Drawer id={ID_DRAWER} className={artist.signatures.items.length > 0 ? 'white' : ''}>
        <div className={`drawer-content ${artist.signatures.items.length === 0 ? 'no-access' : ''}`}>
          <p>
            <img alt="drawer-signature" src={`${IMAGE_SOURCE}artist/icon-signature.svg?t=1`} style={{ width: 119 }} />
          </p>

          <h3>
            <A18n rsx={artist.signatures.items.length > 0 ? 'artists.artist.header.signatures.m6' : 'artists.artist.header.signatures.m4'} replace={[['%artist%', artist.name]]} />
          </h3>
          {artist.signatures.items.length > 0 && (
            <div className="signatures">
              {artist.signatures.items.map(signature => (
                <div className="item" key={signature}>
                  <img alt="Signature" src={signature} />
                </div>
              ))}
            </div>
          )}
          {artist.signatures.items.length === 0 && (
            <div className="item">
              {!hasImageAccess && hasPastsaleAccess && !priceOneDay && <A18n rsx="artists.artist.header.signatures.m3" trustHtml />}
              {(!hasPastsaleAccess || !logged) && (
                <>
                  {priceOneDay && <A18n rsx="artists.artist.header.signatures.m1" replace={[['%price-oneday%', priceOneDay]]} trustHtml />}
                  {!priceOneDay && <A18n rsx="artists.artist.header.signatures.m2" trustHtml />}
                </>
              )}
              {artpriceBanners && (
                <p className="marg marg-t-30">
                  {/* eslint-disable-next-line react/no-danger */}
                  <a href={`/subscription?promocode=${promocodecode}`}>
                    <span dangerouslySetInnerHTML={{ __html: artpriceBanners }} />
                  </a>
                </p>
              )}
              <p className="marg marg-t-30">
                <a className="btn btn-default link" href={`/subscription${promocodecode ? `?promocode=${promocodecode}` : ''}`}>
                  {!hasImageAccess && hasPastsaleAccess ? <A18n rsx="artists.artist.header.signatures.m5" /> : <A18n rsx="artists.artist.lots.noaccessdrawer.m3" />}
                </a>
              </p>
              {!logged && (
                <p className="login">
                  <A18n rsx="artists.artist.lots.noaccessdrawer.m4" />{' '}
                  <a href="/identity/signatures" id="sln_login">
                    <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
                  </a>
                </p>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};
export default Signature;
