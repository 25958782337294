import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import { getDataList } from '../../../../../services/wallet/customerlotslist';
import DocumentList from '../documents/List';
import { getLang } from '../../../../../redux/selectors/preferences';

const Pdf = ({ id }) => {
  const [data, setData] = useState();
  const lang = useSelector(getLang);

  useEffect(() => {
    if (id) {
      getDataList({ id }).then(d => setData(d));
    }
  }, [id]);

  if (!data) return null;

  return (
    <PDFViewer showToolbar width="100%" height="100%">
      <IntlProvider locale={lang}>
        <DocumentList data={data} />
      </IntlProvider>
    </PDFViewer>
  );
};
export default Pdf;
