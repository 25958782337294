import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import customStyles from '../../../../utils/styles/reactSelectStyle';
import { useViewport } from '../../../../hooks/useViewport';

const BasicDropdown = ({ name, control, rules = {}, placeholder, loadingMessage, isSearchable, options, isLoading, readOnly, width }) => {
  const { viewportWidth } = useViewport();
  const handleChange = (value, onChange) => {
    onChange(value.value);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Select
          styles={customStyles({ width, viewportWidth })}
          classNamePrefix="react-select"
          options={options}
          isLoading={isLoading}
          readOnly={readOnly}
          isSearchable={isSearchable}
          isDisabled={readOnly}
          placeholder={placeholder}
          loadingMessage={loadingMessage}
          value={options.find(option => option.value === value)}
          onChange={v => handleChange(v, onChange)}
        />
      )}
    />
  );
};

export default BasicDropdown;
