import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getCurrentBillCurrency, isLogged } from '../../../../../redux/slices/userContext/selectors';
import { getTheme } from '../../../../../redux/selectors/subscriptions/physicproducts';
import { getAutomaticallyApplied, getBannerUrlSml, getCode } from '../../../../../redux/slices/promotion';
import { IMAGE_SOURCE, THEME_BASICS } from '../../../../../constants/rails';
import Drawer from '../../common/Drawer';
import A18n from '../../../../common/A18n';
import Future from './Future';
import Past from './Past';

const NoAccessDrawer = ({ drawerId, section, lotCount, intl }) => {
  const themeBasic = useSelector(state => getTheme(state, { idtheme: THEME_BASICS }));
  const [priceOneDay, setPriceOneDay] = useState();
  const currency = useSelector(getCurrentBillCurrency);
  const artpriceBanners = useSelector(getBannerUrlSml);
  const promocodecode = useSelector(getCode);
  const automaticallyApplied = useSelector(getAutomaticallyApplied);
  const logged = useSelector(isLogged);

  useEffect(() => {
    if (themeBasic) {
      const physicproduct = themeBasic.physicproducts.filter(p => p.original_nbdays === 1)[0];

      if (physicproduct?.price[currency]) {
        setPriceOneDay(
          intl.formatNumber(physicproduct?.price[currency], {
            style: 'currency',
            currency,
          }),
        );
      }
    }
  }, [themeBasic]);

  return (
    <Drawer id={drawerId} className="drawer-lots">
      <p>
        <img alt="drawer-lots" src={`${IMAGE_SOURCE}artist/drawer-lots.png`} />
      </p>

      {section === 'past' && <Past priceOneDay={priceOneDay} lotCount={lotCount} />}
      {section === 'future' && <Future priceOneDay={priceOneDay} />}

      {artpriceBanners && (
        <p className="marg marg-t-30">
          {/* eslint-disable-next-line react/no-danger */}
          <a href={`/subscription${promocodecode && !automaticallyApplied ? `?promocode=${promocodecode}` : ''}`}>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: artpriceBanners }} />
          </a>
        </p>
      )}

      <p className="marg marg-t-30">
        <a className="btn btn-default link sln-link" href={`/subscription${promocodecode && !automaticallyApplied ? `?promocode=${promocodecode}` : ''}`}>
          <A18n rsx="artists.artist.lots.noaccessdrawer.m3" />
        </a>
      </p>
      {!logged && (
        <p className="login">
          <A18n rsx="artists.artist.lots.noaccessdrawer.m4" />{' '}
          <a href="/identity/sales" id="sln_login_lots">
            <A18n rsx="artists.artist.lots.noaccessdrawer.m5" />
          </a>
        </p>
      )}
    </Drawer>
  );
};

export default injectIntl(NoAccessDrawer);
