import React, { lazy, Suspense } from 'react';
import Spinner from '../spinners/Spinner';

const LazyEditor = lazy(() => import(/* webpackChunkName: "lazywysiwyg" */ './Lazy'));
const HtmlEditor = ({ ...props }) => (
  <Suspense fallback={<Spinner />}>
    <LazyEditor {...props} />
  </Suspense>
);

export default HtmlEditor;
