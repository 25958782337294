import React from 'react';
import { useSelector } from 'react-redux';
import { getArtist, getFree } from '../../../../../../redux/slices/artist';
import A18n from '../../../../../common/A18n';

const Future = ({ priceOneDay }) => {
  const artist = useSelector(getArtist);
  const freeArtist = useSelector(getFree);

  return (
    <>
      <h3>
        <A18n rsx="artists.artist.lots.noaccessdrawer.m8" replace={[['%artist%', artist.name]]} />
      </h3>
      <p className="args">
        {priceOneDay && <A18n rsx="artists.artist.lots.noaccessdrawer.m9" trustHtml replace={[['%price-oneday%', priceOneDay]]} />}
        {!priceOneDay && <A18n rsx="artists.artist.lots.noaccessdrawer.m13" trustHtml />}
      </p>
      {freeArtist && (
        <p>
          <A18n
            rsx="artists.artist.lots.noaccessdrawer.m12"
            trustHtml
            replace={[
              ['%a%', `<a style="text-decoration: underline" href="${freeArtist.url}">`],
              ['%/a%', '</a>'],
            ]}
          />
        </p>
      )}
    </>
  );
};
export default Future;
