import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Line, LineChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getPriceIndexOptions, getIndexedCategories, getComparedArtistName, isMergedIndexDataLoading, getMergedData, getMergedVisibleData } from '../../../../redux/selectors/indexes/priceIndex';
import { getArtistName } from '../../../../redux/selectors/indexes/artist';
import Spinner from '../../../common/spinners/Spinner/index';
import { useViewport } from '../../../../hooks/useViewport';

const PriceIndexLineChart = ({ isMergedDataLoading, mergedData, mergedVisibleData = {}, options, artistName, comparedArtistName, indexedCategories }) => {
  const { viewportWidth } = useViewport();

  if (isMergedDataLoading) {
    return (
      <div style={{ height: 400 }}>
        <Spinner />
      </div>
    );
  }
  if (!artistName || !mergedVisibleData.artist) {
    return <div style={{ height: 400 }}>No data found</div>;
  }

  let stockIndexLegend = '';
  let categoryName = '';
  const comparedArtistNameLegend = comparedArtistName || 'Compared artist';
  if (options) {
    switch (options.stockIndex) {
      case 'globalIndex':
        stockIndexLegend = i18next.t('indexes._indexes_chart.m5');
        break;
      case 'sp500':
        stockIndexLegend = 'S&P 500';
        break;
      case 'cac40':
        stockIndexLegend = 'CAC 40';
        break;
      case 'dax':
        stockIndexLegend = 'DAX';
        break;
      default:
        stockIndexLegend = 'Unknown stock index name';
    }
    if (indexedCategories) {
      const categoryInfos = indexedCategories.find(category => parseInt(category.id, 10) === parseInt(options.idcategory, 10));
      if (categoryInfos) categoryName = categoryInfos.label;
    }
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={mergedData} margin={{ top: 5, right: 5, bottom: 50, left: -35 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" angle={-55} textAnchor="end" fontSize={12} interval={viewportWidth < 768 ? 2 : 0} />
        <YAxis />
        <Tooltip />
        <Line dataKey="artist" name={artistName} type="monotone" stroke="#3866C8" activeDot={{ r: 8 }} dot={false} animationDuration={250} strokeWidth="3.25" />
        {mergedVisibleData.stockIndex && <Line dataKey="stockIndex" name={stockIndexLegend} type="monotone" stroke="#C91618" dot={false} animationDuration={250} strokeWidth="2.25" />}
        {mergedVisibleData.category && <Line dataKey={`category-${options.idcategory}`} name={categoryName} type="monotone" stroke="#EDB409" dot={false} animationDuration={250} strokeWidth="2.25" />}
        {mergedVisibleData.comparedArtist && <Line dataKey={`artist-${options.idartist}`} name={comparedArtistNameLegend} type="monotone" stroke="#0BABB8" dot={false} animationDuration={250} strokeWidth="2.25" />}
      </LineChart>
    </ResponsiveContainer>
  );
};

PriceIndexLineChart.defaultProps = {
  artistName: undefined,
  isMergedDataLoading: undefined,
  mergedData: null,
  mergedVisibleData: {},
  comparedArtistName: undefined,
  indexedCategories: undefined,
};

PriceIndexLineChart.propTypes = {
  artistName: PropTypes.string,
  comparedArtistName: PropTypes.string,
  indexedCategories: PropTypes.arrayOf(PropTypes.object),

  isMergedDataLoading: PropTypes.bool,

  mergedData: PropTypes.arrayOf(PropTypes.object),

  mergedVisibleData: PropTypes.shape({
    artist: PropTypes.bool,
    comparedArtist: PropTypes.bool,
    stockIndex: PropTypes.bool,
    category: PropTypes.bool,
  }),

  // eslint-disable-next-line
  options: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    artistName: getArtistName(state),
    comparedArtistName: getComparedArtistName(state),
    indexedCategories: getIndexedCategories(state),

    isMergedDataLoading: isMergedIndexDataLoading(state),

    options: getPriceIndexOptions(state),

    mergedData: getMergedData(state),

    mergedVisibleData: getMergedVisibleData(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceIndexLineChart);
