import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddress, getBillAddress, getCart, getShipAddress, updateBillAddress, updateShipAddress } from '../../../../redux/slices/payments';
import { getVerifiedAt } from '../../../../redux/slices/userContext/selectors';
import Address from './Address';

const Addresses = () => {
  const dispatch = useDispatch();
  const { needs_ship_address: needsShipAddress } = useSelector(getCart);
  const bill = useSelector(getBillAddress);
  const ship = useSelector(getShipAddress);
  const verifiedAt = useSelector(getVerifiedAt);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(isNil(verifiedAt));
  }, [verifiedAt]);

  useEffect(() => {
    dispatch(fetchAddress());
  }, []);

  return (
    <div className="payments-cart-summary-addresses">
      {bill && <Address title="Adresse de facturation" address={bill} updateAction={updateBillAddress} editable={editable} withTva />}
      {ship && needsShipAddress && <Address title="Adresse de livraison" address={ship} updateAction={updateShipAddress} />}
    </div>
  );
};

export default Addresses;
