import i18next from 'i18next';
import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { getCurrentDefaultFacets, getCurrentUrlFacets, getCurrentUrlParams, isCurrentSearchPerformed, getCurrentDraftFacets, getCurrentLoadingFacets, getCurrentDraftTotalCount } from '../../../../redux/selectors/search';
import { areCurrentResultsLoading } from '../../../../redux/selectors/search/results';
import { applyCurrentDraft, updateCurrentDraft } from '../../../../redux/actions/search';
import { getItemsPerpage } from '../../../../redux/selectors/preferences';

import './stylesheet.scss';

function isFacetSelected({ id, urlParams, facetName }) {
  if (id === 0 && (urlParams[facetName] === [] || urlParams[facetName] === undefined)) {
    return true;
  }
  if (Array.isArray(urlParams[facetName])) {
    return urlParams[facetName].map(idStr => parseInt(idStr, 10)).includes(id);
  }

  return String(urlParams[facetName]) === String(id);
}

function getCount({ id, facet, urlFacets, draftFacets, searchPerformed, facetName }) {
  if (searchPerformed) {
    if (get(`urlParams.${facetName}`, id)) {
      return get(urlFacets, `${facetName}.${id}.count`, 0);
    }
    return get(draftFacets, `${facetName}.${id}.count`, 0);
  }
  return facet.count;
}

const QuickFilter = ({ facetName, itemsFilter, onFilterClick }) => {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState('');
  const defaultFacets = useSelector(getCurrentDefaultFacets);
  const loadingFacets = useSelector(getCurrentLoadingFacets) || false;
  const loadingResults = useSelector(areCurrentResultsLoading) || false;
  const urlFacets = useSelector(getCurrentUrlFacets);
  const urlParams = useSelector(getCurrentUrlParams);
  const totalCount = useSelector(state => getCurrentDraftTotalCount(state, facetName));
  const perPage = useSelector(getItemsPerpage);
  const draftFacets = useSelector(getCurrentDraftFacets);
  const searchPerformed = useSelector(state => isCurrentSearchPerformed(state, [facetName]), shallowEqual) || false;

  const appendLoadingClassName = className => {
    if (loadingFacets || loadingResults) {
      return `loading ${className}`;
    }

    return className;
  };

  const facetSelect = id => {
    if (loadingFacets || loadingResults) {
      return;
    }

    let ids;
    if (id !== 0) {
      ids = [id];
    }

    dispatch(updateCurrentDraft({ triggeredParam: facetName, value: ids }));
    dispatch(applyCurrentDraft({ ipp: perPage, updateDefaultFacets: false }));

    if (onFilterClick) {
      onFilterClick(facetName);
    }
  };

  const filterOption = ({ id, count, label }) => {
    const facetSelected = isFacetSelected({ id, urlParams, facetName });
    return (
      <div role="presentation" key={`filter-${facetName}-${id}`} className={appendLoadingClassName('facet')} onClick={() => facetSelect(id)}>
        <div className="check">{(facetSelected && <i className={appendLoadingClassName('fa fa-dot-circle-o')} aria-hidden="true" />) || <i className={appendLoadingClassName('fa fa-circle-o')} aria-hidden="true" />}</div>
        <div className={`name ${facetSelected ? 'selected' : ''}`}>{label}</div>
        <div className={`count ${facetSelected ? 'selected' : ''}`}>{count}</div>
      </div>
    );
  };

  if (totalCount === 0) {
    return null;
  }

  const facets = Object.values(defaultFacets[facetName] || {});
  if (facets.every(facet => facet.sort)) {
    facets.sort((a, b) => a.sort.localeCompare(b.sort));
  }

  return (
    <>
      {itemsFilter && (
        <div className="filter">
          <Input className="input-sm" type="text" placeholder={i18next.t('components.myartprice.summary.m3')} value={filterText} onChange={e => setFilterText(e.target.value)} />
        </div>
      )}
      <div className="facets">
        <b>
          {defaultFacets[facetName] &&
            filterOption({
              id: 0,
              label: i18next.t('components.search.common.quickfilter.m1'),
              count: facets.reduce((count, facet) => {
                if (itemsFilter && filterText.length >= 2 && !facet.label.match(new RegExp(filterText, 'gi'))) return count;
                return count + getCount({ facetName, id: facet.id, facet, urlFacets, searchPerformed, draftFacets });
              }, 0),
            })}
        </b>
        {facets.map(facet => {
          if (itemsFilter && filterText.length >= 2 && !facet.label.match(new RegExp(filterText, 'gi'))) {
            return null;
          }

          const count = getCount({ facetName, id: facet.id, facet, urlFacets, searchPerformed, draftFacets });
          if (count === 0) {
            return null;
          }

          return filterOption({ id: facet.id, label: facet.label, count });
        })}
      </div>
    </>
  );
};

QuickFilter.defaultProps = {
  itemsFilter: false,
};

QuickFilter.propTypes = {
  facetName: string.isRequired,
  itemsFilter: bool,
};

export default QuickFilter;
