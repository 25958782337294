import React from 'react';
import { string } from 'prop-types';
import i18next from 'i18next';
import Select from '../../../common/FacetsSelect';

const component = ({ className }) => <Select placeholder={i18next.t('search.filters.artist.facet.m2')} isMulti className={className} facetName="idartist" />;

component.defaultProps = {
  className: undefined,
};

component.propTypes = {
  className: string,
};

export default component;
