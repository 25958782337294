import { StyleSheet } from '@react-pdf/renderer';
import '../common/styles';

export const imageMaxDimension = 550;

export const styles = StyleSheet.create({
  page: { flexDirection: 'column', fontSize: 12, paddingBottom: 100 },
  section: { margin: 2, padding: 20 },
  header: { flexDirection: 'row-reverse', width: '100%', marginTop: 20, marginBottom: 10, textTransform: 'uppercase' },
  headerText: { backgroundColor: '#000', color: '#fff', padding: 10, paddingRight: 20, fontFamily: 'Noto Sans TC' },
  cell: { flexDirection: 'column', flex: 1, padding: 10 },
  sectionTitle: { fontWeight: 700, fontSize: 14, marginBottom: 12, fontFamily: 'Noto Sans' },
  text: { marginBottom: 4, fontFamily: 'Noto Sans' },
  block: { marginBottom: 10, fontFamily: 'Noto Sans' },
  reproductionContainer: { alignItems: 'center', marginTop: 10 },
  reproduction: { alignItems: 'center', height: imageMaxDimension + 10, width: imageMaxDimension + 10 },
});
