import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getCartLoading, getCart, getParams } from '../../../../redux/slices/payments';
import A18n from '../../../common/A18n';

const Summary = () => {
  const { quantity, from, idestimates = [] } = useSelector(getParams);
  const [product, setProduct] = useState();
  const loading = useSelector(getCartLoading);
  const cart = useSelector(getCart);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (cart?.main_products?.[0]?.product && !cart.loading) {
      setProduct(cart.main_products[0].product);
      setTotal(cart.main_products.reduce((acc, lineCart) => acc + lineCart.product.prices[cart.currency.iso3.toLowerCase()] * lineCart.product_quantity, 0));
    }
  }, [cart]);

  return (
    <div className="payments-platforms-summary">
      <div className={classNames(['summary-container', from])}>
        <div className="container">
          {!loading && product && (
            <div>
              <h2>
                {from === 'estimate' && (
                  <>
                    {/* 1 artpricing report */}
                    {idestimates.length === 1 && <A18n rsx="payments.platforms.summary.m5" />}
                    {/* x artpricing report */}
                    {idestimates.length > 1 && <A18n rsx="payments.platforms.summary.m6" replace={[['%nb%', idestimates.length]]} />}
                    {/* artpricing units */}
                    {idestimates.length === 0 && product.title}
                  </>
                )}
                {from === 'shop' && <A18n rsx="layouts.menu.m21" />}
                {from !== 'shop' && from !== 'estimate' && product.title}{' '}
                {total > 0 && (
                  <span>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    : <A18n rsx="payments.platforms.summary.m1" /> <FormattedNumber value={total} style="currency" currency={cart.currency.iso3.toLowerCase()} />
                  </span>
                )}
              </h2>
              {from !== 'shop' && from !== 'estimate' && (
                <div className="summary">
                  {quantity === 1 && <A18n rsx="payments.platforms.summary.m2" />}
                  {quantity > 1 && <A18n rsx="payments.platforms.summary.m3" replace={[['%nb%', quantity]]} />}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Summary;
