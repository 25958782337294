import { noop } from 'lodash';
import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import PropTypes, { func, string, number, bool } from 'prop-types';
import DropDown from '../../../common/DropDown';
import { isCurrentDraftDirty } from '../../../../redux/selectors/search';
import { getCurrentDraftCount } from '../../../../redux/selectors/search/info';
import './stylesheet.scss';

const ClosingDropDown = ({ title, icon, displayIconDesktop, render, onCancel, draftCount, isDraftDirty, className, onClose, ...otherProps }) => (
  <DropDown
    className={`common-drop-down-to-validate ${className || ''}`}
    title={title}
    icon={icon}
    displayIconDesktop={displayIconDesktop}
    onClose={onCancel}
    render={({ close }) => (
      <>
        {render({ close })}
        {isDraftDirty && <div className="total-count">{i18next.t('common.dropdowntovalidate.m1').replace('%count%', draftCount)}</div>}
        <div className="submit-and-cancel">
          <button
            type="button"
            className="btn btn-default btn-flat pull-right"
            onClick={() => {
              close();
              if (onClose) {
                onClose();
              }
            }}
          >
            {i18next.t('search.bars.common.closingdropdown.m1')}
          </button>
        </div>
      </>
    )}
    {...otherProps}
  />
);

ClosingDropDown.defaultProps = {
  title: '',
  icon: '',
  displayIconDesktop: false,
  className: '',
  draftCount: 0,
  isDraftDirty: false,
  onClose: noop,
};

ClosingDropDown.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  icon: string,
  displayIconDesktop: bool,
  onClose: func,
  render: func.isRequired,
  className: string,
  draftCount: number,
  isDraftDirty: bool,
};

function mapStateToProps(state) {
  return {
    draftCount: getCurrentDraftCount(state),
    isDraftDirty: isCurrentDraftDirty(state),
  };
}

export default connect(mapStateToProps, null)(ClosingDropDown);
