import React from 'react';
import { number, bool, string } from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { getSale } from '../../../../redux/selectors/dbEntities';

const Sale = ({ id, link, linkText }) => {
  const sale = useSelector(store => getSale(store, { id }), shallowEqual) || [];

  if (!link) {
    return sale.title;
  }

  if (!sale.url) {
    return linkText || sale.title;
  }

  return <a href={sale.url}>{linkText || sale.title}</a>;
};

Sale.defaultProps = {
  link: false,
  linkText: undefined,
};

Sale.propTypes = {
  id: number.isRequired,
  link: bool,
  linkText: string,
};

export default Sale;
