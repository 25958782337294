import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCartLoading, updateNavigationStep } from '../../../redux/slices/payments';
import ValidateButton from './ValidateButton';
import Addresses from './Addresses';
import Promocode from './Promocode';
import Summary from './Summary';
import Cart from './Cart';
import Spinner from '../../common/spinners/Spinner';

const CartSummary = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getCartLoading);

  // Set step for the progressbar
  useEffect(() => {
    dispatch(updateNavigationStep(2));
  }, []);

  return (
    <div className="payments-cart-summary">
      <Summary />
      {loading && <Spinner />}
      {!loading && (
        <div className="cart-container container">
          <Addresses />
          <Promocode />
          <Cart />
          <ValidateButton />
        </div>
      )}
    </div>
  );
};

export default CartSummary;
