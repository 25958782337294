import React from 'react';
import i18next from 'i18next';
import { Page, Document, View, Text } from '@react-pdf/renderer';
import { formatFns } from '../../../../../../utils/dates/format';
import { styles, imageMaxDimension } from './styles';
import Reproduction from './Reproduction';
import Footer from '../common/Footer';
import { parsePrice } from '../common/utils';

const Show = ({ data, currency = 'eur' }) => {
  const { lot } = data;

  const hasDetails =
    lot.distinctivesSigns ||
    lot.lotartistassociationcomplement ||
    lot.signaturePosition ||
    lot.descriptionAndLitterature ||
    lot.provenance ||
    lot.exposition ||
    lot.condition ||
    (lot.quantity && lot.quantity > 1) ||
    lot.contractid;
  const hasNFTDetails = lot.tokenid || lot.walletid || lot.minted_on || lot.media_type || lot.url_plateform || lot.transactionid;

  return (
    <Document language="fr">
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.header}>
          <Text style={styles.headerText}>
            {i18next.t('components.lotslists.pdf.m4')} - {lot.title} {lot.creationDate && `(${lot.creationDate})`}
          </Text>
        </View>
        <View key={lot.id} style={styles.section}>
          <View style={{ flexDirection: 'column' }}>
            <View style={styles.block}>
              <Text style={styles.sectionTitle}>{lot.artistOrAssociationWithDates}</Text>
              {lot.number && (
                <Text style={styles.text}>
                  {i18next.t('artprice.lib.psresultctrl.m18')} {lot.number}
                </Text>
              )}
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 20 }}>
              <View style={{ width: '50%' }}>
                <View style={styles.block}>
                  <Text style={styles.text}>{lot.category}</Text>
                  <Text style={styles.text}>{lot.techniqueAndPrecision}</Text>
                  {lot.numberAndEdition && <Text style={styles.text}>{lot.numberAndEdition}</Text>}
                  <Text style={styles.text}>{lot.dimension}</Text>
                </View>
                <View style={styles.block}>
                  {lot.price && (
                    <Text style={[styles.text, { fontWeight: 600 }]}>
                      {/* Hammer price */}
                      {i18next.t('artprice.lib.psresultctrl.m6')}: {parsePrice(lot.price)}
                      {lot.lotstatus === 1 && lot.iso3Currency !== currency && ` (${lot.priceOriginal})`}
                    </Text>
                  )}
                  {lot.priceWithTaxes && lot.lotstatus !== 7 && (
                    <Text style={styles.text}>
                      {i18next.t('lots.small.m1')}: {parsePrice(lot.priceWithTaxes)}
                      {lot.lotstatus === 1 && lot.iso3Currency !== currency && ` (${lot.priceWithTaxesOriginal})`}
                    </Text>
                  )}
                  {lot.isPast && lot.startingPrice && (
                    <Text style={styles.text}>
                      {i18next.t('lots.small.m2')}: {parsePrice(lot.startingPrice)}
                      {lot.iso3Currency !== currency && ` (${lot.startingPriceOriginal})`}
                    </Text>
                  )}
                  {lot.estimation && (
                    <Text style={styles.text}>
                      {i18next.t('lots.show.m12')}: {parsePrice(lot.estimation.low)}
                      {lot.estimation.high && lot.estimation.low && ' - '}
                      {parsePrice(lot.estimation.high)}
                      {lot.iso3Currency !== currency && ` (${lot.estimation.lowOriginal}${lot.estimation.highOriginal && lot.estimation.lowOriginal && ' - '}${lot.estimation.highOriginal})`}
                    </Text>
                  )}
                  {!lot.isPast && lot.startingPrice && (
                    <Text style={styles.text}>
                      {i18next.t('lots.small.m2')}: {parsePrice(lot.startingPrice)}
                      {lot.iso3Currency !== currency && ` (${lot.startingPrice.original})`}
                    </Text>
                  )}
                </View>
                <View style={styles.block}>
                  {Boolean(lot.saleTitle) && <Text style={[styles.text, { fontWeight: 600 }]}>{lot.saleTitle}</Text>}

                  {lot.saledatestatus !== 3 && lot.saledatestatus !== 2 && (
                    <Text style={styles.text}>
                      {lot.saleDtStart} {lot.saleDtStart !== lot.saleDtEnd && `- ${lot.saleDtEnd}`}
                    </Text>
                  )}

                  {lot.saledatestatus === 2 && (
                    <Text style={styles.text}>
                      {formatFns(new Date(lot.saleDtStartUnlocalized), 'MMMM yyyy')} {lot.saleDtStart !== lot.saleDtEnd && `- ${formatFns(new Date(lot.saleDtEndUnlocalized), 'MMMM yyyy')}`}
                    </Text>
                  )}

                  {lot.saleType === 3 && (
                    <Text style={styles.text}>
                      <i className="fa fa-info-circle" /> {i18next.t('sales.tile.sale.m2')}
                    </Text>
                  )}

                  {lot.saleType === 2 && (
                    <Text style={styles.text}>
                      <i className="fa fa-info-circle" /> {i18next.t('sales.tile.sale.m3')}
                    </Text>
                  )}

                  {lot.saledatestatus === 1 && (
                    <Text style={styles.text}>
                      <i className="sale-warning">
                        <i className="fa fa-info-circle" /> {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: i18next.t('sale_helper.sale_date_status.m1').replace(' - ', '<br />') }} />
                      </i>
                    </Text>
                  )}

                  {lot.saledatestatus === 3 && (
                    <Text style={styles.text}>
                      <i className="sale-warning">
                        <i className="fa fa-info-circle" /> {/* eslint-disable-next-line react/no-danger */}
                        <Text dangerouslySetInnerHTML={{ __html: i18next.t('sale_helper.sale_date_status.m2').replace(' - ', '<br />') }} />
                      </i>
                    </Text>
                  )}

                  {lot.saledatestatus === 2 && (
                    <Text style={styles.text}>
                      <i className="dispsale-warning">
                        <i className="fa fa-info-circle" /> {/* eslint-disable-next-line react/no-danger */}
                        <Text dangerouslySetInnerHTML={{ __html: i18next.t('sales.tile.sale.m4').replace(' - ', '<br />') }} />
                      </i>
                    </Text>
                  )}

                  <Text style={[styles.text, { fontWeight: 600 }]}>{lot.auctioneerName}</Text>

                  {(lot.auctionplaceName || lot.address) && lot.saleType !== 2 && (
                    <Text style={styles.text}>
                      {lot.auctionplaceName && <Text>{lot.auctionplaceName}</Text>}
                      {/* eslint-disable-next-line react/no-danger */}
                      {lot.address && <Text dangerouslySetInnerHTML={{ __html: lot.address }} />}
                    </Text>
                  )}

                  {lot.saleType === 2 && <Text style={styles.text}>{lot.saleCountry}</Text>}
                </View>
              </View>
              <View style={{ width: '50%', alignItems: 'center' }}>
                <View style={{ width: 250, alignItems: 'center' }}>
                  <Reproduction lot={lot} imageMaxDimension={250} />
                </View>
              </View>
            </View>
            {(hasDetails || hasNFTDetails) && (
              <View>
                <Text style={[styles.text, { fontWeight: 600 }]}>{i18next.t('lot.show.access.m1')}</Text>

                <Text style={styles.text}>
                  {lot.distinctivesSigns}
                  {lot.signaturePosition && lot.distinctivesSigns && ' / '}
                  {lot.signaturePosition}
                </Text>

                {lot.lotartistassociationcomplement && (
                  <Text style={styles.text}>
                    <i>{i18next.t('lot.show.access.m4')}</i>: {lot.lotartistassociationcomplement}
                  </Text>
                )}

                {lot.descriptionAndLitterature && (
                  <Text style={styles.text}>
                    {i18next.t('artprice.lib.psresultctrl.m23')} {lot.descriptionAndLitterature}
                  </Text>
                )}
                {Boolean(lot.provenance) && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m9')} {lot.provenance}
                  </Text>
                )}
                {Boolean(lot.exposition) && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m10')} {lot.exposition}
                  </Text>
                )}
                {Boolean(lot.condition) && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m11')} {lot.condition}
                  </Text>
                )}
                {Boolean(lot.quantity) && lot.quantity > 1 && (
                  <Text style={styles.text}>
                    {i18next.t('artprice.client.payment.billdetail.m12')} {lot.quantity}
                  </Text>
                )}
                {lot.reproductionPage && <Text>{i18next.t('lot.show.access.m2').replace('%page%', lot.reproductionPage)}</Text>}

                {/* NFT */}
                {lot.contractid && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m21')} {lot.contractid}
                  </Text>
                )}
                {lot.tokenid && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m22')} {lot.tokenid}
                  </Text>
                )}
                {lot.walletid && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m23')} {lot.walletid}
                  </Text>
                )}
                {lot.minted_on && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m24')} {lot.minted_on}
                  </Text>
                )}
                {lot.media_type && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m25')} {lot.media_type}
                  </Text>
                )}
                {lot.url_plateform && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m26')} {lot.url_plateform.toLowerCase()}
                  </Text>
                )}
                {lot.transactionid && (
                  <Text style={styles.text}>
                    {i18next.t('lots.show.m27')} {lot.transactionid}
                  </Text>
                )}
              </View>
            )}
            <View wrap={false} style={styles.reproductionContainer}>
              <View style={styles.reproduction}>
                <Reproduction lot={lot} imageMaxDimension={imageMaxDimension} />
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

export default Show;
