import { boolean, object } from 'yup';
import { get, isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSearchParams } from 'react-router-dom';
import { getBillAddress, getCart, getParams, getShipAddress } from '../../../../redux/slices/payments';
import A18n from '../../../common/A18n';

const ValidateButton = () => {
  const navigate = useNavigate();
  const { image, promocode, type, quantity, idphysicproducts } = useSelector(getParams);
  const [errors, setErrors] = useState([]);
  const { needs_ship_address: needsShipAddress, main_products: mainProducts } = useSelector(getCart);
  const billAddress = useSelector(getBillAddress);
  const shipAddress = useSelector(getShipAddress);
  const { register, handleSubmit } = useForm({
    defaultValues: { checkCgv: false, checkMonthly: false },
    resolver: yupResolver(object().shape({ checkCgv: boolean(), checkMonthly: boolean() })),
  });

  const isRecurrentSubscription = get(mainProducts, '[0].product.isRecurrentSubscription');

  const onSubmit = values => {
    const errorsOnSubmit = [];

    if (needsShipAddress) {
      // ship address needed
      if (!shipAddress?.id) errorsOnSubmit.push('subscription.cart.m38');

      if (shipAddress?.id) {
        // phone on ship address needed
        if (!shipAddress?.phone) errorsOnSubmit.push('subscription.cart.m40');

        // Ship address incomplete
        if (isNil(shipAddress.idcountry) || isEmpty(shipAddress.line1) || isEmpty(shipAddress.postcode) || isEmpty(shipAddress.city)) errorsOnSubmit.push('subscription.cart.m38');
      }
    }

    // bill address needed
    if (!billAddress?.id) errorsOnSubmit.push('subscription.cart.m7');

    if (billAddress?.id) {
      // phone on bill address needed
      if (!billAddress?.phone) errorsOnSubmit.push('subscription.cart.m39');

      // Ship address incomplete
      if (isNil(billAddress.idcountry) || isEmpty(billAddress.line1) || isEmpty(billAddress.postcode) || isEmpty(billAddress.city)) errorsOnSubmit.push('subscription.cart.m37');
    }

    // cgv need to be accepted
    if (!values.checkCgv) errorsOnSubmit.push('subscription.cart.m6');

    // custom cgv need to be accepted on monthly subscriptions
    if (isRecurrentSubscription && !values.checkMonthly) errorsOnSubmit.push('subscription.cart.m50');

    setErrors(errorsOnSubmit);

    if (isEmpty(errorsOnSubmit)) {
      // Params as passed as query search to handle the browser refresh on platform page
      navigate({
        pathname: `/payments/platforms`,
        search: `?${createSearchParams({ image, idphysicproduct: Object.keys(idphysicproducts), quantity, type, promocode })}`,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isRecurrentSubscription && (
          <div>
            <input type="checkbox" {...register('checkMonthly')} />
            Je valide les cgv mensuelles
          </div>
        )}
        <div>
          <input type="checkbox" {...register('checkCgv')} />
          Je valide les cgv
        </div>

        <button type="submit">OK</button>
      </form>

      <div className="errors">
        {errors.map(errorRsx => (
          <p key={errorRsx}>
            <A18n rsx={errorRsx} />
          </p>
        ))}
      </div>
    </div>
  );
};

export default ValidateButton;
