import React from 'react';
import { Svg, G, Path } from '@react-pdf/renderer';

const LogoGroupeServeur = ({ height, width }) => (
  <Svg viewBox="0 0 462.6 64.8" height={height} width={width}>
    <G>
      <G>
        <Path d="M56.2,14.8c0,0-0.1-0.1-0.2-0.2C56.1,14.7,56.2,14.8,56.2,14.8z" fill="#000000" />
        <Path
          fill="#000000"
          d="M84.2,40.8c0-0.4-0.3-1-0.8-1.4c-0.5-0.4-0.7-1-0.7-1s-0.4-0.7-0.7-1.1c-0.3-0.4-1.4-1-1.4-1s-0.1-0.5-0.8-1
			c-0.7-0.4-1-0.3-1-0.3s-0.4-0.8-0.7-1.5c-0.3-0.7-1.1,0.1-1.1,0.1s-0.8-0.8-1.2-1.5c-0.4-0.7-1.5-0.1-1.5-0.1s-0.7-0.8-1.2-1.4
			S71.7,31,71.7,31s-0.8-0.7-1.4-1.4C69.8,29,69,30.3,69,29.9c0-0.4,0-0.7-0.5-1.2c-0.5-0.5-1.5,0-1.5-0.5c0-0.5-0.4-0.7-1-1
			c-0.5-0.3-1-0.3-1-0.3s-3-4-4.1-4.7c-1.1-0.7-0.4-0.4-2.3,0c-1.9,0.4-5.8,3.8-5.8,3.8c-0.8-0.3-0.5-0.4-1.1-0.1
			c-0.5,0.3-1,0.3-1,0.3s-1.1-0.5-1.8-0.1c-0.7,0.4-1,0.5-1.9,0.1c-1-0.4-1.1-0.3-1.5,0.3c-0.4,0.5-1.1,0.4-1.5,0.4s-1.4-0.3-1.8,0
			c-0.4,0.3-0.7,0.5-0.7,0.5h-0.7l-0.7,0.7h-1.1L38.4,29c0,0-0.4-0.3-0.8-0.1S37,29.7,37,29.7s0,0-1.1-1.9c-1.1-1.9-3.8-1.4-5.1-0.8
			c-1.2,0.5-1.4,1.9-2.9,1.6c-1.5-0.3-0.4-4.4,0.8-5.9c1.2-1.5,4.5-1.5,4.5-1.5s1,0.5,2.1,0.7c1.1,0.1,2.2-1,3.8-2.1
			c1.6-1.1,4-1.8,4.9-2.3c1-0.5,2.1,0,2.1,0c1,1.5-0.4,4-0.4,4c1.9-0.3,1.8-2.9,1.8-2.9c5.6,1.4,5.6-2.2,5.6-2.2
			c-1.1,1.8-3.7,1.2-3.7,1.2c1,0.1,1.5-1.1,1.5-1.1c-1.6,0.5-1.8-0.7-1.8-0.7l1.4-0.5l1-2.5c0,0,0.3-0.3,1.2,0.3
			c0.3,0.2,0.5,0.4,0.7,0.7c0-0.2-0.1-0.4,0-0.7c0.1-0.8,0.7-0.8,1.2,0c0.4,0.6,1.1,1.3,1.4,1.6c-0.5-0.6,0.4-1.2,0.4-1.2
			c3.7,2.9,4.1,1.1,4.1,1.1c-1.5,0-2.1-0.7-2.2-1.1c-0.1-0.4,1,0,1,0c3,1.8,4.1,0.5,4.1,0.5c-1.2,0.1-2.2-1.4-2.2-1.4
			s0.4-0.8,1.8,0.4s2.9,0.8,2.9,0.8c-2.1-0.3-1.2-1.8-1.2-1.8s0.4-0.8,2.2,0.3c1.8,1.1,3.3,0.4,3.3,0.4c-0.8,0.1-1.6-0.8-1.2-1
			c0.4-0.1,0.1-1.2,1.5-0.5c1,0.5,2.4,0.2,3.2-0.1c-0.6,0-1.2-0.4-1.6-0.7c0.2-0.1,0.5-0.3,0.9-0.8c1.2-1.4,3.6-0.3,3.6-0.3
			c0.8-2.8-4.7-0.9-5.7-0.6C71.9,8.5,72.2,7,72.2,7c-3.6,3-7,2.2-7,2.2c1.8-0.5,0.7-2.7,0.7-2.7c-0.1,1.1-3.4,3.2-4.7,3.6
			c-1.2,0.4-2.1,0-2.1-0.7c0-0.7,2.7-1.8,2.7-1.8s-0.3-0.5-1-0.8C60.2,6.5,59.1,8,59.1,8s-0.4,2.5-2.6,1.8c0,0-0.1-0.1-0.5-0.4
			c-0.4-0.3,1.1-0.8,1.1-0.8c-1.2-1-2.2,0-2.2,0c-2.1,2.3-2.6,1.6-2.6,1.6c0.1-0.4,0.4-1.1,0-1.5c-0.4-0.4-0.8-0.3-1.9,0.1
			c-1.1,0.4-2.6,0.5-3.7,0.4c-1.1-0.1-2.3-0.4-3.6-0.3c-1.2,0.1-4.3-0.3-5.6-0.3s-0.8,0.3-2.1,1c-1.2,0.7-5.6,2.5-6.2,3.3
			c-0.5,0.8-0.8,1.2-0.8,1.2c-8.8,4-10.6,13-10.6,13c-1.5-0.4-2.9,0-2.9,0c-0.1-0.8-1.2-1.6-2.5-1.5c-1.2,0.1-2.9,2.5-2.9,2.5
			c0.5-0.5,1.6,0.3,1.6,0.3c-0.5,1-2.5,2.1-2.5,2.1L10,31c-0.7,0.4-1,2.3-1,2.3c0.4-0.7,1.9-0.5,1.9-0.5c-1,1.5-0.4,2.5-0.4,2.5
			c1.9-2.2,8.1,0.7,8.1,0.7c0.5,3.4,6.2,7.1,6.2,7.1c0.5,3.2,4.3,3.8,4.9,4c0.7,0.1,1,1,0.8,1.6c-0.1,0.7-1.1,1.8-2.1,2.6
			c-1,0.8-0.8,0.3-1.8,0c-1-0.3-1.6,0.5-2.6,1.5c-1,1-0.3,2.6-0.3,2.6c0.1-1,1.8-1.2,1.8-1.2c-1,1.1,0.4,2.3,0.4,2.3
			c0.3-0.8,0.7-0.7,1.1-0.4s0.3,1.9,0.7,1c0.4-1,1.6-1.1,1.5-0.5c-0.1,0.5,1.1,1.2,1.1,1.2s0,0,0-1.2s1.8-0.3,2.9-0.7
			c1.1-0.4,1.2,1.6,1.2,1.6c0.5,0,1.2-0.5,1.5-1.8c0.3-1.2-2.3-3.4-1.6-3.7c0.7-0.3,1.5-1,2.3-2.9c0.8-1.9,1.9-1.8,1.9-1.8
			c-0.3-0.8,0.8-2.1,0.8-2.1c1.1-0.8,3,0.1,3,0.1C42.8,46.1,44,47,44,47c-0.7-1.4,0.7-2.5,0.7-1.8c0,0.7,0.8,1,1,0.1
			c0.1-0.8,0.5-1.1,0.8-0.7s1.4,0.8,1.4,0.8l-0.4-2.7c-1.4,0.3-2.1,1.4-2.1,1.4c-0.1-0.7-0.4-0.7-1.5,0.3c-0.9,0.8-2.6-0.2-3.3-0.6
			c0.2,0.1,0.6,0.1,1.2,0c1.4-0.3,0.3-1,0.3-1l-1.6-1.8c1.2-0.4,2.3,0.5,2.3,0.5s0.1-0.4,0-1.1c-0.1-0.7-0.8,0-0.8-0.7
			c0-0.7-0.3-0.8-0.7-1.8c-0.4-1,0.8-2.1,0.8-2.1s-0.1,1.1,0,1.9c0.1,0.8,1.1,0.8,1.4,1.6c0.3,0.8-0.3,2.7-0.3,2.7
			C44.8,41.1,45,39,45,39c0.7,1.4,0.3,3,0.3,3c0.7-0.3,1-1.2,1-1.2l0.1,1.8c0,0,0,0.1,1.5-1.5c1.5-1.6,7.7-2.6,7.7-2.6l-0.3,0.7
			c-4.7,0.1-5.8,3.2-6.3,4.1c-0.5,1-0.3,3.6-0.5,5.1c-0.3,1.5-1.5,1.6-2.2,1.2c-0.7-0.4-1.5-1.4-2.6-1.8c-1.1-0.4-2.6,0-3.4,0.5
			c-0.8,0.5-2.3,2.7-1.5,4.5c0.8,1.8,3.2,0.1,3.2,0.1s-0.8-0.1-1.1-0.8c-0.3-0.7,0.8-0.3,2.2,0.3c1.4,0.5,3.6,2.5,5.5,2.3
			c1.9-0.1,2.7-1.9,3.7-3.6s0.8-3.8,1.4-4.5c0.5-0.7,1.4-0.8,2.6-0.7c1.2,0.1,2.5,1.4,2.7,1.8c0.3,0.4,0.4,1.6-0.4,1.4
			c-0.8-0.3-0.7,0.1-2.9,1.2c-2.2,1.1-1,4.4-1.1,3.8c-0.1-0.5,1.4-1.1,1.4-1.1s1.1,2.5,1,1.9c-0.1-0.5,1.1-1.8,0.7-1.2
			c-0.4,0.5,1.2,2.5,1.1,1.8c-0.1-0.7,1-1.6,0.5-1.2s1,2.1,0.8,1.6c-0.1-0.4,0.7-1,1.1-1c0.4,0,1.6,0.3,2.6-0.7c1-1,1.6-0.3,1.6-0.3
			c5.2,3.2,9.5,2.6,11.4,2.3c1.9-0.3,5.8-2.1,8-6.6C86.8,45.3,84.2,41.2,84.2,40.8z M74.5,50.3c-4.7,1.9-6.6-3-6.6-3
			c2.2-2.9,5.9-2.7,5.9-2.7C78.5,48.5,74.5,50.3,74.5,50.3z"
        />
        <Path fill="#000000" d="M74.2,11c0,0-0.2,0.1-0.5,0.2C73.9,11.2,74.1,11.1,74.2,11z" />
        <Path fill="#000000" d="M53.5,13.7c0.1,0.6,0.4,1,0.4,1S53.8,14.2,53.5,13.7z" />
      </G>
      <G>
        <Path
          fill="#000000"
          d="M121.2,25.7h-3.6c0.9,1.5,1.3,2.7,1.3,4.2c0,2.3-0.9,4.2-2.3,5.5c-4.5,4.5-11.9,1.9-11.9,4.8c0,2.9,9.7,0.4,13.8,4.6
			c1.6,1.6,2,3.6,2,4.9c0,5.9-5.2,8.5-13.4,8.5c-10.7,0-13.5-4-13.5-7.4c0-2.1,1.2-4.9,6.8-6.3v-0.1c-1.7-0.5-3.1-1.5-3.1-3.3
			c0-2.7,3.1-3.4,4.6-3.7v-0.1c-3.2-0.5-7.1-3.1-7.1-7.8c0-3.1,2.2-9.2,11.3-9.2h15V25.7z M106.8,34.3c2.5,0,4.6-2.2,4.6-4.8
			c0-2.3-1.8-4.3-4.6-4.3c-2.6,0-4.6,2-4.6,4.6C102.2,32.2,104.3,34.3,106.8,34.3z M107,47.6c-3.7,0-6,1.2-6,3c0,1.9,2.3,3,6.5,3
			c3.5,0,5.7-1.5,5.7-3.2C113.2,48.6,111,47.6,107,47.6z"
        />
        <Path
          fill="#000000"
          d="M131.1,27.7h0.1c2.4-5.3,5-7.7,8.1-7.7c1.6,0,3,0.6,5.3,2.3l-2.2,7c-2.1-1.3-3.5-1.9-4.7-1.9c-2.9,0-4.8,2.7-6.5,7.4v10.9
			h-8V20.4h8V27.7z"
        />
        <Path
          fill="#000000"
          d="M157,46.2c-8.3,0-14.3-5.2-14.3-13.2c0-8.6,6.9-13.1,14.3-13.1c6.2,0,14.2,3.5,14.2,13.2C171.3,43.1,162.6,46.2,157,46.2z
			 M157,24.6c-3.5,0-5.8,2.7-5.8,8.4c0,6.5,2.8,8.5,5.8,8.5c3.7,0,5.7-3.1,5.7-8.6C162.8,27.9,161.1,24.6,157,24.6z"
        />
        <Path
          fill="#000000"
          d="M191.9,42.1c-2.5,2.9-5,4.1-8.2,4.1c-5,0-9.3-3.1-9.3-9.6V20.4h8v15.7c0,3.6,1.9,4.8,4,4.8c2.4,0,4.6-1.9,5.4-4.3V20.4h8
			v25.3h-8V42.1z"
        />
        <Path
          fill="#000000"
          d="M212,22.6c2.3-1.9,4.5-2.7,7.2-2.7c6.6,0,11.8,5.6,11.8,12.9c0,8.1-4.9,13.5-12.5,13.5c-2.3,0-4.7-0.7-6.5-1.8v13.9h-8
			v-38h8V22.6z M212,39.2c1.6,1.2,3,1.6,4.6,1.6c3.9,0,6.3-3,6.3-7.7c0-4.8-2.2-7.6-6-7.6c-1.7,0-3,0.5-4.9,2V39.2z"
        />
        <Path
          fill="#000000"
          d="M258.7,42.9c-4.6,3.4-9.3,3.4-11.3,3.4c-8.9,0-14.7-5.2-14.7-13.1c0-7.7,5.5-13.2,13.3-13.2c7.4,0,13.5,5.2,13.1,14.4H241
			c0.3,4.5,3.4,7.1,8.3,7.1c3.2,0,5.7-0.9,9.4-3.6V42.9z M252.1,30.2c-0.3-3.5-2.4-5.5-5.5-5.5c-3.1,0-5.2,2-5.5,5.5H252.1z"
        />
        <Path
          fill="#000000"
          d="M293.6,27.4c-3.5-2.1-6.9-2.8-8.5-2.8c-1,0-2.9,0.2-2.9,2c0,2.8,12.6,2.9,12.6,11.4c0,5.3-3.7,8.2-10.3,8.2
			c-4.1,0-8-1.3-9.1-1.9v-5.6c2.6,1.1,5.2,2.1,8.2,2.1c2.4,0,3.8-0.7,3.8-1.9c0-3.7-12.6-3-12.6-11.3c0-4.7,4.2-7.8,10.5-7.8
			c3.2,0,5.5,0.5,8.2,1.9V27.4z"
        />
        <Path
          fill="#000000"
          d="M322.2,42.9c-4.6,3.4-9.3,3.4-11.3,3.4c-8.9,0-14.7-5.2-14.7-13.1c0-7.7,5.5-13.2,13.3-13.2c7.4,0,13.5,5.2,13.1,14.4
			h-18.2c0.3,4.5,3.4,7.1,8.3,7.1c3.2,0,5.7-0.9,9.4-3.6V42.9z M315.6,30.2c-0.3-3.5-2.4-5.5-5.5-5.5c-3.1,0-5.2,2-5.5,5.5H315.6z"
        />
        <Path
          fill="#000000"
          d="M333.7,27.7h0.1c2.4-5.3,5-7.7,8.1-7.7c1.6,0,3,0.6,5.3,2.3l-2.2,7c-2.1-1.3-3.5-1.9-4.7-1.9c-2.9,0-4.8,2.7-6.5,7.4v10.9
			h-8V20.4h8V27.7z"
        />
        <Path fill="#000000" d="M346.4,20.4h8.5l5.4,14l5.4-14h8.5l-11.6,25.8h-4.4L346.4,20.4z" />
        <Path
          fill="#000000"
          d="M399,42.9c-4.6,3.4-9.3,3.4-11.3,3.4c-8.9,0-14.7-5.2-14.7-13.1c0-7.7,5.5-13.2,13.3-13.2c7.4,0,13.5,5.2,13.1,14.4h-18.2
			c0.3,4.5,3.4,7.1,8.3,7.1c3.2,0,5.7-0.9,9.4-3.6V42.9z M392.4,30.2c-0.3-3.5-2.4-5.5-5.5-5.5c-3.1,0-5.2,2-5.5,5.5H392.4z"
        />
        <Path
          fill="#000000"
          d="M419.9,42.1c-2.5,2.9-5,4.1-8.2,4.1c-5,0-9.3-3.1-9.3-9.6V20.4h8v15.7c0,3.6,1.9,4.8,4,4.8c2.4,0,4.6-1.9,5.4-4.3V20.4h8
			v25.3h-8V42.1z"
        />
        <Path
          fill="#000000"
          d="M440.4,27.7h0.1c2.4-5.3,5-7.7,8.1-7.7c1.6,0,3,0.6,5.3,2.3l-2.2,7c-2.1-1.3-3.5-1.9-4.7-1.9c-2.9,0-4.8,2.7-6.5,7.4v10.9
			h-8V20.4h8V27.7z"
        />
      </G>
    </G>
  </Svg>
);

export default LogoGroupeServeur;
