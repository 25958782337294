import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { captureException } from '@sentry/react';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';
import useCheckoutGtm from '../../../hooks/useCheckoutGtm';

const StripeCheckoutForm = ({ idpayment, displayedPaymentMethod = [] }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { pushCheckout } = useCheckoutGtm();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) return;

    pushCheckout('stripe');

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/subscription/payment_ok${window.location.search}&idpayment=${idpayment}`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(result.error.message);
      captureException(result.error);
    }
  };

  return (
    <div className="payments-platforms-stripe-checkout-form">
      <form onSubmit={handleSubmit}>
        <PaymentElement />

        <div
          style={{
            textAlign: 'center',
            marginTop: 30,
          }}
        >
          <button type="submit" disabled={!stripe} className="btn btn-default" id="card-button">
            <A18n rsx="payments.platforms.stripe.checkoutform.m2" />
          </button>
        </div>
      </form>

      {displayedPaymentMethod.some(paymentMethod => paymentMethod === 'klarna') && (
        <div className="klarna-container">
          <div className="klarna" />
          <A18n rsx="payments.platforms.stripe.checkoutform.m1" />
        </div>
      )}
    </div>
  );
};

export default StripeCheckoutForm;
