import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ANALYTICS_CLICK_OPERATION_TO_EVENT, ANALYTICS_CLICK_OPERATION_TO_EVENTS_HOME, ANALYTICS_FROM_HOMEPAGE } from '../../../constants/rails';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { getEvents } from '../../../redux/slices/homepage';
import Arrow from '../../common/Arrow';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Events = () => {
  const events = useSelector(getEvents);

  if (isEmpty(events)) return null;

  return (
    <div className="homepage-events block">
      <div className="container">
        <h2 className="title">
          <A18n rsx="homepage.events.m1" />
          <div className="all">
            <a href="/events" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_EVENTS_HOME })}>
              <A18n rsx="homepage.events.m2" />
              <Arrow style={{ marginLeft: 5 }} />
            </a>
          </div>
        </h2>

        <div className={classNames({ events: true, 'two-cols': events.length === 2 })}>
          {events.map(event => (
            <div className="event" key={event.title}>
              <div className="abs-div bg" style={{ backgroundImage: `url("${event.image}")` }} />
              <div className="abs-div filter" />
              <div className="abs-div event-data">
                <h3>{event.title}</h3>
                {/* eslint-disable-next-line react/no-danger */}
                <div className="desc" dangerouslySetInnerHTML={{ __html: event.description }} />
                <div>
                  <a
                    href={`/events/${event.id}/${event.title.replace(/[/#.\s]/gi, '-')}/`}
                    className="btn btn-default btn-animated"
                    onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_EVENT, slug: event.title.replace(/[/#.\s]/gi, '-') })}
                  >
                    <A18n rsx="homepage.events.m3" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Events;
