import React from 'react';
import { useIntl } from 'react-intl';
import A18n from '../../../common/A18n';
import './stylesheet.scss';
import useCheckoutGtm from '../../hooks/useCheckoutGtm';

const Offline = ({ idphysicsale, mode }) => {
  const intl = useIntl();
  const { pushCheckout } = useCheckoutGtm();

  return (
    <div className="payments-platforms-offline">
      {mode === 'cb' && (
        <>
          <p>
            <A18n rsx="payments.platforms.offline.m1" />
          </p>
          <p>
            <A18n rsx="payments.platforms.offline.m4" />
          </p>

          <div className="phones">
            {intl.locale === 'fr' && (
              <p>
                <A18n rsx="payments.platforms.offline.m5" />
              </p>
            )}
            <p>
              <A18n rsx="payments.platforms.offline.m6" />
            </p>
            <p>
              <A18n rsx="payments.platforms.offline.m7" />
            </p>
            <p>
              <A18n rsx="payments.platforms.offline.m8" />
            </p>
            <p className="countries">
              <A18n rsx="payments.platforms.offline.m9" />
            </p>
            <p>
              <A18n rsx="payments.platforms.offline.m10" />
            </p>
          </div>
        </>
      )}
      {mode === 'transfer' && (
        <p>
          <A18n rsx="payments.platforms.offline.m2" />
        </p>
      )}
      <div
        style={{
          textAlign: 'center',
          marginTop: 30,
        }}
      >
        <button
          type="button"
          className="btn btn-default"
          onClick={() => {
            pushCheckout('offline');
            window.open(`/subscription/payment_offline/${idphysicsale}`, '_blank');
          }}
        >
          <A18n rsx="payments.platforms.offline.m3" />
        </button>
      </div>
    </div>
  );
};
export default Offline;
