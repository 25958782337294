import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    fontSize: 10,
    flexDirection: 'column',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
  footerLegals: {
    marginBottom: 5,
    padding: '2px 20px',
    fontSize: 8,
    fontStyle: 'italic',
    lineHeight: 1.6,
  },
});

export default styles;
