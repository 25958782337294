import React, { Component } from 'react';
import i18next from 'i18next';
import { ImageCropper, imgToBlob } from '@artprice/react-images';
import { object, func, number } from 'prop-types';

class Cropper extends Component {
  constructor(props) {
    super(props);

    this.state = { blob: null };
  }

  async componentDidMount() {
    const { imageRef } = this.props;

    if (imageRef.current) {
      const blob = await imgToBlob(imageRef.current, 'image/jpeg', 1);
      this.setState({ blob });
    }
  }

  render() {
    const { onSave, onCancel, cropAspect } = this.props;
    const { blob } = this.state;

    const cropperRsx = {
      title: i18next.t('marketplace.stores.edit.form.images.image.cropper.m1'),
      btnSave: `${i18next.t('marketplace.stores.edit.form.images.image.cropper.m2')} <i class="fa fa-save" />`,
      btnDiscard: `${i18next.t('marketplace.stores.edit.form.images.image.cropper.m3')} <i class="fa fa-ban" />`,
      btnRotateRight: `${i18next.t('marketplace.stores.edit.form.images.image.cropper.m4')} <i class="fa fa-rotate-right" />`,
      btnCrop: `${i18next.t('marketplace.stores.edit.form.images.image.cropper.m7')} <i class="fa fa-crop" />`,
      btnRotateLeft: `${i18next.t('marketplace.stores.edit.form.images.image.cropper.m5')} <i class="fa  fa-rotate-left" />`,
      btnUndo: `${i18next.t('marketplace.stores.edit.form.images.image.cropper.m6')} <i class="fa fa-eraser" />`,
    };

    return (
      <div className="marketplace-store-edit-form-images-image-cropper">
        {blob && <ImageCropper className="image-cropper" file={blob} cropAspect={cropAspect} rsx={cropperRsx} onCancel={onCancel} onSave={onSave} />}
        {!blob && <i className="fa fa-spinner fa-spin fa-4x fa-fw" />}
      </div>
    );
  }
}

Cropper.defaultProps = {
  cropAspect: undefined,
};

Cropper.propTypes = {
  cropAspect: number,
  imageRef: object.isRequired,
  onSave: func.isRequired,
  onCancel: func.isRequired,
};

export default Cropper;
