import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import BackToTopButton from '../../../common/BackToTopButton';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../../utils/http';
import { getActiveSubscription, getIdcustomer } from '../../../../redux/slices/userContext/selectors';
import { MODULE_STORE } from '../../../../constants/rails';
import A18n from '../../../common/A18n';
import SearchBar from './SearchBar';
import Store from './Store';
import Pagination from '../../../common/Pagination';
import './stylesheet.scss';

const List = ({ idartist, stores, paginate }) => {
  const refListHeader = useRef();
  const activeSubscription = useSelector(getActiveSubscription);
  const idcustomer = useSelector(getIdcustomer);

  const setPage = p => {
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...queryStringToObject(window.location.search), p })}`;
  };

  return (
    <div className="marketplace-stores-list">
      <div className="header">
        <div className="container ">
          <div className=" header-container">
            <h1>
              <A18n rsx="marketplace.stores.list.m1" />{' '}
            </h1>
            <p>
              <A18n rsx="marketplace.stores.list.m2" />
            </p>
            {!activeSubscription?.modules?.includes(MODULE_STORE) && (
              <a href={`/subscription/store${idcustomer ? '' : '/newcomer'}`}>
                <A18n rsx="marketplace.stores.list.m3" />
              </a>
            )}
          </div>
          <SearchBar idartist={idartist} />
        </div>
      </div>
      <div className="container" ref={refListHeader}>
        <div className="list-header">
          <h2>
            <A18n rsx="marketplace.stores.list.m4" />
          </h2>
          <p>
            <A18n rsx="marketplace.stores.list.m5" />
          </p>
        </div>
        <div className="store-list">
          {stores.map(store => (
            <Store key={store.id} store={store} />
          ))}
        </div>
        <Pagination className="pagination" perPage={paginate.perPage} totalCount={paginate.totalCount} activePage={paginate.page} onClick={p => setPage(p)} />

        <div className="back-to-top-container">
          <BackToTopButton top={refListHeader.current} />
        </div>
      </div>
    </div>
  );
};
export default List;
