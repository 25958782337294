import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import ReduxProvider from '../providers/ReduxProvider';
import { indicatorAction } from '../../redux/actions/indicator';
import './stylesheet.scss';
import { lotDataSelector } from '../../redux/selectors/indicator';
import { getCurrency } from '../../redux/selectors/preferences';
import Graph from './Graph';
import LinkButton from './LinkButton';
import Table from './Table';
import Spinner from '../common/spinners/Spinner';
import { CURRENCIES } from '../../constants/preferences';

class Indicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChartData: false,
    };
  }

  componentDidMount() {
    this.getLotData();
  }

  componentDidUpdate() {
    this.getLotData();
  }

  getLotData() {
    const { idlot, currency, lotdata, getIndicator } = this.props;
    if (!lotdata) {
      getIndicator({
        idlot,
        currency,
      });
    }
  }

  toggleChartDataVisibility = () =>
    this.setState(prevState => ({
      showChartData: !prevState.showChartData,
    }));

  loading() {
    const { lotdata } = this.props;
    return !lotdata || lotdata.loading;
  }

  render() {
    const { lotdata, noAccess, linkToSubscription, currency } = this.props;
    const { showChartData } = this.state;

    if (this.loading()) {
      return <Spinner />;
    }

    const data =
      lotdata &&
      lotdata.result &&
      Object.keys(lotdata.result).map(year => ({
        year,
        indicator: lotdata.result[year] && Math.round(lotdata.result[year] * 100) / 100,
      }));
    const isDataMeaningful = data && data.some(({ value }) => value !== null);

    if (!isDataMeaningful) {
      return null;
    }

    return (
      <div className="artdbweb-lot-Indicator row">
        <div className="col-sm-3 col-xs-12">
          <div className="title">
            <span className="strong">{i18next.t('lots.show.m19')}</span>
            <br />
            <i>{i18next.t('lots.show.m15')}</i>
          </div>
        </div>
        <div className="col-xs-12 col-sm-9">
          <div className="row">
            <div className="col-sm-12">
              {noAccess && !linkToSubscription && <div className="no-access absolute">{i18next.t('lots.indicator.m1')}</div>}
              {noAccess && linkToSubscription && (
                <div className="no-access absolute">
                  <p>{i18next.t('lots.indicator.m2')}</p>
                  <p>
                    <a href="/subscription" id="sln_subscription" className="btn btn-primary">
                      {i18next.t('lots.indicator.m3')}
                    </a>
                  </p>
                </div>
              )}
              <Graph currency={CURRENCIES[currency].iso1} data={data} />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-offset-3 col-sm-9 text-right">
          <span className="hidden-xs pull-left">
            <LinkButton onClick={this.toggleChartDataVisibility}>
              {showChartData ? i18next.t('indexes.indexes_chart.m6') : i18next.t('indexes.indexes_chart.m5')}
              <i className={`caret ${showChartData ? 'inverse' : ''}`} />
            </LinkButton>
          </span>
          <a href="/indicator/howto">{i18next.t('artprice.lib.result.indicatorresult.m7')}</a>
          {' - '}
          <a href="/indexes/legals">{i18next.t('artprice.index.legals.m1')}</a>
        </div>
        {showChartData && (
          <div className="col-xs-12 col-sm-offset-3 col-sm-9">
            <Table currency={CURRENCIES[currency].iso1} data={data} />
          </div>
        )}
      </div>
    );
  }
}

Indicator.propTypes = {
  currency: PropTypes.string.isRequired,
  idlot: PropTypes.number.isRequired,
  noAccess: PropTypes.bool.isRequired,
  linkToSubscription: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  lotdata: PropTypes.object,
  getIndicator: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { idlot } = ownProps;
  const currency = getCurrency(state);
  return {
    currency,
    lotdata: lotDataSelector(state, {
      idlot,
      currency,
    }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIndicator: ({ idlot, currency }) =>
      dispatch(
        indicatorAction({
          idlot,
          currency,
        }),
      ),
  };
}

const ArtdbwebLotIndicator = connect(mapStateToProps, mapDispatchToProps)(Indicator);

const ProvidedArtdbwebLotIndicator = ({ idlot, linkToSubscription, noAccess }) => (
  <ReduxProvider>
    <ArtdbwebLotIndicator linkToSubscription={linkToSubscription} noAccess={noAccess} idlot={idlot} />
  </ReduxProvider>
);

ProvidedArtdbwebLotIndicator.propTypes = {
  idlot: PropTypes.number.isRequired,
  noAccess: PropTypes.bool.isRequired,
  linkToSubscription: PropTypes.bool.isRequired,
};

export default ProvidedArtdbwebLotIndicator;
