import React from 'react';
import { connect } from 'react-redux';
import { number, object } from 'prop-types';
import Image from './Image';
import { getStore as getStoreSelector } from '../../../../../redux/selectors/marketplace/stores';

import './stylesheet.scss';

const Images = ({ idstore, store }) => {
  if (store) {
    return (
      <div className="marketplace-store-edit-form-images">
        {store.images.map((image, idx) => (
          <div className="cell" key={`img${store.images.length + idx}`}>
            <Image className={idx === 0 ? 'primary' : null} idstore={idstore} idx={idx} imageUrl={image.image_large_url} imageId={image.id} />
          </div>
        ))}
      </div>
    );
  }
  return null;
};

Images.defaultProps = {
  store: undefined,
};

Images.propTypes = {
  idstore: number.isRequired,
  store: object,
};

function mapStateToProps(state, ownProps) {
  const { idstore } = ownProps;

  return {
    store: getStoreSelector(state, { idstore }),
  };
}

export default connect(mapStateToProps, null)(Images);
