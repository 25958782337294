import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { getArtist, getCategoriesPast, getLotsPast } from '../../../../../redux/slices/artist';
import { hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import {
  ANALYTICS_SECTION_INDEXES_CARD,
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_PAST_LOT,
  ANALYTICS_SECTION_ARTIST_PAST_LOTS,
  ID_FREE_ARTIST,
  IMAGE_SOURCE,
  MODULE_INDEXES,
  MODULE_PASTSALE,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_BY_CATEGORY,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOT,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_NOT_SIGNED,
  ANALYTICS_CLICK_OPERATION_TO_PRICING,
  ANALYTICS_CLICK_OPERATION_TO_PRICING_UPGRADE,
} from '../../../../../constants/rails';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import DOMView from '../../../../common/observers/DOMView';
import { UiContext } from '../../common/UiProvider';
import Reproduction from '../Reproduction';
import A18n from '../../../../common/A18n';
import Scroll from '../../common/Scroll';
import Categories from '../Categories';
import Lock from '../../common/Lock';

const Past = ({ onOpenDrawer, style = {} }) => {
  const lots = useSelector(getLotsPast);
  const intl = useIntl();
  const artist = useSelector(getArtist);
  const logged = useSelector(isLogged);
  const categories = useSelector(getCategoriesPast);
  const [urlSeeAll, setUrlSeeAll] = useState();
  const hasPastSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_PASTSALE }));
  const hasIndexesAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_INDEXES }));
  const [noIndexesAccess, setNoIndexesAccess] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const [lotCount, setLotCount] = useState(0);
  const [displayLock, setDisplayLock] = useState(false);
  const { lotsPastRef } = useContext(UiContext);

  useEffect(() => {
    if (categories) {
      setLotCount(categories.reduce((nb, c) => nb + c.count, 0));
    }
  }, [categories]);

  useEffect(() => {
    if (artist.id === ID_FREE_ARTIST) {
      setDisplayLock(false);
    } else {
      setDisplayLock(!logged || !hasPastSaleAccess);
    }
  }, [artist?.id, logged, hasPastSaleAccess]);

  useEffect(() => {
    setNoIndexesAccess(!hasIndexesAccess);
    setNoAccess(!hasPastSaleAccess);
  }, [hasPastSaleAccess, hasIndexesAccess]);

  useEffect(() => {
    if (lotCount === 0 && lots.notSignedCount > 0) {
      setUrlSeeAll(lots.notSignedUrl);
    } else {
      setUrlSeeAll(lots.url);
    }
  }, [lotCount, lots.notSignedCount]);

  const handleOpenDrawer = ({ section, op, e }) => {
    onOpenDrawer({ section, op, period: 'past', e });
  };

  return (
    <div className="step-past" style={{ position: 'relative', ...style }} ref={lotsPastRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.lots.past.m1" />
        </h2>
        <p className="sub-h2">
          {isEmpty(artist.groupMembers) && (
            <>
              {lots.notSignedCount === 0 && (
                <>
                  {lotCount > 1 && (
                    <A18n
                      rsx="artists.artist.lots.past.m2"
                      replace={[
                        ['%artist%', artist.name],
                        ['%nb%', intl.formatNumber(lotCount)],
                      ]}
                    />
                  )}
                  {lotCount === 1 && <A18n rsx="artists.artist.lots.past.m3" replace={[['%artist%', artist.name]]} />}
                </>
              )}
              {lots.notSignedCount > 0 && (
                <A18n
                  rsx={lots.notSignedCount + lotCount === 1 ? 'artists.artist.lots.past.m5' : 'artists.artist.lots.past.m4'}
                  replace={[
                    ['%nb%', intl.formatNumber(lotCount + lots.notSignedCount)],
                    ['%artist%', artist.name],
                    ['%a%', lotCount === 0 ? `<a class="styled" href="${lots.notSignedUrl}">` : ''],
                    ['%/a%', lotCount === 0 ? `</a>` : ''],
                  ]}
                  trustHtml
                />
              )}
            </>
          )}
          {!isEmpty(artist.groupMembers) && (
            <>
              {lotCount > 1 && (
                <A18n
                  rsx="artists.artist.lots.past.m13"
                  replace={[
                    ['%artist%', artist.name],
                    ['%nb%', intl.formatNumber(lotCount)],
                  ]}
                />
              )}
              {lotCount === 1 && <A18n rsx="artists.artist.lots.past.m14" replace={[['%artist%', artist.name]]} />}
            </>
          )}
        </p>

        {!isEmpty(categories) && (
          <Categories
            period="past"
            categories={categories}
            lots={lots}
            clickOnCategories={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_BY_CATEGORY, e })}
            clickOnNotSigned={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_NOT_SIGNED, e })}
          />
        )}

        {isEmpty(categories) && (
          <p className="sub-h2">
            <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.lots.past.m11' : 'artists.artist.lots.past.m15'} replace={[['%artist%', artist.name]]} />
          </p>
        )}
      </div>

      {!isEmpty(lots.items) && (
        <Scroll
          aapiBeaconSection={ANALYTICS_SECTION_ARTIST_PAST_LOTS}
          className="lots"
          renderComplement={() => {
            if (lotCount === 1) return null;

            return (
              <a
                id="sln_ps"
                href={urlSeeAll}
                className="btn btn-default link"
                onClick={e => handleOpenDrawer({ section: ANALYTICS_SECTION_ARTIST_PAST_LOTS, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST, e })}
                style={{ paddingRight: 40 }}
              >
                {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.lots.past.m6" />}
                {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.lots.past.m12" />}
                {displayLock && <Lock stroke="#000" style={{ position: 'absolute' }} />}
              </a>
            );
          }}
        >
          {lots.items.map(lot => (
            <a href={lot.url} onClick={e => handleOpenDrawer({ section: ANALYTICS_SECTION_ARTIST_PAST_LOT, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOT, e })} key={lot.url}>
              <div className="lot">
                <div className="tag">
                  <div className="dt">{lot.saleDtStart}</div>
                  {lot.saleCountry && <div className="country">{lot.saleCountry}</div>}
                </div>
                <Reproduction lot={lot} />
                <div>
                  <p className="title" style={{ fontWeight: 600 }}>
                    {lot.title}
                  </p>
                  <p>{lot.category}</p>
                  {displayLock && !lot.fakeImage && (
                    <div className="lock" style={{ marginTop: 15 }}>
                      <Lock stroke="#b4b2ab" style={{ marginRight: 5 }} />
                      <span>
                        <A18n rsx="artists.artist.lots.future.m13" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </a>
          ))}
          {artist.macro && !noAccess && noIndexesAccess && (
            <div className="lot fake">
              <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_INDEXES_CARD} selector="#indexes-card" idartist={artist.id} />
              <img id="indexes-card" src={`${IMAGE_SOURCE}artist/indexes.png`} alt="indexes" />
              <div className="fake-container">
                <p className="title">
                  <A18n rsx="artists.artist.lots.past.m7" trustHtml />
                </p>
                <p className="content">
                  {artist.index && <A18n rsx="artists.artist.lots.past.m8" trustHtml />}
                  {!artist.index && <A18n rsx="artists.artist.lots.past.m10" trustHtml />}
                </p>
                <a
                  className="btn btn-default link"
                  onClick={() =>
                    aapiBeacon('click', {
                      from: ANALYTICS_FROM_ARTIST,
                      op: noAccess ? ANALYTICS_CLICK_OPERATION_TO_PRICING : ANALYTICS_CLICK_OPERATION_TO_PRICING_UPGRADE,
                      section: ANALYTICS_SECTION_ARTIST_PAST_LOTS,
                      idartist: artist.id,
                    })
                  }
                  href={noIndexesAccess ? `/subscription${!noAccess ? '?upgrade=1' : ''}` : artist.urlIndexes}
                >
                  <A18n rsx="artists.artist.lots.past.m9" />
                </a>
              </div>
            </div>
          )}
          {noAccess && (
            <div className="lot fake">
              <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_INDEXES_CARD} selector="#indexes-card" idartist={artist.id} />
              <img id="indexes-card" src={`${IMAGE_SOURCE}artist/auctions.png`} alt="indexes" />
              <div className="fake-container">
                <p className="title">
                  <A18n rsx="artists.artist.lots.past.m16" trustHtml />
                </p>
                <p className="content">
                  {lotCount === 1 && <A18n rsx="artists.artist.lots.past.m20" trustHtml replace={[['%dt-year%', artist.firstLotYear]]} />}
                  {lotCount > 1 && artist.firstLotYear === artist.lastLotYear && <A18n rsx="artists.artist.lots.past.m19" trustHtml replace={[['%dt-year%', artist.firstLotYear]]} />}
                  {lotCount > 1 && artist.firstLotYear !== artist.lastLotYear && (
                    <A18n
                      rsx="artists.artist.lots.past.m17"
                      trustHtml
                      replace={[
                        ['%dt-old-year%', artist.firstLotYear],
                        ['%dt-recent-year%', artist.lastLotYear],
                      ]}
                    />
                  )}
                </p>
                <a
                  className="btn btn-default link"
                  href="/subscription"
                  onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_PRICING, section: ANALYTICS_SECTION_ARTIST_PAST_LOTS, idartist: artist.id })}
                >
                  <A18n rsx="artists.artist.lots.past.m18" trustHtml />
                </a>
              </div>
            </div>
          )}
        </Scroll>
      )}
    </div>
  );
};

export default Past;
