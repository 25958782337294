import React, { useState } from 'react';
import i18next from 'i18next';
import { isMobile } from '../../../../utils/ui';
import './stylesheets.scss';

const Links = ({ lotUrl, auctioneerUrl, saleUrl, lotNumber }) => {
  const [visible, setVisible] = useState(false);
  const mobile = isMobile();

  if (!auctioneerUrl && !saleUrl) {
    return (
      <div className="lots-tile-links">
        <a className="only-details" href={lotUrl}>
          {i18next.t('lots.tile.links.m2')}
          {lotNumber && ` (${i18next.t('classifieds.all.auction.m2').toLowerCase()} ${lotNumber})`}
        </a>
      </div>
    );
  }

  return (
    <div className="lots-tile-links">
      <button type="button" onClick={() => setVisible(true)} onMouseEnter={() => setVisible(true)}>
        {i18next.t('lots.tile.links.m2')}
        {lotNumber && ` (${i18next.t('classifieds.all.auction.m2').toLowerCase()} ${lotNumber})`}
        <i className="fa fa-caret-down" />
      </button>

      {visible && (
        <div onMouseLeave={() => setVisible(false)} className={`shadow details ${mobile && 'mobile'}`}>
          {mobile && (
            <button type="button" className="close-popup" onClick={() => setVisible(false)}>
              <i className="fa fa-close" />
            </button>
          )}
          {lotUrl && (
            <div>
              <a href={lotUrl}>{i18next.t('lots.tile.links.m1')}</a>
            </div>
          )}
          {saleUrl && (
            <div>
              <a href={saleUrl}>{i18next.t('lot.show.access.m3')}</a>
            </div>
          )}
          {auctioneerUrl && (
            <div>
              <a rel="noreferrer" target="_blank" href={auctioneerUrl}>
                {i18next.t('lots.show.m20')}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Links;
