import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';

import './stylesheet.scss';
import classNames from 'classnames';

/* Inspired by https://www.jayfreestone.com/writing/react-portals-with-hooks/ */

function createRootElement(id) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addRootElement(rootElem) {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
}

const ROOT_ELEMENT = 'modal-root';
const stopPropagation = e => e.stopPropagation();

const Modal = ({ children, className, title, onClose, visible = false, onTransitionEnd, footer }) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${ROOT_ELEMENT}`);
    const parentElem = existingParent || createRootElement(ROOT_ELEMENT);
    if (!existingParent) {
      addRootElement(parentElem);
    }
    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      // do not remove parent if it is already existing
      if (!existingParent && !parentElem.hasChildNodes()) {
        parentElem.remove();
      }
    };
  }, []);

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return createPortal(
    <div role="presentation" className={`modal-black-bg ${visible ? 'modal-visible' : 'modal-hidden'}`} onClick={onClose} onTransitionEnd={onTransitionEnd}>
      <div role="presentation" className={classNames({ 'artp-modal': true, 'with-footer': !isEmpty(footer), 'with-title': title, ...{ [className || '']: true } })} onClick={stopPropagation}>
        {title && (
          <div className="artp-modal-header">
            <h2 className="fixed-gridcol-2">{title}</h2>
          </div>
        )}
        <div className={classNames({ 'artp-modal-content': true, 'with-title': title, 'with-footer': !isEmpty(footer) })}>{children}</div>

        {!isEmpty(footer) && <div className="artp-modal-footer">{footer.map(item => item)}</div>}
      </div>
    </div>,
    getRootElem(),
  );
};

Modal.defaultProps = {
  title: '',
  children: <div>No children nor content given</div>,
  className: '',
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  footer: PropTypes.arrayOf(PropTypes.node),
};

// Useful for the animations
const ModalContainer = ({ children, className, title, open, onClose, footer }) => {
  const [shouldShow, setShouldShow] = useState(open);
  useEffect(() => {
    let timeoutId;
    if (open) {
      timeoutId = setTimeout(() => setShouldShow(open), 60); // arbitrary value, used to trigger the "appearing" animation
    }
    return () => clearTimeout(timeoutId);
  }, [open]);

  const onTransitionEnd = useCallback(() => {
    if (!open) {
      setShouldShow(false);
    }
  }, [open, setShouldShow]);

  return (
    (open || shouldShow) && (
      <Modal className={className} title={title} onClose={onClose} visible={open && shouldShow} onTransitionEnd={onTransitionEnd} footer={footer}>
        {isFunction(children) ? children({ close: onClose }) : children}
      </Modal>
    )
  );
};

ModalContainer.defaultProps = {
  ...Modal.defaultProps,
};

ModalContainer.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ModalContainer;
