import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const ShortcutLink = ({ text, onClick, to, className }) => (
  <Link onClick={onClick} to={to}>
    <div className={`shortcut ${className}`}>
      <i className="fa fa-circle-o" aria-hidden="true" />
      {text}
    </div>
  </Link>
);

ShortcutLink.defaultProps = {
  className: '',
  text: '',
  onClick: () => undefined,
  to: '',
};

ShortcutLink.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export const Shortcut = ({ text, className }) => (
  <div className={`shortcut ${className}`}>
    <b>
      <i className="fa fa-dot-circle-o" aria-hidden="true" />
      {text}
    </b>
  </div>
);

Shortcut.defaultProps = {
  className: '',
  text: '',
};

Shortcut.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};
