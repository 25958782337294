import React from 'react';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_CLICK_OPERATION_FOLLOW_ARTIST,
  ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS,
  ANALYTICS_CLICK_OPERATION_TO_PRICING,
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_FUTURE_LOTS,
  IMAGE_SOURCE,
} from '../../../../../../constants/rails';
import { getFavoriteCount, getIdcustomer, getPushEmail, isLogged } from '../../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import { getArtist } from '../../../../../../redux/slices/artist';
import A18n from '../../../../../common/A18n';
import Follow from '../../../../Follow';

const Fake = ({ direction = 'vertical' }) => {
  const logged = useSelector(isLogged);
  const artist = useSelector(getArtist);
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const favoriteCount = useSelector(getFavoriteCount);

  if (!idcustomer)
    return (
      <div className={`lot fake ${direction}`}>
        <div className="fake-bg" />
        {direction === 'vertical' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email.png`} alt="email-alert" />}
        {direction === 'horizontal' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email-no-fs.png`} alt="email-alert" />}
        <div className="fake-container">
          <p className="title">
            <A18n rsx="artists.artist.lots.future.m8" trustHtml />
          </p>
          <p className="content">
            <A18n rsx="artists.artist.lots.future.m9" trustHtml />
          </p>
          <a
            className="btn btn-default link"
            href={`/subscription${logged ? '' : '/newcomer'}`}
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_PRICING, section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, idartist: artist.id })}
          >
            <A18n rsx="artists.artist.lots.future.m10" />
          </a>
        </div>
      </div>
    );

  if (idcustomer && !isPushEmail)
    return (
      <div className={`lot fake ${direction}`}>
        <div className="fake-bg" />
        {direction === 'vertical' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email.png`} alt="email-alert" />}
        {direction === 'horizontal' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email-no-fs.png`} alt="email-alert" />}
        <div className="fake-container">
          <p className="title">
            <A18n rsx="artists.artist.lots.future.m20" trustHtml replace={[['%artist%', artist.name]]} />
          </p>
          <p className="content">
            <A18n rsx="artists.artist.lots.future.m11" trustHtml />
          </p>
          <a
            className="btn btn-default link"
            href="/myartprice/settings/artists"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_ARTISTS, section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, idartist: artist.id })}
          >
            <A18n rsx="artists.artist.lots.future.m12" />
          </a>
        </div>
      </div>
    );

  if (idcustomer && favoriteCount === 0 && isPushEmail)
    return (
      <div className={`lot fake ${direction}`}>
        <div className="fake-bg" />
        {direction === 'vertical' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email.png`} alt="email-alert" />}
        {direction === 'horizontal' && <img id="email-alert-card" src={`${IMAGE_SOURCE}artist/alert-email-no-fs.png`} alt="email-alert" />}
        <div className="fake-container">
          <p className="title">
            <A18n rsx="artists.artist.lots.future.m20" trustHtml replace={[['%artist%', artist.name]]} />
          </p>
          <p className="content">
            <A18n rsx="artists.artist.lots.future.m18" trustHtml />
          </p>

          <Follow
            idartist={artist.id}
            render={({ handleFollowClick }) => (
              <a
                className="btn btn-default link"
                onClick={() => {
                  handleFollowClick();
                  aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_FOLLOW_ARTIST, section: ANALYTICS_SECTION_ARTIST_FUTURE_LOTS, idartist: artist.id });
                }}
              >
                <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
                  <div>
                    <A18n rsx="artists.artist.lots.future.m19" />
                  </div>
                  <div style={{ border: 'solid 1px #000', borderRadius: 15, fontSize: 16, height: 30, marginLeft: 10, paddingTop: 3, width: 30 }}>
                    <i className="fa fa-heart-o" />
                  </div>
                </div>
              </a>
            )}
          />
        </div>
      </div>
    );

  return null;
};

export default Fake;
