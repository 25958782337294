import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import './stylesheet.scss';

const Cell = ({ definition, value }) => {
  if (definition.type.startsWith('idartist'))
    return (
      <td className="reports-report-table-cell artist">
        <a href={value.url}>{value.name}</a>
        <br />
        <small>{value.dates}</small>
      </td>
    );

  if (definition.type === 'idlot' && value) return <td className="reports-report-table-cell lot">{value.url && <a href={value.url}>{value.label}</a>}</td>;

  if (definition.type === 'date' && value) {
    return (
      <td className="reports-report-table-cell lot">
        <FormattedDate value={value.label} />
      </td>
    );
  }

  if (definition.type === 'currency_usd')
    return (
      <td className="reports-report-table-cell right">
        {/* eslint-disable-next-line react/no-children-prop,react/style-prop-object */}
        <FormattedNumber value={value.value} style="currency" currency="USD" maximumFractionDigits={0} children={v => v.replace('$US', '$')} />
      </td>
    );

  // eslint-disable-next-line react/style-prop-object
  if (definition.type === 'currency_eur')
    return (
      <td className="reports-report-table-cell right">
        {/* eslint-disable-next-line react/style-prop-object */}
        <FormattedNumber value={value.value} style="currency" currency="EUR" maximumFractionDigits={0} />
      </td>
    );

  if (definition.type === 'number')
    return (
      <td className="reports-report-table-cell right">
        <FormattedNumber value={value.value} />
      </td>
    );

  if (definition.type === 'bool') return <td className="reports-report-table-cell center">{value.checked && <i className="fa fa-check" />}</td>;

  return <td className="reports-report-table-cell string">{value.label}</td>;
};

export default Cell;
