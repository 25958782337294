/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { string } from 'prop-types';
import i18next from 'i18next';
import Select from '../../common/FacetsSelect';

const component = ({ className }) => (
  <div className="artp-input-group">
    <span className="artp-input-label">{i18next.t('lots.search.filters.inputs.lotartistassociationtype.m1')}</span>
    <Select isMulti className={className} placeholder={i18next.t('lots.search.filters.inputs.lotartistassociationtype.m2')} facetName="idlotartistassociationtype" />
  </div>
);

component.defaultProps = {
  className: undefined,
};

component.propTypes = {
  className: string,
};

export default component;
