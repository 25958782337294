import React, { useState } from 'react';
import './stylesheets.scss';
import A18n from '../../../../common/A18n';
import AddIcon from '../common/AddIcon';
import Modal from '../../../../common/ui/Modal';

const NoAccess = ({ className }) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <div className={`account-lots-lists-add-to-list-button-no-access ${className ?? ''}`}>
      <Modal open={visibility} onClose={() => setVisibility(false)} className="account-lots-lists-create-button-modale">
        <div className="account-lots-lists-create-button-no-access">
          <p>
            <i className="fa fa-lock fa-4x" />
          </p>
          <p>
            <A18n rsx="components.lotslists.noaccess.m1" />
          </p>
          <p>
            <A18n rsx="components.lotslists.noaccess.m2" />
          </p>
          <p>
            <a className="btn btn-primary" href="/subscription/upgrade">
              <A18n rsx="subscriptions.purchase.warnings.update-or-renew.m13" />
            </a>
          </p>
          <p>
            <a href="#" onClick={() => setVisibility(false)}>
              <i className="fa fa-arrow-left" /> <A18n rsx="components.lotslists.create.success.m3" />
            </a>
          </p>
        </div>
      </Modal>

      <button type="button" className="lots-lists-button" onClick={() => setVisibility(true)}>
        <AddIcon />
        <A18n rsx="components.lotslists.create.m9" />
      </button>
    </div>
  );
};

export default NoAccess;
