import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { getCart } from '../../../../redux/slices/payments';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Cart = () => {
  const cart = useSelector(getCart);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(get(cart, 'main_products[0].component_products', []).reduce((value, lineCart) => value + lineCart.prices[cart.currency.iso3.toLowerCase()] * lineCart.quantity, 0));
  }, [cart]);

  if (isEmpty(cart)) return null;

  return (
    <div className="payments-cart-summary-cart">
      {cart.currency.id !== cart.customerCurrency.id && (
        <div>
          <i className="fa fa-info-circle" aria-hidden="true" />
          <A18n
            rsx="subscription.cart.m41"
            replace={[
              ['%final_currency%', cart.currency.iso1],
              ['%display_currency%', cart.customerCurrency.iso1],
            ]}
          />
        </div>
      )}

      <h3>Panier</h3>

      <div className="cart-details">
        {get(cart, 'main_products[0].component_products', []).map(lineCart => (
          <div className="line" key={lineCart.id}>
            <div className="title">{lineCart.title}</div>
            <div className="price">
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber value={lineCart.prices[cart.currency.iso3.toLowerCase()] * lineCart.quantity} style="currency" currency={cart.currency.iso3} />
            </div>
          </div>
        ))}
        {total > 0 && (
          <div className="line total">
            <div className="title">Total</div>
            <div className="price">
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber value={total} style="currency" currency={cart.currency.iso3} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
