import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import { updateTvaIntra } from '../../../../../redux/slices/userContext';
import Form from './Form';
import Show from './Show';

const Address = ({ address, title, updateAction, editable = true, withTva }) => {
  const { idtitle, firstname, lastname, tvaintra } = useSelector(getUserContext);
  const [displayForm, setDisplayForm] = useState(isNil(address?.id));
  const dispatch = useDispatch();

  const onSubmit = values => {
    dispatch(updateAction(values)).then(() => {
      setDisplayForm(false);
      dispatch(updateTvaIntra({ tvaintra: values.tvaintra }));
    });
  };

  return (
    <div className="address">
      <h3>
        {title}

        {editable && !displayForm && (
          <button type="button" onClick={() => setDisplayForm(true)}>
            Edit
          </button>
        )}
      </h3>

      {isNil(address?.id) && <span>Vous devez saisir une adresse de facturation pour pouvoir continuer</span>}
      {displayForm && <Form initialValues={(address?.id && { ...address, tvaintra }) || { idtitle, firstname, lastname, tvaintra }} onSubmit={onSubmit} onCancel={() => setDisplayForm(false)} withTva={withTva} />}
      {!displayForm && <Show address={address} withTva={withTva} />}
    </div>
  );
};

export default Address;
