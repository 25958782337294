export default ({ viewportWidth, width } = {}) => ({
  option: provided => ({
    ...provided,
    fontSize: 12,
    paddingBottom: 20,
    paddingTop: 20,
  }),
  menu: provided => ({
    ...provided,
    fontSize: 12,
    paddingBottom: 0,
    paddingTop: 0,
    color: '#a198a4',
    zIndex: 10,
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: 12,
    color: '#a198a4',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menuList: provided => ({
    ...provided,
    fontSize: 12,
    paddingBottom: 20,
    paddingTop: 20,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  control: () => ({
    alignItems: 'center',
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    boxSizing: 'border-box',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: 14,
    justifyContent: 'space-between',
    minHeight: 30,
    outline: '0 !important',
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 0,
    paddingTop: 0,
    position: 'relative',
    transition: 'all 100ms',
    width: width || viewportWidth < 768 ? '100%' : 275,
  }),
});
