import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSwitch } from '../../../../../redux/selectors/ui/switches';
import { off } from '../../../../../redux/actions/ui/switches';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import './stylesheet.scss';

const Drawer = ({ children, id, className, onClose }) => {
  const visible = useSelector(state => getSwitch(state, { id }));
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(off(id));
    if (onClose) onClose();
  };

  return (
    <div className="artists-artist-common-drawer">
      <div className={`background ${visible ? 'active' : ''}`} onClick={handleClose} />
      <div className={`content ${className} ${visible ? 'active' : ''}`}>
        <div className="close" onClick={handleClose}>
          <img src={`${IMAGE_SOURCE}artist/close.png`} alt="close" />
        </div>
        <div className="children"> {children}</div>
      </div>
    </div>
  );
};

export default Drawer;
