import React from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import './stylesheet.scss';

const ButtonFollow = ({ isFavorite, handleFollowClick, rsxFavorited, rsxNotFavorited, size, title }) => (
  <div className="button-follow">
    <Button className="btn" color="default" size={size} onClick={handleFollowClick} title={title}>
      {i18next.t(isFavorite ? rsxFavorited : rsxNotFavorited)} <i className={isFavorite ? 'fa fa-heart' : 'fa fa-heart-o'} />
    </Button>
  </div>
);

ButtonFollow.defaultProps = {
  size: null,
  rsxFavorited: undefined,
  rsxNotFavorited: undefined,
  title: undefined,
};

ButtonFollow.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  handleFollowClick: PropTypes.func.isRequired,
  rsxFavorited: PropTypes.string,
  rsxNotFavorited: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
};

export default ButtonFollow;
