import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import React from 'react';
import './stylesheet.scss';
import Header from './Header';
import Settings from './Settings';
import List from './List';
import { areThemesUnavailable } from '../../../redux/selectors/subscriptions/physicproducts';
import { getSettings } from '../../../redux/selectors/subscriptions/settings';
import WarningsUpdateOrRenew from './warnings/UpdateOrRenew';
import Registration from './FreeServices/Registration';
import Others from './FreeServices/Others';
import Promotion from './Promotion';

const Purchase = ({ locale }) => {
  const subscriptionsUnavailable = useSelector(areThemesUnavailable);
  const { newcomer } = useSelector(getSettings);

  return (
    <IntlProvider locale={locale}>
      <div className={`subscriptions-list ${subscriptionsUnavailable ? 'empty' : ''}`}>
        <Header />
        {newcomer && <Registration />}
        <div className="container">
          <Settings />
          <Promotion />
          <List />
        </div>
        <div className={`subscriptions-free-services ${subscriptionsUnavailable ? 'no-data' : ''}`}>
          {!subscriptionsUnavailable && !newcomer && <Registration />}
          <Others />
        </div>
        <WarningsUpdateOrRenew />
      </div>
    </IntlProvider>
  );
};

export default Purchase;
