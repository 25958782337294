import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import React, { useContext, useRef } from 'react';
import { ANALYTICS_CLICK_OPERATION_SCROLL, ANALYTICS_FROM_ARTIST, IMAGE_SOURCE } from '../../../../../constants/rails';
import { useHorizontalScroll } from '../../../../../hooks/useHorizontalScroll';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { useViewport } from '../../../../../hooks/useViewport';
import { getArtist } from '../../../../../redux/slices/artist';
import { UiContext } from '../UiProvider';
import './stylesheet.scss';

const Scroll = ({ className = '', children, renderComplement, aapiBeaconSection }) => {
  const ref = useRef();
  const artist = useSelector(getArtist);
  const { leftDisabled, rightDisabled, displayNav, handleScrollClick } = useHorizontalScroll({ ref });
  const { viewportWidth } = useViewport();
  const { hideLeftMenu } = useContext(UiContext);

  const hScroll = direction => {
    handleScrollClick(direction);
    aapiBeacon('click', {
      op: ANALYTICS_CLICK_OPERATION_SCROLL,
      from: ANALYTICS_FROM_ARTIST,
      artistId: artist.id,
      section: aapiBeaconSection,
    });
  };

  return (
    <div className={`scroll ${hideLeftMenu ? 'no-menu' : ''}`}>
      {ref.current?.scrollLeft > 0 && (
        <div className="shadow-left-container" style={{ height: ref.current.clientHeight }}>
          <div className="shadow-left" />
        </div>
      )}
      <div className={`body ${className}`} ref={ref}>
        {children}
      </div>

      {(displayNav || renderComplement) && (
        <div className="container footer">
          {displayNav && viewportWidth >= 768 && (
            <>
              <Button className="btn-previous btn btn-default" onClick={() => hScroll('left')} disabled={leftDisabled}>
                <img src={`${IMAGE_SOURCE}artist/arrow-left.png`} alt="previous" />
              </Button>
              <Button className="btn-next btn btn-default" onClick={() => hScroll('right')} disabled={rightDisabled}>
                <img src={`${IMAGE_SOURCE}artist/arrow-right.png`} alt="next" />
              </Button>
            </>
          )}
          {renderComplement && <div className="complement"> {renderComplement()}</div>}
        </div>
      )}
    </div>
  );
};

export default Scroll;
