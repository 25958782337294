import React from 'react';
import { object } from 'prop-types';

const ErrorBlock = ({ error, style }) => {
  if (!error) return null;
  return (
    <div
      className="user-submit-error-block text-danger"
      style={{
        color: 'red',
        fontSize: 16,
        fontWeight: 600,
        paddingTop: 5,
        ...style,
      }}
    >
      {error.message}
    </div>
  );
};

ErrorBlock.defaultProps = {
  error: undefined,
};

ErrorBlock.propTypes = {
  error: object,
};

export default ErrorBlock;
