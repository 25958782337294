import React from 'react';
import { useSelector } from 'react-redux';
import { getFree, getArtist } from '../../../../../../redux/slices/artist';
import A18n from '../../../../../common/A18n';

const Past = ({ lotCount, priceOneDay }) => {
  const artist = useSelector(getArtist);
  const freeArtist = useSelector(getFree);

  return (
    <>
      <h3>
        {lotCount === 1 && <A18n rsx="artists.artist.lots.noaccessdrawer.m6" replace={[['%artist%', artist.name]]} />}
        {lotCount > 1 && <A18n rsx="artists.artist.lots.noaccessdrawer.m1" replace={[['%artist%', artist.name]]} />}
      </h3>
      <p className="args">
        {lotCount === 1 && (
          <>
            <A18n rsx="artists.artist.lots.noaccessdrawer.m10" replace={[['%dt-year%', artist.firstLotYear]]} trustHtml />{' '}
            <A18n rsx="artists.artist.lots.noaccessdrawer.m11" replace={[['%price-oneday%', priceOneDay]]} trustHtml />
          </>
        )}
        {lotCount > 1 && (
          <>
            {artist.lastLotYear === artist.firstLotYear && (
              <>
                <A18n rsx="artists.artist.lots.noaccessdrawer.m7" replace={[['%dt-year%', artist.firstLotYear]]} trustHtml />{' '}
                {priceOneDay && <A18n rsx="artists.artist.lots.noaccessdrawer.m11" replace={[['%price-oneday%', priceOneDay]]} trustHtml />}
                {!priceOneDay && <A18n rsx="artists.artist.lots.noaccessdrawer.m14" trustHtml />}
              </>
            )}
            {artist.lastLotYear !== artist.firstLotYear && (
              <>
                <A18n
                  rsx="artists.artist.lots.noaccessdrawer.m2"
                  replace={[
                    ['%dt-old-year%', artist.firstLotYear],
                    ['%dt-recent-year%', artist.lastLotYear],
                  ]}
                  trustHtml
                />{' '}
                {priceOneDay && <A18n rsx="artists.artist.lots.noaccessdrawer.m11" replace={[['%price-oneday%', priceOneDay]]} trustHtml />}
                {!priceOneDay && <A18n rsx="artists.artist.lots.noaccessdrawer.m14" trustHtml />}
              </>
            )}
          </>
        )}
      </p>
      {freeArtist && (
        <p>
          <A18n
            rsx="artists.artist.lots.noaccessdrawer.m12"
            trustHtml
            replace={[
              ['%a%', `<a style="text-decoration: underline" href="${freeArtist.url}">`],
              ['%/a%', '</a>'],
            ]}
          />
        </p>
      )}
    </>
  );
};
export default Past;
