import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_FROM_ARTIST, ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL, ANALYTICS_SECTION_ARTIST_MARKETPLACE_AD } from '../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import { getArtist } from '../../../../../../redux/slices/artist';
import Overlapping from '../../../common/Overlapping';
import { a18n } from '../../../../../../utils/a18n';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const SellAd = forwardRef((_props, ref) => {
  const artist = useSelector(getArtist);

  return (
    <Overlapping ref={ref} className="artists-artist-marketplace-classifieds-sell">
      <div className="sell-content">
        <div className="title">
          <A18n rsx="artists.artist.marketplace.classifieds.sellad.m1" />
        </div>
        <div className="content">
          <p>
            <A18n rsx="artists.artist.marketplace.classifieds.sellad.m2" trustHtml />
          </p>
          <p>
            <A18n rsx="artists.artist.marketplace.classifieds.sellad.m3" />
          </p>
        </div>
        <a
          className="btn btn-default link"
          href={`/${a18n('routes.marketplace')}/how-to-sell`}
          onClick={() =>
            aapiBeacon('click', {
              from: ANALYTICS_FROM_ARTIST,
              op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL,
              section: ANALYTICS_SECTION_ARTIST_MARKETPLACE_AD,
              idartist: artist.id,
            })
          }
        >
          <A18n rsx="artists.artist.marketplace.classifieds.sellad.m4" />
        </a>
      </div>
    </Overlapping>
  );
});

export default SellAd;
