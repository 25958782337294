import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_SETTINGS, ANALYTICS_FROM_HOMEPAGE, ANALYTICS_SECTION_SUBSCRIPTIONS_DATA } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import A18n from '../../../common/A18n';
import { getIdcustomer } from '../../../../redux/slices/userContext/selectors';

const Favorites = () => {
  const idcustomer = useSelector(getIdcustomer);

  return (
    <div className="homepage-favorites homepage-auto-promo">
      <div className="l0">
        <A18n rsx="homepage.focusonorautopromo.favorites.m1" />
      </div>
      <div className="l1">
        <A18n rsx="homepage.focusonorautopromo.favorites.m2" />
      </div>
      <div className="l2">
        {!idcustomer && (
          <a
            className="btn btn-default btn-animated"
            href="/subscription/newcomer"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_SECTION_SUBSCRIPTIONS_DATA, section: 'auto-promo-favorites' })}
          >
            <A18n rsx="homepage.focusonorautopromo.favorites.m4" />
          </a>
        )}

        {idcustomer && (
          <a
            className="btn btn-default btn-animated"
            href="/myartprice/settings/artists"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_SETTINGS, section: 'auto-promo-favorites' })}
          >
            <A18n rsx="homepage.focusonorautopromo.favorites.m3" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Favorites;
