import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatforms } from '../../../services/subscriptions/stripe';
import { getParams, updateNavigationStep } from '../../../redux/slices/payments';
import Spinner from '../../common/spinners/Spinner';
import Accordion from '../../common/Accordion';
import { a18n } from '../../../utils/a18n';
import A18n from '../../common/A18n';
import Offline from './Offline';
import Summary from './Summary';
import Paypal from './Paypal';
import Stripe from './Stripe';
import './stylesheet.scss';

const Platforms = () => {
  const dispatch = useDispatch();
  const { image, idphysicproducts, type, promocode, idestimates } = useSelector(getParams);
  const [loading, setLoading] = useState(false);
  const [stripeSubscriptionOptions, setStripeSubscriptionOptions] = useState();
  const [items, setItems] = useState([]);

  // Set step for the progressbar
  useEffect(() => {
    dispatch(updateNavigationStep(3));
  }, []);

  useEffect(() => {
    const params = { type, promocode, image, idphysicproducts, idestimates };

    if (!isEmpty(idphysicproducts)) {
      setLoading(true);

      // retrieve platforms information from params set in the outlet context
      // those params are set in the  component Payments/Layout and initialized from the backend
      getPlatforms(params)
        .then(response => {
          const stripeSubscription = response.platforms.find(platform => platform.id === 'stripe_subscription');

          if (stripeSubscription) {
            setStripeSubscriptionOptions({ stripeSubscriptionCheckoutSessionId: stripeSubscription.parameters.checkout_session.sessionId });
          } else {
            const innerItems = [];

            const stripeIntent = response.platforms.find(platform => platform.id === 'stripe_intent');
            if (stripeIntent) {
              innerItems.push({
                key: 'stripe',
                title: a18n('payments.platforms.m1'),
                children: (
                  <Stripe
                    idpayment={response.idpayment}
                    displayedPaymentMethod={stripeIntent.extra.payment_method_types}
                    options={{
                      checkoutFormOptions: {
                        clientSecret: stripeIntent.parameters.intent_client_secret,
                      },
                    }}
                  />
                ),
              });
            }

            const paypalOptions = response.platforms.find(platform => platform.id === 'paypal');
            if (paypalOptions) {
              innerItems.push({
                key: 'paypal',
                title: a18n('payments.platforms.m2'),
                children: <Paypal idphysicsale={response.idphysicsale} options={paypalOptions} />,
              });
            }

            if (response.offline_allowed) {
              // paiement par virement
              innerItems.push({ key: 'transfer', title: a18n('payments.platforms.m3'), children: <Offline idphysicsale={response.idphysicsale} mode="transfer" /> });

              // paiement par cb offline
              innerItems.push({
                key: 'cboffline',
                title: a18n('payments.platforms.m4'),
                children: <Offline idphysicsale={response.idphysicsale} mode="cb" />,
              });
            }

            setItems(innerItems);
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => setLoading(false));
    }
  }, [type, promocode, image, idphysicproducts]);

  if (stripeSubscriptionOptions) return <Stripe options={stripeSubscriptionOptions} />;

  return (
    <div className="payments-platforms">
      <Summary />
      {!loading && (
        <div className="platforms-container container">
          <h2>
            <A18n rsx="payments.platforms.m5" />
          </h2>
          <Accordion items={items} />
        </div>
      )}
      {loading && (
        <div style={{ marginTop: 60 }}>
          <Spinner faSize={4} />
        </div>
      )}
    </div>
  );
};

export default Platforms;
