import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL, ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_MARKETPLACE_CLASSIFIEDS_LIST } from '../../../../../../constants/rails';
import { aapiBeacon } from '../../../../../../services/analytics/aapi';
import { getArtist } from '../../../../../../redux/slices/artist';
import { a18n } from '../../../../../../utils/a18n';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Empty = () => {
  const artist = useSelector(getArtist);

  return (
    <div className="artists-artist-marketplace-empty">
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.marketplace.classifieds.empty.m1" />
        </h2>
        <p className="sub-h2">
          <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.marketplace.classifieds.empty.m2' : 'artists.artist.marketplace.classifieds.empty.m5'} replace={[['%artist%', artist.name]]} />
        </p>

        <div className="actions">
          <div>
            <A18n rsx="artists.artist.marketplace.classifieds.empty.m3" replace={[['%artist%', artist.name]]} trustHtml />
          </div>
          <div>
            <a
              className="btn btn-default link"
              href={`/${a18n('routes.marketplace')}/how-to-sell`}
              onClick={() =>
                aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL, idartist: artist.id, section: ANALYTICS_SECTION_ARTIST_MARKETPLACE_CLASSIFIEDS_LIST })
              }
            >
              <A18n rsx="artists.artist.marketplace.classifieds.empty.m4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Empty;
