import React from 'react';
import { bool, string, number, func, shape } from 'prop-types';
import Separator from './Separator';
import PagesSequence from './PagesSequence';
import ActivePage from './ActivePage';
import './stylesheet.scss';

const Pagination = ({ activePage, totalCount, perPage, onClick, pagePadding, pageParamName, labels = {}, className, loading = false }) => {
  const pageCount = Math.ceil(totalCount / perPage);
  const { previous, next } = labels;
  // separator + (first | last page)
  const offset = 2;
  const itemsCount = pagePadding * 2 + 5;

  const displayRightSeparator = () => {
    if (pageCount <= itemsCount) {
      return false;
    }

    if (activePage >= pageCount - offset) {
      return false;
    }

    return activePage + pagePadding < pageCount - offset;
  };

  const displayLeftSeparator = () => {
    if (pageCount <= itemsCount) {
      return false;
    }

    return activePage + pagePadding > itemsCount - offset;
  };

  const rightIterations = () => {
    const leftSeparator = displayLeftSeparator();
    const rightSeparator = displayRightSeparator();

    if (leftSeparator && rightSeparator) {
      return pagePadding;
    }

    if (rightSeparator && !leftSeparator) {
      return itemsCount - offset - activePage;
    }

    return pageCount - activePage - 1;
  };

  const leftIterations = () => {
    const leftSeparator = displayLeftSeparator();
    const rightSeparator = displayRightSeparator();

    if (pageCount <= itemsCount) {
      return activePage - offset;
    }

    if (leftSeparator && rightSeparator) {
      return pagePadding;
    }

    if (rightSeparator && !leftSeparator) {
      return activePage - offset;
    }

    return activePage - (pageCount - itemsCount + offset + 1);
  };

  const handleUpdateUrl = page => {
    const finalPageParam = `${pageParamName}=${page}`;
    const matcher = new RegExp(`([?&])${pageParamName}=\\d+`);
    let url = window.location.pathname;

    if (window.location.search === '') {
      url += `?${finalPageParam}`;
    } else if (window.location.search.match(matcher)) {
      url += window.location.search.replace(matcher, `$1${finalPageParam}`);
    } else {
      url += `${window.location.search}&${finalPageParam}`;
    }

    window.history.replaceState(window.history.state, '', url);
  };

  const handleClick = page => {
    if (page > 0 && page <= pageCount && !loading) {
      onClick(page);
      handleUpdateUrl(page);
    }
  };

  return (
    <div className={`artp-ui-pagination ${className}`}>
      <div className={`artp-ui-pagination-container ${loading ? 'loading' : ''}`}>
        <PagesSequence iterations={1} initialCount={activePage - 1} onClick={handleClick} className={`previous ${activePage === 1 ? 'disable' : ''}`} label={previous} />
        {activePage > 1 && <PagesSequence iterations={1} initialCount={1} onClick={handleClick} />}
        {displayLeftSeparator() && <Separator />}
        <PagesSequence iterations={leftIterations()} initialCount={activePage - leftIterations()} onClick={handleClick} />
        <ActivePage page={activePage} />
        <PagesSequence iterations={rightIterations()} initialCount={activePage + 1} onClick={handleClick} />
        {displayRightSeparator() && <Separator />}
        {activePage < pageCount && <PagesSequence iterations={1} initialCount={pageCount} onClick={handleClick} />}
        <PagesSequence iterations={1} initialCount={activePage + 1} onClick={handleClick} className={`next ${activePage === pageCount ? 'disable' : ''}`} label={next} />
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  activePage: 1,
  pageParamName: 'p',
  totalCount: 0,
  pagePadding: 2,
  loading: false,
  className: '',
  labels: {
    previous: '<',
    next: '>',
  },
};

Pagination.propTypes = {
  activePage: number,
  totalCount: number,
  perPage: number.isRequired,
  onClick: func.isRequired,
  pagePadding: number,
  pageParamName: string,
  labels: shape({
    previous: string,
    next: string,
  }),
  className: string,
  loading: bool,
};

export default Pagination;
