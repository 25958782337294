/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import './stylesheet.scss';
import { simplifyData } from '../../../../../utils/marketplace/stores/stats';

function getStyle(value) {
  if (value < 0.1) {
    return {
      textIndent: '1rem',
    };
  }
  return {};
}

const StatsList = ({ data = [] }) => {
  const simplifiedData = simplifyData(data, 0.01);
  return (
    <div className="store-Stats-List">
      <table className="table table-striped table-hover table-condensed">
        <thead className="marg marg-l-0">
          <tr>
            <th className="col-xs-6">{i18next.t('marketplace.stores.stats.list.m1')}</th>
            <th className="col-xs-6" />
          </tr>
        </thead>
        <tbody>
          {simplifiedData.map(({ name, value }) => (
            <tr key={name}>
              <td>{name}</td>
              <td style={getStyle(value)}>{`${(value * 100).toFixed(2)}%`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

StatsList.defaultProps = {
  data: [],
};

StatsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default memo(StatsList);
