import i18Next from 'i18next';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { getData, getDataList } from '../../../../../services/wallet/customerlotslist';
import { getCurrency, getLang } from '../../../../../redux/selectors/preferences';
import DocumentList from '../documents/List';
import DocumentShow from '../documents/Show';
import { IMAGE_SOURCE } from '../../../../../constants/rails';

async function createAndDownloadPdf(Doc, name) {
  return pdf(Doc)
    .toBlob()
    .then(blob => {
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.style = 'display: none';
      link.href = urlObject;
      link.download = `${name}.pdf`;

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(urlObject);
    });
}

const Link = ({ id, idlot, sort, anchor, className, ...otherProps }) => {
  const [loading, setLoading] = useState();
  const lang = useSelector(getLang);
  const currency = useSelector(getCurrency);

  const handleClick = () => {
    if (loading) return;
    setLoading(true);

    if (idlot) {
      getData({ id, idlot }).then(data => {
        const name = `${i18Next.t('components.lotslists.pdf.m4')} - ${data.list.label}`;
        const Doc = (
          <IntlProvider locale={lang}>
            <DocumentShow data={data} currency={currency} />
          </IntlProvider>
        );

        createAndDownloadPdf(Doc, name).then(() => setLoading(false));
      });
    } else {
      getDataList({ id, sort, paginate: false }).then(data => {
        const name = `${i18Next.t('components.lotslists.pdf.m4')} - ${data.list.label}`;
        const Doc = (
          <IntlProvider locale={lang}>
            <DocumentList data={data} currency={currency} />
          </IntlProvider>
        );

        createAndDownloadPdf(Doc, name).then(() => setLoading(false));
      });
    }
  };

  return (
    <span onClick={handleClick} className={`account-lots-lists-pdf-link btn btn-default ${className}`} {...otherProps}>
      {!loading && <img style={{ height: 20, marginRight: 5 }} src={`${IMAGE_SOURCE}pdflists/export_list.svg`} alt="export" />}
      {loading && <i className="fa fa-spin fa-spinner fa-lg" />}
      {anchor}
    </span>
  );
};

export default Link;
