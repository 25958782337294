import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
import RouterProvider from '../providers/RouterProvider';
import Platforms from './Platforms';
import CartSummary from './CartSummary';
import Layout from './Layout';
import { fetchCart, getCartUnavailable, getParams, updateParams } from '../../redux/slices/payments';
import Spinner from '../common/spinners/Spinner';

const processIdphysicproductParam = ({ idphysicproduct, quantity }) => {
  if (idphysicproduct) {
    if (isArray(idphysicproduct)) {
      // Coming from the subscription page
      return idphysicproduct.reduce((result, internalIdphysicproduct) => {
        // eslint-disable-next-line no-unused-vars
        const { [internalIdphysicproduct]: _, ...others } = result;
        return { ...others, [internalIdphysicproduct]: (result[internalIdphysicproduct] || 1) + quantity };
      }, {});
    }

    if (typeof idphysicproduct === 'string') {
      return { [idphysicproduct]: quantity || 1 };
    }

    // Object type
    return { ...idphysicproduct };
  }
  return {};
};

const Payment = ({ cartParams: initialCartParams }) => {
  const dispatch = useDispatch();
  const cartParams = useSelector(getParams);
  const cartUnavailable = useSelector(getCartUnavailable);

  useEffect(() => {
    dispatch(
      updateParams({
        ...initialCartParams,
        idphysicproducts: processIdphysicproductParam({ idphysicproduct: initialCartParams.idphysicproduct, quantity: initialCartParams.quantity }),
      }),
    );
  }, [initialCartParams]);

  useEffect(() => {
    if (!isEmpty(cartParams)) {
      const { type, image, promocode, quantity, idphysicproducts } = cartParams;
      dispatch(
        fetchCart({
          type,
          image,
          promocode,
          quantity,
          idphysicproducts,
        }),
      );
    }
  }, [cartParams]);

  useEffect(() => {
    if (cartUnavailable) {
      window.location = '/subscription';
    }
  }, [cartUnavailable]);

  if (cartUnavailable) {
    return (
      <div style={{ marginTop: 60 }}>
        <Spinner faSize={4} />
      </div>
    );
  }

  // manage correctly the display when redux's cartParams are empty
  if (!cartParams) return null;

  return (
    <RouterProvider
      routes={[
        {
          path: '/payments',
          element: <Layout />,
          children: [
            {
              path: 'platforms',
              element: <Platforms />,
            },
            {
              path: 'cart',
              element: <CartSummary />,
            },
          ],
        },
      ]}
    />
  );
};

export default Payment;
