/* eslint-disable global-require */
module.exports = [
  'artists.artist.lots.noaccessdrawer.m1',
  'artists.artist.lots.noaccessdrawer.m2',
  'artists.artist.lots.noaccessdrawer.m3',
  'artists.artist.lots.noaccessdrawer.m4',
  'artists.artist.lots.noaccessdrawer.m5',
  'artists.artist.lots.noaccessdrawer.m6',
  'artists.artist.lots.noaccessdrawer.m7',
  'artists.artist.lots.noaccessdrawer.m8',
  'artists.artist.lots.noaccessdrawer.m9',
  'artists.artist.lots.noaccessdrawer.m10',
  'artists.artist.lots.noaccessdrawer.m11',
  'artists.artist.lots.noaccessdrawer.m12',
  'artists.artist.lots.noaccessdrawer.m13',
  'artists.artist.lots.noaccessdrawer.m14',
];
