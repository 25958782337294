import React from 'react';
import { string, bool } from 'prop-types';

import './stylesheet.scss';

const SmallSection = ({ title, value, isHtml, condition }) => {
  if (!title || !value || !condition) return null;
  return (
    <section className="small-section pad pad-t-5 pad-b-5">
      <strong>
        {title}
        {': '}
      </strong>
      {/* eslint-disable-next-line react/no-danger */}
      {isHtml ? <span className="inner-html" dangerouslySetInnerHTML={{ __html: value }} /> : <span>{value}</span>}
    </section>
  );
};

SmallSection.defaultProps = {
  value: undefined,
  isHtml: false,
  condition: true,
};
SmallSection.propTypes = {
  title: string.isRequired,
  value: string,
  isHtml: bool,
  condition: bool,
};

export default SmallSection;
