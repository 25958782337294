import React from 'react';
import { Image } from '@react-pdf/renderer';
import SvgNoReproduction from '../../common/SvgNoReproduction';

const getSize = ({ geometry, imageMaxDimension }) => {
  const [width, height] = geometry;

  if (width > height) {
    return {
      height: (imageMaxDimension * height) / width,
      width: imageMaxDimension,
    };
  }

  return {
    height: imageMaxDimension,
    width: (imageMaxDimension * width) / height,
  };
};

const Reproduction = ({ lot, imageMaxDimension }) => {
  if (!Array.isArray(lot.imageUrls) || lot.imageUrls.length < 1) return <SvgNoReproduction imageMaxDimension={imageMaxDimension} />;

  const { width, height } = getSize({ geometry: lot.imageUrls[0].geometry, imageMaxDimension });
  return <Image src={lot.imageUrls[0].url} style={{ height, width }} />;
};

export default Reproduction;
