import { StyleSheet } from '@react-pdf/renderer';
import '../common/styles';

export const imageMaxDimension = 100;

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 11,
    paddingBottom: 100,
  },
  text: {
    marginBottom: 4,
    fontFamily: 'Noto Sans TC',
  },
  section: {
    margin: 2,
    padding: 5,
    // flexGrow: 1,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    flexDirection: 'row',
    fontFamily: 'Noto Sans',
  },
  cell: {
    flexDirection: 'column',
    flex: 1,
    padding: 10,
  },
  datas: {
    flexDirection: 'row',
    marginBottom: 3,
  },
  reproduction: {
    alignItems: 'center',
    marginTop: 10,
    height: imageMaxDimension + 10,
    width: imageMaxDimension + 10,
  },
});
