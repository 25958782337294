/* eslint-disable global-require */
module.exports = [
  'components.lotslists.landingpage.m1',
  'components.lotslists.landingpage.m2',
  'components.lotslists.landingpage.m3',
  'components.lotslists.landingpage.m4',
  'components.lotslists.landingpage.m5',
  'components.lotslists.landingpage.m6',
  'components.lotslists.landingpage.m7',
  'components.lotslists.landingpage.m8',
  'components.lotslists.landingpage.m9',
  'components.lotslists.landingpage.m10',
  'components.lotslists.landingpage.m11',
  'subscriptions.purchase.warnings.update-or-renew.m13',
];
