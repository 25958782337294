import React from 'react';
import { number, bool } from 'prop-types';
import ReduxProvider from '../../../../providers/ReduxProvider';
import Store from '..';

const Header = ({ idstore, displayEmptyTabs }) => (
  <ReduxProvider>
    <Store idstore={idstore} displayEmptyTabs={displayEmptyTabs} />
  </ReduxProvider>
);

Header.defaultProps = {
  displayEmptyTabs: false,
};

Header.propTypes = {
  idstore: number.isRequired,
  displayEmptyTabs: bool,
};

export default Header;
