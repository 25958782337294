import React from 'react';
import { array } from 'prop-types';

import Item from './Item';

const Events = ({ events: marketplaceEvents }) => {
  if (marketplaceEvents.length <= 0) return null;
  return marketplaceEvents.map(event => <Item key={`#${event.id}-${event.title}`} event={event} />);
};

Events.defaultProps = {
  events: [],
};

Events.propTypes = {
  // eslint-disable-next-line
  events: array,
};

export default Events;
