import React from 'react';
import { string, node, arrayOf, oneOfType } from 'prop-types';
import './stylesheet.scss';

const VerticalBar = ({ children, className, ...otherProps }) => (
  <div className={`vertical-bar ${className || ''}`.trim()} {...otherProps}>
    {children}
  </div>
);

VerticalBar.defaultProps = {
  className: '',
};

VerticalBar.propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default VerticalBar;
