import React from 'react';
import { useSelector } from 'react-redux';
import {
  isLogged,
  get,
  subscriptionNeedsAction,
  hasAlmostExpiredSubscription,
  hasExpiredSubscription,
  mustCompleteSellerProfile,
  mustRenameStore,
  mustCompleteStoreInformation,
  hasNotSignedAuctioneerAgreement,
  mustFillEmail,
  hasClassifiedsWaitingForModification,
  hasExpiredClassifieds,
  hasClassifiedsAwaitingOfferSelection,
  hasAuctionsWithOngoingTransactions,
  hasEstimatesWaitingForPayment,
  isUndelivered,
  emailNeedsVerification,
} from '../../../../../../../redux/slices/userContext/selectors';
import {
  ANALYTICS_FROM_HEADER_ACCOUNT,
  ANALYTICS_CLICK_OPERATION_LOGOUT,
  ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_ESTIMATE,
  ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_HOME,
  ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_LOTS_LISTS,
  ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_CONTROL_CENTER,
  ANALYTICS_CLICK_OPERATION_TO_CUSTOMER_HISTORY,
  ANALYTICS_CLICK_OPERATION_TO_LOGIN_PAGE,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_AUTO_REDIRECT,
  IMAGE_SOURCE,
} from '../../../../../../../constants/rails';
import Separator from '../../Separator';
import A18n from '../../../../../../common/A18n';
import SubscriptionMessage from './SubscriptionMessage';
import { aapiBeacon } from '../../../../../../../services/analytics/aapi';
import Undelivered from './notifications/Undelivered';
import SubscriptionNeedsAction from './notifications/SubscriptionNeedsAction';
import AlmostExpiredSubscription from './notifications/AlmostExpiredSubscription';
import ExpiredSubscription from './notifications/ExpiredSubscription';
import AuctioneerAgreementNotSigned from './notifications/AuctioneerAgreementNotSigned';
import StoreNameMissing from './notifications/StoreNameMissing';
import StoreInformationIncomplete from './notifications/StoreInformationIncomplete';
import EmailMissing from './notifications/EmailMissing';
import ClassifiedsWaitingForModification from './notifications/ClassifiedsWaitingForModification';
import ExpiredClassifieds from './notifications/ExpiredClassifieds';
import AwaitingOfferSelection from './notifications/AwaitingOfferSelection';
import AuctionsWithOngoingTransactions from './notifications/AuctionsWithOngoingTransactions';
import EstimatesWaitingForPayment from './notifications/EstimatesWaitingForPayment';
import SellerProfileIncomplete from './notifications/SellerProfileIncomplete';
import { getAuctioneerLink } from '../../../../../../../utils/links/auctioneer';
import EmailNotVerified from './notifications/EmailNotVerified';
import PromotionNotify from './PromotionNotify';
import './stylesheet.scss';

// overlay over actual aapi to set type and from
function aapiWrapper(op) {
  aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_ACCOUNT, op });
}

const Content = () => {
  const logged = useSelector(isLogged);
  const {
    idcustomer,
    idaccountrole,
    name,
    isAuctioneer,
    isNonPartnerAuctioneer,
    auctioneerMapped,
    auctioneerAgreement: { signedAt } = {},
    estimate: { hasEndedReports = false, counts: { freshInformationsCount = 0 } = {} } = {},
    marketplace: { active, hasStore } = {},
  } = useSelector(get);
  const needsAction = useSelector(subscriptionNeedsAction);
  const almostExpired = useSelector(hasAlmostExpiredSubscription);
  const expired = useSelector(hasExpiredSubscription);
  const sellerProfileIncomplete = useSelector(mustCompleteSellerProfile);
  const storeNameMissing = useSelector(mustRenameStore);
  const storeInformationIncomplete = useSelector(mustCompleteStoreInformation);
  const mustSignAuctioneerAgreement = useSelector(hasNotSignedAuctioneerAgreement);
  const emailMissing = useSelector(mustFillEmail);
  const classifiedsWaitingForModification = useSelector(hasClassifiedsWaitingForModification);
  const expiredClassifieds = useSelector(hasExpiredClassifieds);
  const awaitingOfferSelection = useSelector(hasClassifiedsAwaitingOfferSelection);
  const auctionsWithOngoingTransactions = useSelector(hasAuctionsWithOngoingTransactions);
  const estimatesWaitingForPayment = useSelector(hasEstimatesWaitingForPayment);
  const undelivered = useSelector(isUndelivered);
  const emailNotVerified = useSelector(emailNeedsVerification);

  if (!logged) {
    return (
      <a href="/identity" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_LOGIN_PAGE)}>
        <img src={`${IMAGE_SOURCE}menu/silhouette_black.svg`} alt="silhouette" /> <A18n rsx="layouts.header.m3" />
      </a>
    );
  }

  return (
    <div className="artp-Header-MyAccount-Content">
      <div className="header">
        <div className="customer-info">
          <img className="silhouette-icon" src={`${IMAGE_SOURCE}menu/silhouette_black.svg`} alt="silhouette" /> {name}
        </div>
        <Separator style={{ background: '#000' }} />
        <div className="subscription-info">
          <SubscriptionMessage />
        </div>
      </div>
      <PromotionNotify />
      <ul className="link-list">
        {/* Mon compte */}
        {idaccountrole === 1 && (
          <>
            <li className="nowrap">
              <a className="link-list-item" href="/account" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_HOME)}>
                <A18n rsx="layouts.header.m48" /> <span style={{ marginLeft: 4 }}>({idcustomer})</span>
              </a>
            </li>
            {/* Notif Mon compte */}
            {undelivered && <Undelivered />}
            {!undelivered && emailNotVerified && <EmailNotVerified />}
            {needsAction && <SubscriptionNeedsAction />}
            {almostExpired && <AlmostExpiredSubscription />}
            {expired && <ExpiredSubscription />}
          </>
        )}
        {/* Mon espace auctioneer */}
        {(isAuctioneer || isNonPartnerAuctioneer) && (
          <>
            <li>
              <a
                className="link-list-item"
                href={getAuctioneerLink({ isAuctioneer, isNonPartnerAuctioneer, auctioneerMapped, signedAt })}
                onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_AUCTIONEER_CONTROL_CENTER)}
              >
                <A18n rsx="layouts.header.m42" />
              </a>
            </li>
            {/* Notif Mon espace auctioneer */}
            {mustSignAuctioneerAgreement && <AuctioneerAgreementNotSigned />}
          </>
        )}
        {/* Mon espace Marketplace */}
        {idaccountrole === 1 && active && (
          <li>
            <a className="link-list-item" href="/marketplace/auto_redirect" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_AUTO_REDIRECT)}>
              <A18n rsx={hasStore ? 'component.header.myaccount.m4' : 'component.header.myaccount.m2'} />
            </a>
          </li>
        )}
        {/* Notif Mon espace Marketplace */}
        {sellerProfileIncomplete && <SellerProfileIncomplete />}
        {storeNameMissing && <StoreNameMissing />}
        {!storeNameMissing && storeInformationIncomplete && <StoreInformationIncomplete />} {/* On l'affiche pas si le nom est manquant aussi */}
        {emailMissing && <EmailMissing />}
        {classifiedsWaitingForModification && <ClassifiedsWaitingForModification />}
        {expiredClassifieds && <ExpiredClassifieds />}
        {awaitingOfferSelection && <AwaitingOfferSelection />}
        {auctionsWithOngoingTransactions && <AuctionsWithOngoingTransactions />}
        {estimatesWaitingForPayment && <EstimatesWaitingForPayment />}
        {/* Mes listes */}
        <li>
          <a className="link-list-item" href="/account/lots-lists" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_LOTS_LISTS)}>
            <A18n rsx="layouts.header.m46" />
          </a>
        </li>
        {/* Mes Artpricings */}
        {(hasEndedReports || freshInformationsCount > 0) && (
          <li>
            <a className="link-list-item" href="/account/estimate" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_ESTIMATE)}>
              <A18n rsx="layouts.header.m17" />
            </a>
          </li>
        )}
        {/* Mes dernières consultations */}
        <li>
          <a className="link-list-item" href="/customer/history" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_CUSTOMER_HISTORY)}>
            <A18n rsx="layouts.header.m18" />
          </a>
        </li>
      </ul>
      <Separator />
      {/* Se déconnecter */}
      <a className="link-list-item" href="/login/weblogout" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_LOGOUT)}>
        <A18n rsx="layouts.header.m29" />
      </a>
    </div>
  );
};

export default Content;
