import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import i18next from 'i18next';
import Map from './Map';
import Pie from './Pie';
import List from './List';
import ReduxProvider from '../../../providers/ReduxProvider';
import { getPercentageCountryRepartition, isCountryRepartitionLoading } from '../../../../redux/selectors/marketplace/stores/stats';
import { fetchAllStats as fetchAllStatsAction } from '../../../../redux/actions/marketplace/stores/stats';
import Spinner from '../../../common/spinners/Spinner';
import YourStats from './YourStats';
import Views from './Views';
import BestClassifieds from './BestClassifieds';
import PeriodSelector from './PeriodSelector';
import './stylesheet.scss';
import SectionTitle from '../common/SectionTitle';
import StoreManagmentMenu from '../../common/ManagmentMenu';
import NoAccess from '../common/NoAccess';
import { getHasStore } from '../../../../redux/slices/userContext/selectors';

const MAP = 'MAP';
const PIE = 'PIE';
const LIST = 'LIST';

const Stats = ({ fetchAllStats, countryRepartition, countryRepartitionLoading }) => {
  const [activeTab, setActiveTab] = useState(PIE);
  const hasStore = useSelector(getHasStore);

  useEffect(() => {
    fetchAllStats();
  }, []);

  return (
    <div className="store-Stats">
      <StoreManagmentMenu />
      <SectionTitle section="stats" />
      <div className="container">
        <div className="store-stats marg marg-t-50">
          <div className="row marg marg-t-30">
            {hasStore && (
              <div className="flex-container-space-between">
                <h3>{i18next.t('component.store.stats.index.m2')} </h3>
                <PeriodSelector />
              </div>
            )}
            <div className="col-xs-12">
              {!hasStore && <NoAccess />}
              {hasStore && <BestClassifieds />}
            </div>
          </div>

          <div className="row marg marg-t-50">
            <h3>{i18next.t('component.store.stats.index.m3')}</h3>
            <div className="col-xs-12">
              <Views />
            </div>
          </div>

          <div className="row marg marg-t-50">
            <h3 className="marg marg-b-10">{i18next.t('component.store.stats.index.m4')}</h3>
            <p className="marg marg-b-25 font font-16">{i18next.t('component.store.stats.index.m6')}</p>
            <div className="col-xs-12">
              <YourStats />
            </div>
          </div>

          <div className="row marg marg-t-50">
            <div className="flex-container-space-between">
              <h3>{i18next.t('component.store.stats.index.m5')}</h3>
              {!countryRepartitionLoading && (
                <div>
                  <button type="button" className={`btn icon-btn${activeTab === PIE ? ' active' : ''}`} onClick={() => setActiveTab(PIE)}>
                    <i className="fa fa-pie-chart" />
                  </button>
                  <button type="button" className={`btn icon-btn${activeTab === LIST ? ' active' : ''}`} onClick={() => setActiveTab(LIST)}>
                    <i className="fa fa-list" />
                  </button>
                  <button type="button" className={`btn icon-btn${activeTab === MAP ? ' active' : ''}`} onClick={() => setActiveTab(MAP)}>
                    <i className="fa fa-globe" />
                  </button>
                </div>
              )}
            </div>
            {countryRepartitionLoading ? (
              <div style={{ height: 400 }}>
                <Spinner />
              </div>
            ) : (
              <>
                {activeTab === MAP && <Map data={countryRepartition} />}
                {activeTab === PIE && <Pie data={countryRepartition} />}
                {activeTab === LIST && <List data={countryRepartition} />}
              </>
            )}
          </div>
        </div>
      </div>
      {!hasStore && <div className="calc" />}
    </div>
  );
};

Stats.defaultProps = {
  countryRepartition: [],
  countryRepartitionLoading: false,
};

Stats.propTypes = {
  fetchAllStats: Proptypes.func.isRequired,
  countryRepartition: Proptypes.arrayOf(Proptypes.object),
  countryRepartitionLoading: Proptypes.bool,
};

function mapStateToProps(state) {
  return {
    countryRepartition: getPercentageCountryRepartition(state),
    countryRepartitionLoading: isCountryRepartitionLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllStats: () => dispatch(fetchAllStatsAction()),
  };
}

const ConnectedStats = connect(mapStateToProps, mapDispatchToProps)(Stats);

const StatsWithProvider = () => (
  <ReduxProvider>
    <ConnectedStats />
  </ReduxProvider>
);

export default StatsWithProvider;
