import React from 'react';
import PropTypes from 'prop-types';
import './stylesheet.scss';

const LinkButton = ({ className = '', onClick, children }) => (
  <button type="button" className={`artdbweb-lot-Indicator-LinkButton ${className}`} onClick={onClick}>
    {children}
  </button>
);

LinkButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func,
};

LinkButton.defaultProps = {
  className: '',
  onClick: undefined,
};
export default LinkButton;
