import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, BarChart, Bar } from 'recharts';
import { getActiveSubscription, isLogged } from '../../../../redux/slices/userContext/selectors';
import {
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR,
  ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH,
  ANALYTICS_SECTION_ARTIST_INDEXES_BUTTON,
  ID_FREE_ARTIST,
  IMAGE_SOURCE,
  MODULE_INDEXES,
  ANALYTICS_SECTION_INDEXES_DRAWER,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES,
  RETURN_AFTER_LOGIN_COOKIE_KEY,
} from '../../../../constants/rails';
import { getFree, getArtist, getCa } from '../../../../redux/slices/artist';
import { getBannerUrlSml, getCode } from '../../../../redux/slices/promotion';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { useViewport } from '../../../../hooks/useViewport';
import { on } from '../../../../redux/actions/ui/switches';
import Drawer from '../common/Drawer';
import { UiContext } from '../common/UiProvider';
import A18n from '../../../common/A18n';
import Lock from '../common/Lock';
import './stylesheet.scss';
import { set as setCookie } from '../../../../utils/cookie';

const ID_DRAWER_INDEXES = 'id_drawer_indexes';

const Indexes = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { indexesRef, hideLeftMenu } = useContext(UiContext);
  const ca = useSelector(getCa);
  const logged = useSelector(isLogged);
  const artist = useSelector(getArtist);
  const freeArtist = useSelector(getFree);
  const activeSubscription = useSelector(getActiveSubscription);
  const promocodecode = useSelector(getCode);
  const artpriceBanners = useSelector(getBannerUrlSml);
  const { viewportWidth } = useViewport();

  const [noSubscriptionAccess, setNoSubscriptionAccess] = useState(false);
  const [displayLock, setDisplayLock] = useState(false);

  const tickFormatter = value =>
    intl.formatNumber(value, {
      style: 'currency',
      currency: 'eur',
      minimumFractionDigits: 0,
    });

  useEffect(() => setNoSubscriptionAccess(isEmpty(activeSubscription?.modules.filter(m => m === MODULE_INDEXES))), [activeSubscription]);
  useEffect(() => {
    if (artist.id === ID_FREE_ARTIST) {
      setDisplayLock(false);
    } else {
      setDisplayLock(noSubscriptionAccess || !logged);
    }
  }, [artist?.id, logged, noSubscriptionAccess]);

  if (!artist.macro && !artist.index) return null;
  if (isEmpty(ca?.data)) return null;

  const handleSeeAllClick = ({ e, section }) => {
    let eventBlocked = false;

    if (artist.id !== ID_FREE_ARTIST) {
      if (!noSubscriptionAccess && !logged) {
        aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES, section, idartist: artist.id });

        e.stopPropagation();
        e.preventDefault();
        const aParent = e.target.closest('a');
        eventBlocked = true;
        setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, aParent ? aParent.href : window.location.href);
        window.location = '/identity/indexes';
      } else if (!logged) {
        e.preventDefault();
        e.stopPropagation();

        aapiBeacon('dom_view', { from: ANALYTICS_FROM_ARTIST, section: ANALYTICS_SECTION_INDEXES_DRAWER, idartist: artist.id });
        eventBlocked = true;

        dispatch(on(ID_DRAWER_INDEXES));
      }
    }

    if (!eventBlocked) aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_INDEXES, section, idartist: artist.id });
  };

  return (
    <div className={hideLeftMenu ? '' : 'block'} ref={indexesRef}>
      <Drawer id={ID_DRAWER_INDEXES} className="indexes">
        <p>
          <img alt="drawer-ca" style={{ width: 119 }} src={`${IMAGE_SOURCE}artist/indexes.svg`} />
        </p>
        <h3>
          <A18n rsx="artists.artist.indexes.m12" replace={[['%artist%', artist.name]]} trustHtml />
        </h3>
        <p className="args">
          {artist.index && <A18n rsx="artists.artist.indexes.m11" trustHtml replace={[['%artist%', artist.name]]} />}
          {!artist.index && <A18n rsx="artists.artist.indexes.m16" trustHtml replace={[['%artist%', artist.name]]} />}
        </p>
        <p>
          <A18n
            rsx="artists.artist.lots.noaccessdrawer.m12"
            trustHtml
            replace={[
              ['%a%', `<a style="text-decoration: underline" href="${freeArtist?.url}">`],
              ['%/a%', '</a>'],
            ]}
          />
        </p>
        {artpriceBanners && (
          <p>
            {/* eslint-disable-next-line react/no-danger */}
            <a href={`/subscription?promocode=${promocodecode}`}>
              <span dangerouslySetInnerHTML={{ __html: artpriceBanners }} />
            </a>
          </p>
        )}
        <p className="marg marg-t-30">
          <a className="btn btn-default link" href={`/subscription${promocodecode ? `?promocode=${promocodecode}` : ''}`}>
            <A18n rsx="artists.artist.indexes.m13" />
          </a>
        </p>
        <p className="login">
          <A18n rsx="artists.artist.indexes.m14" />{' '}
          <a href="/identity/indexes">
            <A18n rsx="artists.artist.indexes.m15" />
          </a>
        </p>
      </Drawer>
      <div className="container">
        <div className="artists-artist-indexes">
          <h2>
            <A18n rsx="artists.artist.indexes.m1" />
          </h2>
          <p className="sub-h2">
            {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m2" replace={[['%artist%', artist.name]]} />}
            {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m17" replace={[['%artist%', artist.name]]} />}
          </p>

          <div className="header">
            <div>
              <a href={`${artist.urlIndexes}?st=chrono`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
                <A18n rsx="artists.artist.indexes.m3" />
              </a>
            </div>
            {artist.index && (
              <div>
                <a href={`${artist.urlIndexes}?st=indexes`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
                  <A18n rsx="artists.artist.indexes.m4" />
                  {displayLock && <Lock stroke="#999" />}
                </a>
              </div>
            )}
            <div className="hidden-xs">
              <a href={`${artist.urlIndexes}?st=rank`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
                <A18n rsx="artists.artist.header.m3" />
                {displayLock && <Lock stroke="#999" />}
              </a>
            </div>
            <div className="hidden-xs">
              <a href={`${artist.urlIndexes}?st=mediums`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
                <A18n rsx="artists.artist.header.m4" />
                {displayLock && <Lock stroke="#999" />}
              </a>
            </div>
            <div className="hidden-xs">
              <a href={`${artist.urlIndexes}?st=geo`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_TOOLBAR })}>
                <A18n rsx="artists.artist.header.m5" />
                {displayLock && <Lock stroke="#999" />}
              </a>
            </div>
            <div>
              <a href={`${artist.urlIndexes}`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH })}>
                ...
              </a>
            </div>
          </div>

          <div className="graphs">
            <div className="ca">
              <a href={`${artist.urlIndexes}?st=chrono`} onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_GRAPH })}>
                <ResponsiveContainer width="100%" height={350}>
                  <BarChart data={ca.data} style={{ cursor: 'pointer' }}>
                    <XAxis dataKey="label" />
                    <YAxis dataKey="value" tick={!displayLock} width={displayLock ? 20 : 100} tickFormatter={tickFormatter} />
                    <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                    <Bar barSize={viewportWidth < 1200 && viewportWidth > 992 ? 25 : 50} dataKey="value" fill="#cac6ba" animationDuration={300} animationBegin={35} />
                  </BarChart>
                </ResponsiveContainer>
              </a>

              {displayLock && <Lock stroke="#cac6ba" className="lock graph-lock" height={45} width={45} />}
            </div>
          </div>

          <div className="actions">
            <a href={artist.urlIndexes} className="btn btn-default link" onClick={e => handleSeeAllClick({ e, section: ANALYTICS_SECTION_ARTIST_INDEXES_BUTTON })} style={{ paddingRight: 40 }}>
              {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m10" />}
              {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.indexes.m18" />}
              {displayLock && <Lock stroke="#000" style={{ position: 'absolute' }} />}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indexes;
