import React from 'react';
import { Svg, G, Rect, Path } from '@react-pdf/renderer';

const LogoArtprice = () => (
  <Svg viewBox="0 0 314.2 356.2" height={75} width={50}>
    <G id="Artprice">
      <Rect width="314.2" height="314.2" fill="#000000" />
      <G>
        <Path
          fill="#FFFFFF"
          d="M38.3,237.3l-12.2,12.2c-0.3,0.3-0.5,0.5,0.3,1.4l4.4,4.4l-3.5-0.2c-4.6-0.3-9.4,1.7-13,5.2
			c-10.3,10.3-9.4,27.2,2,38.5c12.2,12.2,27.8,13,38.9,1.9c3.1-3.1,6.3-8.2,5.9-13.3l-0.2-3.5l4.4,4.4c0.5,0.5,1.1,0.1,1.3-0.1
			L77.9,277c0.2-0.2,0.5-0.5-0.5-1.5l-38.1-38.1C39.2,237.3,38.7,236.9,38.3,237.3z M46.1,286.4c-4.7,4.7-11.7,4.6-16.4-0.1
			c-5.1-5.1-5.3-11.8-0.4-16.7c4.7-4.7,11.5-4.5,16.4,0.4C50.5,274.8,50.7,281.8,46.1,286.4z M128.9,206.5c-0.2-0.1-0.7-0.5-1.2-0.4
			c-0.1,0-0.5,0.1-1,0.9c-1.7,2.7-3.8,4.2-5.9,4.5c-1.8,0.2-3.6-0.5-5.2-2.2l-14.7-14.7l6.8-6.7c0.4-0.4,0.7-0.8-0.3-1.7l-8.4-8.5
			c-0.4-0.3-0.8-0.3-1.2,0.2l-6.8,6.8l-9.6-9.9c-0.3-0.3-1.5-1-2.6,0.2l-11.2,11.2c-0.3,0.3-0.5,0.5,0.5,1.5c0,0,9.7,10.2,9.7,10.2
			l-3.6,3.6c-0.3,0.3-0.3,0.5,0,0.9l1.2,1.8l-2.2,0.2c-3.2,0.3-6.2,1.9-9,4.7c-2.9,2.9-4.6,7.5-4.3,11.6l0.3,3.5l-3.5-3.5
			c-0.6-0.6-0.8-0.7-0.9-0.7c0,0-0.2,0-0.5,0.4l-11.8,11.8c-0.4,0.3-0.4,0.5-0.4,0.5c0,0.2,0.4,0.6,0.7,0.8l37.8,37.8
			c1,1,1,1,1.5,0.5l12.2-12.2c0.3-0.3,0.5-0.5-0.2-1.3l-24.3-24.3l-0.1-0.4c-0.4-2.4-0.6-5.1,1.8-7.5c3.6-3.6,7.4-1.5,9.9,1
			c0.6-0.6,1.4-1.5,1.4-2.2c0-1.1,0-5.7-0.1-9.8c0,0-0.1-3.5-0.1-3.5l0.7-0.7l3.3-3.4l13.9,13.9c7.1,7.1,16.9,16.9,30.7,3.1
			c3.4-3.4,4.9-7.7,5.8-10.2c0.3-0.9,0.1-1.4-0.4-1.7C136.3,211.9,129.9,207.2,128.9,206.5z M264.5,67.9c-0.2,0.1-14.2,5.7-14.2,5.7
			c-0.5,0.2-0.7,0.4-0.8,0.6c-0.1,0.2,0,0.4,0.1,0.8c1,2.8,0.5,7.3-2.9,10.8c-4.9,4.9-11.8,4.9-16.6,0c-5.2-5.2-5.3-11.7-0.2-16.7
			c3.1-3.1,7.6-4.4,10.9-3.2c0.7,0.3,0.8,0.1,0.9-0.1c0.1-0.2,6-14.7,6-14.7c0.2-0.5,0.2-0.8-0.1-1.1c-0.1-0.1-0.3-0.3-0.6-0.4
			c-10.2-4.7-21.2-2.2-30.2,6.8c-12.1,12.1-11.9,29.9,0.6,42.3c12.4,12.4,29.2,12.3,41.8-0.3c8.6-8.6,11-18.6,7.1-29.8
			c-0.1-0.2-0.2-0.4-0.4-0.5C265.5,67.8,265.1,67.7,264.5,67.9z M257.3,16.3c-12.4,12.3-12.4,29,0,41.3
			c12.7,12.7,29.5,12.9,41.9,0.5c5.9-5.9,9-13.7,9.3-18.5c0.1-1.5-0.6-1.9-1.3-2c-4.1-0.9-9.6-1.8-13.3-2.4c-0.5-0.1-1,0-1.2,0.2
			c-0.1,0.1-0.1,0.2-0.1,0.3c0.4,4.9-0.8,8.8-3.7,11.7c-4.5,4.5-9.9,5.2-14.2,2l-1.2-0.9l23.8-23.8c1.1-1.1,1.2-1.4,0.6-2.3
			c-1-1.6-2.1-3-4.9-5.8C282.5,6.1,267.5,6,257.3,16.3z M266.6,41.7l-0.9-1.4c-2.9-4.5-2.6-9,0.8-12.4c3.6-3.7,8.2-4,12.6-0.9
			l1.3,0.9L266.6,41.7z M193,82.6l-12.1,12.1c-0.3,0.3-0.3,0.4-0.3,0.4c0,0.2,0.5,0.6,0.7,0.9l3.7,3.7l-3.9-0.6c-4-0.6-8,1-11.6,4.6
			c-2.9,2.9-4.6,7.5-4.3,11.6l0.3,3.5l-3.5-3.5c-0.6-0.6-0.8-0.7-0.9-0.7c0,0-0.2,0-0.5,0.4l-11.8,11.8c-0.3,0.3-0.4,0.5-0.4,0.5
			c0,0.2,0.4,0.6,0.7,0.8L187,166c1,1,1,1,1.5,0.5l12.2-12.2c0.3-0.3,0.5-0.5-0.2-1.3l-24.3-24.3l-0.1-0.4c-0.4-2.4-0.6-5.1,1.8-7.4
			c3.6-3.6,7.4-1.5,9.9,1c0.6-0.6,1.4-1.5,1.4-2.2c0-1.1,0-5.6-0.1-9.7c0-0.1-0.1-6.5-0.1-6.5l30.3,30.3c1,1,1,1,1.5,0.5l12.1-12.1
			c0.4-0.4,0.5-0.7-0.1-1.4l-38.1-38.1C193.7,81.9,193.6,82,193,82.6z M126.6,147.6c-3.6,3.6-6.1,8.7-6.2,12.7l0,3.1l-3.3-3.3
			c-0.6-0.6-0.8-0.7-0.9-0.7c0,0-0.2,0-0.5,0.4l-11.5,11.5c-0.3,0.3-0.3,0.4-0.3,0.4c0,0.2,0.5,0.6,0.7,0.9l50.9,50.9
			c1,1,1,1,1.4,0.6l12.3-12.2c0.4-0.4,0.5-0.5-0.4-1.4L153,194.7l2.9-0.2c3.6-0.2,8-2.3,11.1-5.1c5.7-5.3,8.8-11.6,9-18.1
			c0.2-7.4-3.4-14.9-10.4-21.8C150.6,134.3,135.2,139,126.6,147.6z M153.8,178.8c-4.1,4.1-11.2,5.4-16.8-0.2c-5-5-5.2-11.8-0.4-16.6
			c3.9-3.9,11-5,16.5,0.5C158.6,168.1,157.6,175.1,153.8,178.8z M178.3,67.8l-12.1,12.1c-0.2,0.2-0.4,0.4,0.5,1.4l8.2,8.2
			c0.9,0.9,1.3,0.5,1.5,0.4l12.1-12.1c0.4-0.4,0.6-0.7-0.1-1.4l-8.4-8.4C178.9,67.2,178.8,67.3,178.3,67.8z"
        />
        <Path
          fill="#FFFFFF"
          d="M250.6,132.9c-5,5-7.1,11.3-5.4,16.8l0.8,2.5l-2.5-0.9c-5.7-2-11-0.3-16.7,5.4c-4.2,4.2-6.2,9.6-5.4,14.9
			l0.6,4l-5-5c-0.6-0.6-0.8-0.7-0.8-0.7c0,0-0.2,0-0.6,0.4L203.9,182c-0.3,0.3-0.3,0.4-0.3,0.4c0,0.2,0.5,0.6,0.7,0.8l37.8,37.8
			c1,1,1,1,1.5,0.5l12.2-12.2c0.3-0.3,0.4-0.4-0.3-1.2l-25.3-25.3l-0.1-0.2c-1-1.8-0.9-6,2-8.9c3.2-3.2,7.4-2.8,11.3,1.2l22.6,22.6
			c0.8,0.8,0.9,0.6,1.4,0.1l12-12c0.4-0.4,0.6-0.6-0.5-1.7l-25-25l-0.1-0.2c-1.1-2.2-0.7-6.1,2-8.8c4.2-4.2,8.5-1.7,11.3,1.2
			l22.6,22.6c0.8,0.8,0.9,0.6,1.4,0.1l12-12c0.4-0.4,0.6-0.6-0.5-1.7L275.5,133C267.1,124.6,258.9,124.6,250.6,132.9z"
        />
        <Path
          fill="#FFFFFF"
          d="M184.9,257.7c-0.2,0.1-14.2,5.7-14.2,5.7c-0.5,0.2-0.7,0.4-0.8,0.6c-0.1,0.2-0.1,0.4,0.1,0.8
			c1,2.8,0.5,7.3-3,10.8c-4.9,4.9-11.7,4.9-16.6,0c-5.2-5.1-5.2-11.7-0.2-16.7c3.1-3.1,7.6-4.4,10.9-3.2c0.7,0.3,0.8,0.1,0.9-0.1
			c0.1-0.2,6-14.7,6-14.7c0.2-0.5,0.2-0.8-0.1-1.1c-0.1-0.1-0.3-0.2-0.5-0.3c-10.2-4.7-21.2-2.2-30.2,6.8
			c-12.1,12.1-11.9,29.9,0.6,42.3c12.4,12.4,29.2,12.3,41.8-0.3c8.6-8.6,11-18.6,7.1-29.8c-0.1-0.2-0.2-0.4-0.3-0.5
			C185.9,257.7,185.5,257.6,184.9,257.7z"
        />
        <Path
          fill="#FFFFFF"
          d="M151.6,314.2l-30.1,0l-2.1-2.1c-2.3-2.3-1.5-3.6-0.5-4.6l15.3-15.3c1.3-1.3,1.8-1.3,3.2,0l15.9,15.9
			c1.7,1.7,2,2.4,0.4,4L151.6,314.2"
        />
        <Path fill="#FFFFFF" d="M151.6,314.2" />
        <Path
          fill="#FFFFFF"
          d="M219.9,205.8c-12.4-12.4-29.7-12.5-42.1-0.1c-12.2,12.1-12.1,29.9,0.1,42.1c12.4,12.4,29.7,12.4,42,0.1
			C232.3,235.5,232.3,218.2,219.9,205.8z M207.9,235.4c-4.6,4.6-12.1,4.4-17-0.6c-4.9-4.9-5.1-12.1-0.5-16.8
			c4.6-4.6,11.6-4.4,16.6,0.6C212.1,223.8,212.5,230.9,207.9,235.4z"
        />
      </G>
    </G>
    <G id="by_Artmarket">
      <G>
        <G>
          <Path
            fill="#000000"
            d="M7.3,330.1c2.2,0,3.9,0.7,5.1,2.1s1.7,3.5,1.7,6.2c0,2.7-0.6,4.7-1.8,6.2c-1.2,1.4-2.9,2.2-5,2.2c-1.1,0-2.2-0.2-3.1-0.7
				c-0.9-0.5-1.6-1.1-2.2-2H1.8l-0.4,2.4H0.5v-23H2v5.8c0,0.9,0,1.7-0.1,2.4l0,1.3H2c0.6-1,1.3-1.7,2.2-2.1S6.1,330.1,7.3,330.1z
				 M7.2,331.4c-1.9,0-3.2,0.5-4.1,1.6c-0.8,1.1-1.2,2.9-1.2,5.4v0.3c0,2.4,0.4,4.2,1.3,5.2c0.9,1.1,2.2,1.6,4,1.6
				c1.8,0,3.1-0.6,4-1.8c0.9-1.2,1.3-3,1.3-5.2C12.5,333.8,10.7,331.4,7.2,331.4z"
          />
          <Path
            fill="#000000"
            d="M21,330.4h1.5l3.4,9c1,2.8,1.7,4.6,2,5.6H28c0.4-1.3,1.1-3.2,2-5.7l3.4-8.9h1.5l-7.2,18.6c-0.6,1.5-1.1,2.5-1.5,3.1
				c-0.4,0.5-0.9,0.9-1.4,1.2c-0.5,0.3-1.2,0.4-1.9,0.4c-0.6,0-1.2-0.1-1.9-0.3v-1.3c0.6,0.2,1.2,0.2,1.8,0.2c0.5,0,0.9-0.1,1.3-0.4
				c0.4-0.2,0.7-0.6,1-1.1c0.3-0.5,0.7-1.3,1.1-2.4c0.4-1.1,0.7-1.8,0.8-2.1L21,330.4z"
          />
          <Path
            fill="#000000"
            d="M61.8,346.5l-2.2-6h-8.2l-2.1,6h-3.7l8.1-21.7h3.8l8.1,21.7H61.8z M58.7,337.4l-2-5.9c-0.1-0.4-0.4-1-0.6-1.9
				s-0.4-1.5-0.5-1.9c-0.3,1.2-0.7,2.5-1.2,4l-1.9,5.6H58.7z"
          />
          <Path
            fill="#000000"
            d="M83.4,337.8v8.6h-3.5v-21.6H86c2.8,0,4.8,0.5,6.2,1.6c1.3,1,2,2.6,2,4.7c0,2.7-1.4,4.6-4.2,5.7l6.1,9.5h-4l-5.2-8.6H83.4
				z M83.4,334.9h2.4c1.6,0,2.8-0.3,3.6-0.9c0.7-0.6,1.1-1.5,1.1-2.7c0-1.2-0.4-2.1-1.2-2.6c-0.8-0.5-2-0.8-3.6-0.8h-2.3V334.9z"
          />
          <Path fill="#000000" d="M118.1,346.5h-3.5v-18.5h-6.3v-3h16.2v3h-6.3V346.5z" />
          <Path
            fill="#000000"
            d="M148.6,346.5l-6.3-18h-0.1c0.2,2.7,0.3,5.2,0.3,7.5v10.5h-3.2v-21.6h5l6,17.2h0.1l6.2-17.2h5v21.6h-3.4v-10.7
				c0-1.1,0-2.5,0.1-4.2s0.1-2.8,0.1-3.1h-0.1l-6.5,18H148.6z"
          />
          <Path
            fill="#000000"
            d="M192,346.5l-2.2-6h-8.2l-2.1,6h-3.7l8.1-21.7h3.8l8.1,21.7H192z M188.9,337.4l-2-5.9c-0.1-0.4-0.4-1-0.6-1.9
				s-0.4-1.5-0.5-1.9c-0.3,1.2-0.7,2.5-1.2,4l-1.9,5.6H188.9z"
          />
          <Path
            fill="#000000"
            d="M213.6,337.8v8.6h-3.5v-21.6h6.1c2.8,0,4.8,0.5,6.2,1.6c1.3,1,2,2.6,2,4.7c0,2.7-1.4,4.6-4.2,5.7l6.1,9.5h-4l-5.2-8.6
				H213.6z M213.6,334.9h2.4c1.6,0,2.8-0.3,3.6-0.9c0.7-0.6,1.1-1.5,1.1-2.7c0-1.2-0.4-2.1-1.2-2.6c-0.8-0.5-2-0.8-3.6-0.8h-2.3
				V334.9z"
          />
          <Path
            fill="#000000"
            d="M257.3,346.5h-4.1l-6.8-9.8l-2.1,1.7v8.1h-3.5v-21.6h3.5v10.3c1-1.2,1.9-2.3,2.9-3.4l5.8-6.9h4c-3.8,4.4-6.5,7.6-8.1,9.5
				L257.3,346.5z"
          />
          <Path fill="#000000" d="M283.9,346.5h-12.2v-21.6h12.2v3h-8.7v5.9h8.1v3h-8.1v6.8h8.7V346.5z" />
          <Path fill="#000000" d="M307.5,346.5h-3.5v-18.5h-6.3v-3h16.2v3h-6.3V346.5z" />
        </G>
      </G>
    </G>
  </Svg>
);

export default LogoArtprice;
