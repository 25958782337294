import i18next from 'i18next';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLots, getCategories, fetchFiltersResultCount, getArtist, getFiltersResultSearching, getFiltersResultCount } from '../../../../../redux/slices/artist';
import { hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import {
  ANALYTICS_FROM_ARTIST,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_APPLY_FILTERS,
  ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_LOGIN,
  ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_LOTS_LIST,
  ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_NO_ACCESS,
  ID_FREE_ARTIST,
  MODULE_PASTSALE,
  RETURN_AFTER_LOGIN_COOKIE_KEY,
} from '../../../../../constants/rails';
import DebouncedInput from '../../../../search/v2/inputs/DebouncedInput';
import customStyles from '../../../../search/common/reactSelect/style';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import Dimensions from '../../../../search/v2/inputs/Dimensions';
import { objectToQueryString } from '../../../../../utils/http';
import { off } from '../../../../../redux/actions/ui/switches';
import { set as setCookie } from '../../../../../utils/cookie';
import Spinner from '../../../../common/spinners/Spinner';
import Years from '../../../../search/v2/inputs/Years';
import Checkbox from '../../../../common/ui/Checkbox';
import A18n from '../../../../common/A18n';
import Drawer from '../../common/Drawer';
import NoAccess from './NoAccess';
import './stylesheet.scss';

export const DRAWER_ID = 'artist-filters';

const defaultSearchParams = { idlotartistassociationtypes: [1], only_in_future_sales: 0, only_in_past_sales: 1 };

const FiltersDrawer = () => {
  const intl = useIntl();
  const artist = useSelector(getArtist);
  const logged = useSelector(isLogged);
  const hasPastSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_PASTSALE }));
  const dispatch = useDispatch();
  const [displayNoAccess, setDisplayNoAccess] = useState(false);
  const lots = useSelector(getLots);
  const categories = useSelector(getCategories);
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const filtersResultSearching = useSelector(getFiltersResultSearching);
  const filtersResultCount = useSelector(getFiltersResultCount);

  const handleSearch = () => {
    if (!isEmpty(searchParams)) {
      const url = `${searchParams.only_in_past_sales === 1 ? lots?.past?.url : lots?.future?.url}?${objectToQueryString(searchParams)}`;
      if ((hasPastSaleAccess && logged) || artist.id === ID_FREE_ARTIST) {
        aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_APPLY_FILTERS, section: ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_LOTS_LIST, idartist: artist.id });

        window.location.href = url;
      } else if (hasPastSaleAccess && !logged) {
        aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_APPLY_FILTERS, section: ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_LOGIN, idartist: artist.id });
        setCookie(RETURN_AFTER_LOGIN_COOKIE_KEY, url);
        window.location = '/identity/sales';
      } else {
        aapiBeacon('click', { from: ANALYTICS_FROM_ARTIST, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_APPLY_FILTERS, section: ANALYTICS_SECTION_ARTIST_FILTERS_REDIRECT_TO_NO_ACCESS, idartist: artist.id });
        setDisplayNoAccess(true);
      }
    }
  };

  const handleClose = () => {
    setSearchParams(defaultSearchParams);
    dispatch(off(DRAWER_ID));
  };

  useEffect(() => {
    const params = Object.entries(searchParams).reduce((p, [key, value]) => (key === 'url' ? p : { ...p, [key]: value }), {});

    if (!isEmpty(params)) dispatch(fetchFiltersResultCount({ params }));
  }, [searchParams]);

  return (
    <Drawer id={DRAWER_ID} className="artists-artist-filters-drawer" onClose={() => setSearchParams(defaultSearchParams)}>
      <NoAccess display={displayNoAccess} setDisplay={setDisplayNoAccess} searchParams={searchParams} />
      <h2>
        <A18n rsx="artists.artist.filters.drawer.m2" />
      </h2>

      <div className="search-item">
        <div className="search-item-label">
          <A18n rsx="artists.artist.filters.drawer.m3" />
        </div>
        <div className="search-item-control">
          <DebouncedInput
            debounce={250}
            placeholder={i18next.t('artists.artist.filters.drawer.m4')}
            onChange={keyword => {
              setSearchParams({ ...searchParams, keyword });
            }}
          />
        </div>
      </div>

      <div className="search-item">
        <div className="search-item-label">
          <A18n rsx="artists.artist.filters.drawer.m5" />
        </div>
        <div className="search-item-control">
          <Select
            isMulti
            styles={customStyles(0)}
            className="search-common-react-select"
            classNamePrefix="react-select"
            options={categories[searchParams.url === lots?.future?.url ? 'future' : 'past'].map(c => ({ value: c.id, label: c.label }))}
            onChange={selectedCategories => {
              setSearchParams({ ...searchParams, idcategory: selectedCategories?.map(c => c.value) });
            }}
          />
        </div>
      </div>

      <div className="search-item">
        <div className="search-item-label">
          <A18n rsx="artists.artist.filters.drawer.m6" />
        </div>
        <div className="search-item-control">
          <Years
            value={{ from: searchParams.year_from, to: searchParams.year_to }}
            onChange={({ from, to }) => {
              setSearchParams({ ...searchParams, year_from: from || searchParams.year_from, year_to: to || searchParams.year_to });
            }}
          />
        </div>
      </div>

      <div className="search-item">
        <div className="search-item-label">
          <A18n rsx="artists.artist.filters.drawer.m7" />
        </div>
        <div className="search-item-control">
          <Dimensions
            value={{ height: searchParams.height, width: searchParams.width, length: searchParams.length, unit: searchParams.unit, percent: searchParams.percent }}
            onChange={({ height, width, length, unit, percent }) => {
              setSearchParams({
                ...searchParams,
                height: height || searchParams.height,
                width: width || searchParams.width,
                length: length || searchParams.length,
                unit: unit?.value || searchParams.unit,
                percent: percent || searchParams.percent,
              });
            }}
          />
        </div>
      </div>

      <h2 className="second">
        <A18n rsx="artists.artist.filters.drawer.m8" />
      </h2>
      <div className="search-item">
        <div className="search-item-control target">
          <Checkbox
            className={`search-item-control-check ${isEmpty(categories?.future) ? 'disabled' : ''}`}
            square
            light
            label={<A18n rsx="artists.artist.filters.drawer.m9" />}
            checked={searchParams.only_in_future_sales === 1}
            onChange={checked => setSearchParams({ ...searchParams, only_in_future_sales: checked ? 1 : 0, only_in_past_sales: checked ? 0 : 1 })}
            disabled={isEmpty(categories?.future)}
          />
          <Checkbox
            className="search-item-control-check"
            square
            light
            label={<A18n rsx="artists.artist.filters.drawer.m10" />}
            checked={searchParams.only_in_past_sales === 1}
            onChange={checked => setSearchParams({ ...searchParams, only_in_future_sales: checked ? 0 : 1, only_in_past_sales: checked ? 1 : 0 })}
          />
        </div>
      </div>

      <div className="bottom">
        <div className="results-statuts">
          {!filtersResultSearching && searchParams !== defaultSearchParams && (
            <div>
              {filtersResultCount === 0 && <A18n rsx="artists.artist.filters.drawer.m1" />}
              {filtersResultCount === 1 && <A18n rsx="artists.artist.filters.drawer.m14" />}
              {filtersResultCount > 1 && <A18n rsx="artists.artist.filters.drawer.m13" replace={[['%count%', intl.formatNumber(filtersResultCount)]]} />}
            </div>
          )}
        </div>
        <div className="actions">
          <button className="cancel" type="button" onClick={handleClose}>
            <A18n rsx="artists.artist.filters.drawer.m11" />
          </button>
          <button className="ok" type="button" onClick={handleSearch} disabled={filtersResultSearching || searchParams === defaultSearchParams || (searchParams !== defaultSearchParams && filtersResultCount === 0)}>
            {!filtersResultSearching && <A18n rsx="artists.artist.filters.drawer.m12" />}
            {filtersResultSearching && <Spinner faSize={1} />}
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersDrawer;
