import { debounce } from 'lodash';
import { bool, func, number, object, string } from 'prop-types';
import React from 'react';
import i18next from 'i18next';
import { connect, useDispatch } from 'react-redux';
import Artist from '../../filters/Artist';
import Categories from '../../filters/Categories';
import Techniques from '../../filters/Techniques';
import Shapes from '../../filters/Shapes';
import Sizes from '../../filters/Sizes';
import Countries from '../../filters/Countries';
import Prices from '../../filters/PricesClassified';
import Classifiedauctionmodes from '../../filters/Classifiedauctionmodes';
import Storetypes from '../../filters/Storetypes';
import Professionaltypes from '../../filters/Professionaltypes';
import CommonDrawer from '../../../common/Drawer';
import { off as closeDrawerAction } from '../../../../redux/actions/ui/switches';
import { initializeContext as initializeContextAction, updateCurrentDraft as updateParamsAction } from '../../../../redux/actions/search';
import {
  isCurrentSearchPerformed as isSearchPerformedSelector,
  getCurrentDraftParams as paramsSelector,
  getCurrentLoadingFacets as loadingFacetsSelector,
  getCurrentUrlParams as initialParamsSelector,
} from '../../../../redux/selectors/search';
import { getCurrentCount as initialTotalCountSelector, getCurrentDraftCount as totalCountSelector } from '../../../../redux/selectors/search/info';
import { width as viewportWidthSelector } from '../../../../redux/selectors/ui/viewport';
import SearchBar from '../../filters/Terms';
import './stylesheet.scss';
import { cleanPathname } from '../../../../utils/http';

const Drawer = ({ totalCount, isSearchPerformed, id, resetIfNecessary, initialTotalCount, validateParams, initialParams, loadingFacets, updateParams, params, searchInSellerClassifieds, searchInStore }) => {
  const dispatch = useDispatch();

  const handleSearchBarChange = debounce(value => {
    updateParams('terms', value);
  }, 200);

  const validateParamsAndClose = force => {
    validateParams(force);
    dispatch(closeDrawerAction(id));
  };

  return (
    <CommonDrawer
      id={id}
      onClose={resetIfNecessary}
      render={() => (
        <div className="drawer-content">
          <div className="drawer-title">
            <span>{i18next.t('marketplace.searchbars.classifieds.bar.m1')}</span>
            <i
              role="presentation"
              className="fa fa-times text-right"
              onClick={() => {
                resetIfNecessary();
                dispatch(closeDrawerAction(id));
              }}
            />
          </div>

          <section>
            <div className="artp-input-group">
              <SearchBar
                formClassName="search-bar-drawer-marketplace"
                label={i18next.t('marketplace.searchbars.stores.bar.m5')}
                withoutButton
                onChange={handleSearchBarChange}
                defaultValue={initialParams && initialParams.terms}
              />
            </div>
          </section>

          <section>
            <Artist withSearch className="search-bar-input" />
            <div className="artp-input-group">
              <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.m13')}</span>
              <div className="checkbox" style={{ marginBottom: 0, marginTop: 9 }}>
                <label htmlFor="mktplc-fav-checkbox">
                  <input id="mktplc-fav-checkbox" type="checkbox" checked={params.in_fav === '1'} onChange={e => updateParams('in_fav', e.target.checked ? '1' : '0')} />
                </label>
              </div>
            </div>
            <Categories className="search-bar-input" />
            <Techniques className="search-bar-input" />
            <Shapes className="search-bar-input" />
            <Sizes className="search-bar-input" />
            <Prices className="search-bar-input" />
          </section>

          {!searchInStore && (
            <section>
              {!searchInSellerClassifieds && <Countries />}
              <Classifiedauctionmodes />
              <Professionaltypes />
              <Storetypes />
            </section>
          )}

          {isSearchPerformed && (
            <div className="text-center">
              <a className="btn btn-primary btn-lg" href={cleanPathname(window.location.pathname)} style={{ padding: '2px 8px' }} title={i18next.t('marketplace.searchbars.classifieds.bar.m3')}>
                <i className="fa fa-trash-o" /> {i18next.t('artists.search.form.m2')}
              </a>
            </div>
          )}

          <div className="flex-filler" style={{ minHeight: 200 }} />
          {(loadingFacets || totalCount !== initialTotalCount) && (
            <section className="results">
              {totalCount !== initialTotalCount && !loadingFacets && <i className="font font-16">{i18next.t('marketplace.home.index.m4').replace('%count%', totalCount)}</i>}
              {loadingFacets && <i className="fa fa-spinner fa-spin" />}
            </section>
          )}

          <section className="apply">
            <button type="button" className="submit-button" onClick={validateParamsAndClose}>
              {i18next.t('marketplace.searchbars.classifieds.bar.m2')}
            </button>
          </section>
        </div>
      )}
    />
  );
};

Drawer.defaultProps = {
  totalCount: 0,
  searchInSellerClassifieds: true,
  searchInStore: true,
  loadingFacets: false,
  initialTotalCount: 0,
  isSearchPerformed: false,
};

Drawer.propTypes = {
  totalCount: number,
  id: string.isRequired,
  resetIfNecessary: func.isRequired,
  initialTotalCount: number,
  validateParams: func.isRequired,
  initialParams: object.isRequired,
  loadingFacets: bool,
  updateParams: func.isRequired,
  params: object.isRequired,
  searchInSellerClassifieds: bool,
  searchInStore: bool,
  isSearchPerformed: bool,
};

function mapStateToProps(state) {
  return {
    totalCount: totalCountSelector(state),
    initialTotalCount: initialTotalCountSelector(state),
    loadingFacets: loadingFacetsSelector(state),
    initialParams: initialParamsSelector(state),
    params: paramsSelector(state),
    viewportWidth: viewportWidthSelector(state),
    isSearchPerformed: isSearchPerformedSelector(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    closeDrawer: () => dispatch(closeDrawerAction(ownProps.id)),
    updateParams: (param, value) => dispatch(updateParamsAction({ triggeredParam: param, value })),
    initializeContext: (params, searchScope) => dispatch(initializeContextAction({ params, searchScope })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
