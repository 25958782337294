import React from 'react';
import { arrayOf, bool, number } from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { shouldRender } from '../../../services/indexes/subscriptionNeeded';
import { getMaxVisibleYear, isSubscriptionNeeded } from '../../../redux/selectors/indexes/artist';
import { isLogged } from '../../../redux/slices/userContext/selectors';

import './stylesheet.scss';

const UnconnectedSubscriptionNeeded = ({ maxYear, logged, subscriptionNeeded, othersDisplayConditions }) => {
  if (!shouldRender(subscriptionNeeded, othersDisplayConditions)) {
    return null;
  }

  return (
    <div className="subscription-needed">
      <div className="font font-14 sn-banner">
        <div className="centered-container text-center">
          <p style={{ fontSize: '1.75rem' }}>{i18next.t('indexes.subscription_needed.title').replace('%year%', maxYear)}</p>
          <p>
            <a className="btn btn-flat link ripple-on-hover" href="/subscription">
              <i className="fa fa-shopping-cart marg marg-r-5" />
              {i18next.t('indexes.subscription_needed.buy_btn')}
            </a>
            {!logged && (
              <>
                <span className="marg marg-l-5 marg-r-5">{i18next.t('indexes.subscription_needed.or')}</span>
                <a className="btn btn-flat link ripple-on-hover" href="/identity">
                  <i className="fa fa-user marg marg-r-5" />
                  {i18next.t('indexes.subscription_needed.login')}
                </a>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

UnconnectedSubscriptionNeeded.defaultProps = {
  othersDisplayConditions: [],
  subscriptionNeeded: false,
  logged: false,
  maxYear: 2002,
};

UnconnectedSubscriptionNeeded.propTypes = {
  logged: bool,
  othersDisplayConditions: arrayOf(bool),
  subscriptionNeeded: bool,
  maxYear: number,
};

function mapStateToProps(state) {
  return {
    maxYear: getMaxVisibleYear(state),
    logged: isLogged(state),
    subscriptionNeeded: isSubscriptionNeeded(state),
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedSubscriptionNeeded);
