/* eslint-disable global-require */
module.exports = [
  'layout.footer.m1',
  'layout.footer.m2',
  'layout.footer.m3',
  'layout.footer.m4',
  'layout.footer.m5',
  'layout.footer.m6',
  'layout.footer.m7',
  'layout.footer.m8',
  'layout.footer.m9',
  'layout.footer.m10',
  'layout.footer.m11',
  'layout.footer.m12',
  'layout.footer.m13',
  'layout.footer.m14',
  'layout.footer.m15',
  'layout.footer.m16',
  'layout.footer.m17',
  'layout.footer.m18',
  'routes.artists_directory',
];
