import React from 'react';
import PropTypes from 'prop-types';
import './stylesheet.scss';

const Spinner = ({ className = '', faSize }) => (
  <div className={`common-Spinner ${className}`}>
    <div className="spinner">
      <i className={`fa fa-spinner fa-spin fa-${faSize}x fa-fw`} />
    </div>
  </div>
);

Spinner.defaultProps = {
  className: undefined,
  faSize: 5,
};

export const spinnerPropTypes = {
  className: PropTypes.string,
  faSize: PropTypes.oneOf([1, 2, 3, 4, 5]),
};

Spinner.propTypes = spinnerPropTypes;

export default Spinner;
