import { Font } from '@react-pdf/renderer';

Font.register({
  family: 'Noto Sans TC',
  src: '/fonts/Noto_Sans_TC.ttf',
});

Font.register({
  family: 'Noto Sans',
  fonts: [{ src: '/fonts/NotoSans-Regular.ttf' }, { src: '/fonts/NotoSans-Bold.ttf', fontWeight: 700 }],
});
