/* eslint-disable global-require */
module.exports = [
  ...require('./notifications/i18n'),
  ...require('./SubscriptionMessage/i18n'),
  'layouts.header.m3',
  'layouts.header.m48',
  'layouts.header.m42',
  'component.header.myaccount.m4',
  'component.header.myaccount.m2',
  'layouts.header.m46',
  'layouts.header.m17',
  'layouts.header.m18',
  'layouts.header.m29',
];
