import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Map from '../../../../common/graphs/Map';

function formatter(value) {
  return `${(value * 100).toFixed(2)}%`;
}

const StatsMap = ({ data = [] }) => {
  const processedData = data.filter(({ value }) => value > 0.01);

  return <Map data={processedData} formatter={formatter} />;
};

StatsMap.defaultProps = {
  data: [],
};

StatsMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default memo(StatsMap);
